/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "返回",
      "从App Store下载",
      "从Google Play下载",
      "图文集",
      "第{{number}}张",
      "下一页",
      "上一页",
      "页面",
      "第{{number}}张，共{{total}}张",
      "不允许售卖或分享我的个人信息",
      "不允许售卖或​分享​我的​个人​信息",
      "向Bumble发送Compliments，表明你喜欢对方的个人档案。您可以在成功配对前获得对方的注意。现在试试吧",
      "Compliments | 如何发送Compliment | Bumble",
      "个性化​推荐​机制，​为您​推荐​匹配​度​最高​的​人选，​每日​更新。",
      "发现 | 遇见契合的人 | Bumble",
      "使用隐身模式，按照适合自己的节奏约会，选择您的Bumble个人档案对谁可见。详细了解如何在Bumble上使用隐身模式，请参阅我们的指南。",
      "隐身模式 | 隐藏个人档案 | Bumble",
      "在Bumble线下活动中相识后，您可以使用应用程序与对方继续聊天，增进了解。",
      "立即配对 | 使用应用程序继续聊天 | Bumble",
      "使用Bumble的Opening Move功能，以新方式开启对话。引起对方的注意，设定节奏，跳过千篇一律的聊天模式",
      "Bumble Opening Moves | Bumble",
      "需要暂时离开或想享受一个人的时光？查看如何使用Bumble的瞌睡模式暂停您的Bumble帐户。您可以按照您自己的节奏交友约会。",
      "瞌睡模式 | 暂停您的账号 | Bumble",
      "刚刚在Bumble上​见到​让你​非常​心​动​的​人？​使用​SuperSwipe让对方知道你的心意。点击对方个人档案上的星星即可，或在此查看更多内容",
      "Bumble SuperSwipe | 什么是SuperSwipe？ | Bumble",
      "商务旅行或个人旅行？Bumble的旅行模式可以帮助您在新地方建立有趣的新联系，并在旅途中约会。立即查看旅行模式。",
      "Bumble旅行模式 | 随时随地都可约会 | Bumble",
      "想要确认线上聊天的人是否在线下也能来电？在Bumble中使用视频聊天或语音聊天功能，感觉一下",
      "视频聊天 | 拨打电话 | Bumble",
      "使用 Bumble 网页版，体验最强功能。",
      "Bumble 网页版 | Bumble",
      "立即终止合同",
      "Bumble主页",
      "关闭菜单",
      "打开菜单",
      "语言选项",
      "Footer",
      "Bumble的无障碍可访问性",
      "Apple与Apple商标是Apple Inc.的注册商标",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "工作机会",
      "活动",
      "Google Play是Google LLC的商标。",
      "指导原则",
      "投资人",
      "管理Cookies",
      "管理Cookies",
      "现代奴役法案声明",
      "数据采集说明",
      "隐私声明",
      "季节约会指南",
      "条款",
      "应用",
      "华盛顿消费者健康数据隐私政策",
      "关于",
      "大使",
      "嗡嗡",
      "结识​新朋友，​寻找​好友圈",
      "以专业的姿态拓展商务人际网，发展职业",
      "遇见爱情，或建立自由无束缚的关系，或建立两者之间的关系",
      "了解更多",
      "使用旅行模式，走向全球",
      "特色功能",
      "Compliments",
      "给对方送上Compliments，从人群中脱颖而出。",
      "发现",
      "个性化​推荐​机制，​为您​推荐​匹配​度​最高​的​人选，​每日​更新。",
      "隐身模式",
      "使用隐身模式隐藏个人档案，不让上司、亲戚或前任看到你的档案。",
      "立即配对",
      "在Bumble线下​活动​中​遇见​了​聊得来​的人？​在​应用​程序​上​继续​聊天。",
      "Opening Moves",
      "预设Opening Moves，卸掉压力，让对方开启聊天。",
      "瞌睡模式",
      "使用瞌睡模式，按下约会暂停键。我们会在这里一直等着你再次出发。",
      "SuperSwipe",
      "有人让你怦然心动？使用SuperSwipe，让对方知道你的心意。",
      "旅行模式",
      "使用旅行模式，品味免税美食，在全球随心配对。",
      "视频聊天",
      "通过视频聊天和语音通话确认你是否想与对方线下见面。",
      "网页版",
      "在浏览器中直接登入Bumble。",
      "商店",
      "主页",
      "跳过​进入​主界面",
      "Bumble商标",
      "想要增进了解？使用Bumble视频聊天",
      "只需点击聊天中的视频图标即可开始视频通话，并且无需分享任何个人联系信息。",
      "如何在Bumble上使用视频聊天？",
      "不会。所有Bumble上的通话和聊天都将保护您的个人信息安全。除非您主动告知，否则对方不会获得您的号码或任何其他联系详细信息。",
      "我的配对对象会看到我的手机号码吗？",
      "当然可以！如果您暂时不想线下见面，语音通话允许您通过音频了解对方。语音通话只需选择对应的通话图标即可。",
      "我能用语音通话吗？",
      "收到消息通知确实是件令人开心的事。但是，单凭这一点并不能确定线下聊天会同样来电。所以，不妨先试试视频或语音通话。",
      "在Bumble上视频聊天",
      "<a href={{video_chat_url}}>视频聊天和语音通话</a>可以帮助您了解对方，然后决定是否要安排线下见面。另外，使用Bumble进行视频或语音通话，您无需交换手机号码。在您作好准备之前，您的个人信息可以得到保护。",
      "视频聊天看看你们是否来电",
      "连通线上和线下约会，有机会决定线下见面是否时机成熟。",
      "更好（也更快）地了解对方。视频或语音聊天可以让您更直观的了解一个人。",
      "一切由您掌控。我们将保护你的联系信息，因此你可以自己决定何时分享相关信息。",
      "看看您是否作好了线下见面的准备",
      "快速测试是否来电",
      "无需提供个人信息即可通话",
      "为什么你应该​使用​视频​聊天​和​语音​通话",
      "想和对方聊天？您可以在 Bumble 应用中的聊天界面选择语音通话或视频通话。开启视频通话，请点击右上角的灰色摄像头图标。该图标旁边有三个点，点击此处即可看到语音通话选项。",
      "如何进行视频聊天",
      "想试试Compliments？",
      "当您发送了一条Compliment时，对方划到您的个人档案时即会看到您发送的Compliment。",
      "在Bumble上给某人发送Compliment后会发生什么？",
      "如果对方的Compliment引起了你的注意，那就右滑配对吧。你将在聊天页面中看到Compliment，然后按照常规的<a href={{make_the_first_move_url}}>迈出第一步</a>规则开启聊天。",
      "如何回应Compliment？",
      "您可以免费发送{{num}}个[[Compliments:num]]。当您用完Compliment时，您可以在Bumble应用中购买，最大礼包数量为30个Compliment。购买后您可以给让您心动的人发送一个Compliment（剩余的Compliment可供之后看到其他心动人选时使用）。不用担心，Compliment不会过期！",
      "我可以发送多少个Compliment？",
      "一句（或两句）好听的话有时会有神奇的效力。如果你想给某人发送Compliment，但又不知道说什么好，可以看看下方例子找找灵感：",
      "浏览更多例子",
      "“喜欢[artist name]的人品味都不差”",
      "“你的笑容很有感染力！”",
      "“你的简介很搞笑！”",
      "在Bumble 上​发送​Compliment时​写些​什么​好？",
      "想给对方带来一份快乐？试试Compliment吧。",
      "Bumble Compliment",
      "使用 Bumble 上的Compliment功能让潜在配对对象知道您喜欢TA的个人资料（说点让对方心动的话也无妨）。作为 {{service_level_lexeme_name}} 会员，您将获得Compliment。这也是一种在配对之前发送和接收消息的方式。",
      "Compliment是什么",
      "当您收到Compliment时，它会显示在发送者的个人档案中。您还将收到通知，告诉您有人很欣赏您个人档案中的部分内容。",
      "查看Compliment",
      "您可以通过点击对方档案下方的黄色心形图标发送Compliment。您点击的位置很重要。点击位置对应着您想要称赞的特定内容，例如某张照片或档案中的部分内容！点击您想要称赞的内容后，您将看到一个文本框，供您编写您的消息（不超过150个字符）。",
      "发送Compliment",
      "下载Bumble，立即体验“发现”",
      "每天上午9点，您将在“发现”中看到为您量身定制的新人选。您可以借助共同之处与他们建立联系。",
      "“发现”的更新时间是什么？",
      "“发现”页面中的潜在配对人选分为 3 大类别：相似的兴趣爱好、相同的交友目的和共同的社区。此外，我们还会根据您的历史配对人选为您推荐。",
      "“发现”页面的人选是如何选出来的？",
      "个性化​推荐​机制，​为您​推荐​最​合适​的​人选，​每日​更新",
      "在{{qr_code}}“发现”​遇见​与你​同频​的人",
      "在“发现”页面您将看到我们选出的我们认为您会喜欢的人选。推荐机制基于您的兴趣爱好、交友目的以及社区。",
      "体验“发现”",
      "想用新​方式​找到​共同点？",
      "“发现”页面每天更新，为您推送更多与您契合的人。“发现”可以帮助您减少寻找配对对象的时间，从而让您有更多的时间跟有共鸣的人交流。",
      "推荐人选​每日更新",
      "“发现”根据您的个人档案以及您的历史配对人选为您推荐专属人选。您可以在这里看到跟您有着相同喜好以及相同追求的人，无论是素食龙虾卷，还是恋爱关系。",
      "个性化{{__EOL__}}推荐",
      "下载Bumble",
      "常见疑问解答",
      "想让Bumble浏览过程更私密？",
      "当您开启隐身模式时，您的个人档案将不会被推送给其他Bumble用户。只有您右滑的用户可以看到您的个人档案。",
      "隐身模式是什么？",
      "隐身模式关闭时，若您的Bumble个人档案符合某些用户的喜好时，如年龄或位置，您的个人档案将对他们可见。开启隐身模式后，只有您右滑的对象能够看到您的个人档案。",
      "谁可以在Bumble的隐身模式下看到我？",
      "使用隐身模式不同于<a href={{blocking_on_bumble_url}}>在Bumble上封锁某人</a>。 使用此功能时，如果您喜欢某人（或者您以前给他们发过消息），对方即可看到您的个人档案。",
      "隐身模式相当于封锁功能吗？",
      "使用Bumble的“隐身模式”自由决定个人档案对谁可见。",
      "Bumble的隐身模式",
      "如果您想暂时离开约会圈，或在Bumble上以隐身模式浏览，您可以切换到隐身模式。 打开此功能后，我们将对所有人隐藏您的个人档案。只有您右滑的人能够看到您的个人档案。 隐身模式让您完全掌控您分享的内容对谁可见。",
      "开启​隐身模式​隐藏​您的​个人档案",
      "谁能看到您的照片和个人信息由您决定。",
      "想在您的个人档案中添加一些私密信息吗？您可以与当前配对对象私密分享您想要分享的内容。",
      "放心浏览，不用担心遇到前任、同事或其他你不想在约会场景下见到的人。",
      "完全掌控",
      "选择您要分享的内容",
      "避免尴尬时刻",
      "隐身模式为什么让人喜欢",
      "开通了{{service_level_lexeme_name}}和{{service_level_lexeme_name_two}}的用户可使用隐身模式。开通会员服务后，前往个人档案页面，点击右上角的“设置”，然后切换到隐身模式。",
      "升级至{{service_level_lexeme_name}}",
      "在Bumble上如何使用隐身模式",
      "下载Bumble，就在今天试试立即配对吧",
      "在Bumble应用程序中，点击屏幕底部的个人档案图标。进入后，您会在屏幕右上角找到二维码图标。点击即可打开二维码。",
      "“我的个人二维码”在哪里？",
      "不行。您需要连接网络使用实时二维码才能使用立即配对。",
      "我可以截屏并分享我的二维码吗？",
      "不用！只要一方扫描二维码，你将自动与对方匹配。",
      "两个人都需要扫描二维码吗？",
      "“立即配对”可以让您立即查看您和配对对象的共同点——让您从一开始就了解两人的契合度。",
      "快速找到​共同点",
      "在Bumble线下​活动​中​遇见​了​聊得来​的人？​在​应用​程序​上​继续​聊天。",
      "心动就要{{qr_code}}立即配对",
      "使用“立即配对”代替在社交平台上互相关注或交换电话号码，让您无忧地将初次见面升级至“继续聊天”。让对方扫描您的二维码，即可在Bumble上实现立即配对。",
      "试试​“立即配对”",
      "在Bumble线下​活动​中​遇见​了​让你​心动​的人？",
      "相比于在社交平台上互相关注或交换电话号码，“立即配对”可以让您先在应用程序中试试是否与对方来电。有了Bumble的安全功能，您可以按照自己的步伐增进了解。",
      "更安全的​交友方式",
      "在Bumble线下活动中相遇后，您可以使用应用程序与对方继续聊天，并且还能通过共同的兴趣爱好拉近距离——无论是完美的咖啡浇注还是培育罕见的仙人掌。",
      "继续聊天",
      "在Bumble上试试Opening Moves",
      "Opening Move是您与喜欢的人开启聊天的新方式。 预设消息后，您就可以轻松坐等对方回复了。收到回复后，您可以挑选您最喜欢的答复继续聊天。",
      "如何使用Bumble的Opening Moves",
      "对于非二元和同性配对，双方都可以设置和回应Opening Move。",
      "同性约会可以使用Bumble Opening Move吗？",
      "当有人回复您的Opening Move时，您的聊天界面会弹出对方的个人档案。女性用户可在24小时内回复，否则配对将过期失效。之后，您可以进行回复或开启新的话题。",
      "如何查看有没有人回应了我的Opening Move？",
      "Opening Move，以全新方式开启聊天，减轻开场压力。开场更简单、更快捷，聊天内容更有趣。",
      "Bumble Opening Moves",
      "Opening Move是一个让开场更轻松的新方式。您可以选择平台建议的Opening Move，例如“在派对上玩唱歌击鼓传花时，你会唱什么歌？”，或者编写您自己的Opening Move。预设Opening Move后，您的配对对象将在配对成功界面中予以回复。",
      "什么是Opening Move？",
      "减轻压力",
      "分享真实故事，展现个人魅力",
      "开始更好的对话",
      "Opening Moves可以帮您……",
      "预设Opening Move。成功配对时，对方将自动收到您预设的Opening Move。前往个人档案中的“Opening Moves”，选择一条建议提问，或发挥创造力自行编写提问。您可以随时更改Opening Moves。",
      "试试Opening Moves",
      "如何使用Bumble的Opening Moves",
      "<a href={{see_more_url}}>查看更多</a>",
      "想要暂时离开？试试瞌睡模式",
      "可以。您可以随时访问设置页面，关闭瞌睡模式。",
      "我可以提前关闭瞌睡模式吗？",
      "前往设置，点击瞌睡模式，然后选择离开时长。你准备好了，可以随时回来。",
      "如何开启瞌睡模式？",
      "不行。在瞌睡模式下，所有滑动和聊天活动都会暂停。 进入浏览档案界面时，您将看到瞌睡模式的剩余时间以及关闭瞌睡模式的选项。",
      "在瞌睡模式下，我仍然可以滑动并聊天吗？",
      "如果您设置了显示状态，那么已经与您聊过天的用户将看到您的“离开”状态。 否则，他们将在收件箱中以正常方式看到与您的聊天记录。",
      "当我开启瞌睡模式时，配对对象会看到我的状态吗？",
      "Bumble活跃度由您掌控。您可以随时离开约会圈。",
      "您可以通过设置状态让已配对的用户知道您的近况，以免对方误会您玩<a href={{ghosted_article_url}}>消失游戏</a>。系统会自动将您的状态告知您的配对对象，您无需发送信息。",
      "暂别约会，但保留配对及聊天。",
      "按自己的节奏约会（或休息）",
      "让您的配对对象知道您的近况",
      "暂停约会，但保留现有配对",
      "为什么我们喜欢瞌睡模式",
      "打开Bumble应用程序，进入个人档案页面，然后在右上角点击设置图标。在设置中，点击暂停并选择您离开的时长。您可以隐藏您的资料24小时、72小时、一周或长期。{{__EOL__}}{{__EOL__}}请注意，无活跃聊天的配对对象将在您暂别期间失效。如果您选择设置状态，您当前聊天的用户将能够看到您的暂别状态。",
      "Bumble 瞌睡模式是什么",
      "生活很忙碌时，不妨打开瞌睡模式。暂停您的Bumble帐户，按照您自己的节奏约会。",
      "Bumble瞌睡模式",
      "瞌睡模式是暂停您的账户的一种简单方式，当您想休息或远离约会时。您仍然可以与现有联系人聊天。启动该模式后，我们会隐藏您的个人档案，直到您回来为止。开启瞌睡模式不会导致您失去个人档案信息或现有联系人。",
      "暂停您的​Bumble​个人档案",
      "开启瞌睡模式时，您可以通过状态更新让现有联系人知道您会短暂离开一段时间。您可以选择“旅行中”、“专注工作中”、“暂别电子设备”或“专注自己”。无论您如何选择，我们都支持您。",
      "添加​“离开”​状态",
      "启用瞌睡模式将暂停您的Bumble（或{{page_partner_name_bff}})账户。若您不主动告知，您的配对对象不会知道您打开了瞌睡模式。如果您决定提前关闭瞌睡模式，只需返回设置并点击“取消瞌睡模式”。操作就是这么简单。",
      "暂停聊天",
      "试用Bumble SuperSwipe",
      "不限次数！{{service_level_lexeme_name}}用户每周享有{{num}}个[[superswipe:num]]。另外，您还可以随时充值。免费会员可以购买SuperSwipe礼包。",
      "我可以在Bumble上发送多少个SuperSwipes？",
      "当有人给您SuperSwipe时，您会立即收到通知。您将在该名用户的个人档案上看到一个标签，表明对方喜欢您。",
      "如何知道有人给我发送了 SuperSwipe？",
      "不一样的心动感觉？试试很棒的破冰方法吧。发送SuperSwipe，让对方知道您的心意，更快开启聊天。",
      "Bumble SuperSwipe",
      "从人群中脱颖而出并不总是简单的，但SuperSwipe可以帮助您留下深刻的第一印象。{{__EOL__}}{{__EOL__}}当您使用SuperSwipe时，您喜欢的人会收到通知。除此以外，您的个人档案会被优先推送给对方。如果对方右滑了您，就可以开始聊天了。",
      "什么是SuperSwipe？",
      "大胆表现，脱颖而出",
      "开启更多聊天",
      "认识更多朋友",
      "SuperSwipe 可以帮助您……",
      "如果您开通了<a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a>或Bumble  {{service_level_lexeme_name_one}}，每周您将获得{{num}}个免费[[superswipe:num]]。此外，所有成员都可以从个人档案页面购买多至 {{num_two}}个 [[superswipe:num_two]]。如果您开通了{{service_level_lexeme_name_three}}，每周您将享有 {{num_three}}个[[superswipe:num_three]] 。{{__EOL__}}{{__EOL__}}使用SuperSwipe很简单——当您看到喜欢的人时，只需点击对方个人档案底部的黄色星形图标即可。",
      "如何使用SuperSwipe",
      "Apple与Apple商标为Apple Inc.商标。{{__EOL__}}Google Play为Google LLC.商标。",
      "开启Bumble旅行模式，​约会不停歇",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}}用户</a>可以使用旅行模式。启用旅行模式，我们将为您推送所选城市的用户。",
      "如何使用旅行模式？",
      "差不多。我们有一些安全限制，这些限制可能会随时间变化，但旅行模式可用于大多数目的地。",
      "我可以在任何地方使用旅行模式吗？",
      "旅行模式下，您的显示位置将是您选择城市的中心。该区域的用户便可看到您的个人档案。您的个人档案上将会有一条提示告诉其他用户您正在旅行中或正在计划旅行。",
      "打包行李去旅行还是前往新城市工作？打开Bumble旅行模式，随时随地认识新人。",
      "Bumble旅行模式",
      "旅行模式是您在离开日常居住地时认识当地人的绝佳工具。您可以将您的位置设置为世界上的任何地方，您的个人档案中的位置信息随之会改为您所选的城市。开启旅行模式，您即可在旅途中认识当地人。",
      "什么是​旅行模式？",
      "没有人比当地人更了解一个地方。使用旅行模式，认识当地人， 为旅途增添特别的回忆。",
      "提前7天将您的位置改成您即将前往的城市，以便您提前安排约会及认识当地人。",
      "阳光沙滩不仅仅是度假胜地——无论是度假、出差或是探索新城市，你都有可能收获家的温馨。在新的地方遇见浪漫。",
      "发现当地最佳约会地点",
      "开启新旅程",
      "建立长期的人际关系",
      "喜欢旅行模式的原因",
      "Bumble 的 <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} 和{{service_level_lexeme_name_two}} </a> 用户可以使用旅行模式。在个人档案页面点击右上角的设置图标，然后下拉到旅行模式。选择你的新位置即可查看该区域的用户。你的个人档案上会出现相应图标以便他人知道您当前正在使用旅行模式。",
      "旅行模式是什么？",
      "试一试",
      "试试Bumble网页版",
      "当然。Bumble 网络版可在PC端、Mac以及平板电脑上的所有主流浏览器上运行。",
      "我可以在我的PC或Mac上使用Bumble吗？",
      "完全可以。你可以在Bumble网页版中发送消息或进行视频或音频通话。",
      "我可以在网页版中跟配对对象聊天吗？",
      "登入，请选择“登入”，或点击<a href={{sign_in_url}}>这里</a>。您可以使用您的Apple ID、Facebook或手机号码登录。",
      "如何在登入Bumble网页版？",
      "不想用手机？试试Bumble网页版，还是那么棒的Bumble体验，而且屏幕更大。",
      "使用Bumble网页版",
      "登入Bumble网页版很简单。访问我们的网站，点击“登入”，然后使用您的苹果ID、Facebook或手机号码（您通常使用的登录方法）。就是这么简单！登入成功！您的聊天和配对已同步。",
      "登录",
      "在浏览器中登入",
      "编辑或更新个人资料，应用高级筛选条件。",
      "完成身份认证，提高配对几率。",
      "分享可爱的新快照或删除较旧的快照。",
      "更新您的个人档案",
      "让他人知道你身份真实",
      "添加或删除照片",
      "网页版Bumble的功能",
      "你可以在左侧查看聊天和配对列表，在右边查看潜在匹配对象。{{__EOL__}}{{__EOL__}}你可以使用左右箭头键，或点击屏幕右侧显示的符号来浏览他人档案以向左或向右筛选人选。",
      "如何在网页上使用Bumble？",
      "友善待人是最具魅力的。做自己，无顾虑。女性掌握主动权。",
      "首创Make The First Move™",
      "美好快乐的生活离不开健康的人际关系。无论是约会、交友、拓展人际网，Bumble都能让你自信地建立人际关系。",
      "Bumble的重要性",
      "无论是何种恋爱关系，或是关系中的哪一阶段，我们都鼓励人们抱有诚意、自信友善、平等尊重地对待他人。我们欢迎各个性取向的人们使用我们的平台，无论你是想寻找真爱或是想体验人生乐趣。",
      "Bumble的价值观",
      "在Bumble，女性通过“Make the First Move”开启聊天。用户也可以使用Opening Move预设留言，等待对方回复。我们一改传统交友秩序，鼓励从开始的那一刻就开启平等关系。",
      "Bumble的使用规则",
      "关于我们",
      "立即下载",
      "使用APP体验更佳。",
      "我们的功能，​让​线上​约会​也​能​充满​乐趣",
      "2024年2月12日",
      "如何在恋爱关系中拉近情感距离",
      "2024年1月25日",
      "从未使用过约会软件。查看Bumble的使用方法",
      "2024年2月5日",
      "Deception Detector™",
      "公开交友新闻",
      "浏览<b>专属推荐</b>，查看根据你的喜好与过往配对专门为你推送的人选。",
      "专门为你挑选",
      "使用<b>瞌睡模式</b>，按下约会暂停键。我们会在这里一直等待你再次出发。",
      "暂别交友",
      "使用<b>视频通话</b>和<b>语音通话</b>，足不出户收获真实交友体验。",
      "来电啦",
      "显示更多",
      "选择<b>Opening Moves</b>，坐等与你配对的TA开启聊天。",
      "省力多得",
      "使用<b>旅行模式</b>，装上免税小吃，在全球随心配对。",
      "带我一起飞",
      "有人让你怦然心动？使用<b>SuperSwipe</b>，让对方知道你的心意。",
      "赢得对方的关注",
      "给对方送上<b>Compliments</b>，从人群中脱颖而出。",
      "打动对方",
      "使用<b>隐身模式</b>隐藏个人档案，不让上司、亲戚或前任看到你的档案。",
      "隐身行动",
      "随心约会",
      "几个月后，她在这里还认识了她现在的伴娘。",
      "汉娜在Bumble结识了她现在的丈夫。",
      "卡瑞妮和尤良在Bumble相遇。一开始，卡瑞妮被尤良的旅行照片所吸引。现在，他们在一起建立他们的小家。",
      "卡瑞妮和尤良",
      "布莱恩和卡茜在海外工作，但他们在Bumble相遇",
      "布莱恩和卡茜",
      "他们成功了，你也可以",
      "继续，下一步",
      "查看新内容",
      "您值得拥有更好的。因此，我们推出了很多有效方法减轻您的约会压力，增加您的约会机会。",
      "告别择偶标准上的妥协",
      "关于Bumble",
      "我们是唯一一个将女性体验放在第一位的交友软件。当女性被善待时，每个人都能得到善待。",
      "Make the first move™",
      "相识你真正想要交往的人，然后迈出第一步，开启聊天。建立有诚意的配对，以共同喜好为起点积极互动。最重要的是，在过程中收获快乐。",
      "使用Bumble Date遇见意中人",
      "寻找事业上的伙伴。与其他专业人士配对，找到新工作、导师或是进入全新的领域。",
      "使用Bumble Bizz，拓展事业",
      "遇见志同道合的人，或是能够殊途同归的人。无论你身在家乡或身在异乡，结识新朋友，一起体验新鲜事。",
      "使用Bumble For Friends结识新朋友",
      "我们不仅仅是约会交友平台",
      "Bumble作为交友软件，鼓励女性迈出第一步。我们的软件改变了约会的传统方式与理念。我们鼓励创建有意义的恋爱关系与人际网络。立即下载。",
      "Bumble {{social_network}}账号",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "对不起，无法找到该网页。",
      "联系我们",
      "登录",
      "啊，发生了一个错误",
      "(opens in new window)",
      "常见问答",
      "Cookie Policy",
      "Premium",
      "Bumble—— The Blog",
      "工作机会",
      "聊天",
      "城市",
      "管理Cookies",
      "版权所有© 2006至今。Bumble。保留所有权力。",
      "交友信赖度报告",
      "Apple与Apple商标为Apple Inc.商标。{{__EOL__}}Google Play为Google LLC.商标。",
      "立即下载",
      "Facebook",
      "关注我们",
      "帮助中心",
      "Instagram",
      "语言",
      "LinkedIn",
      "Bumble属于Bumble Inc.",
      "隐私政策",
      "Safety Centre",
      "网站导览",
      "现代奴役法案声明",
      "条件与条款",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "称赞"
            ],
            "singular": [
                  "称赞"
            ]
      },
      "day": {
            "plural": [
                  "天"
            ],
            "singular": [
                  "天"
            ]
      },
      "hour": {
            "plural": [
                  "小时"
            ],
            "singular": [
                  "小时"
            ]
      },
      "minute": {
            "plural": [
                  "分钟"
            ],
            "singular": [
                  "分钟"
            ]
      },
      "month": {
            "plural": [
                  "月"
            ],
            "singular": [
                  "月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipe"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
