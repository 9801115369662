import audioCalls from './audio-calls';
import compliments from './compliments';
import discover from './discover';
import incognitoMode from './incognito-mode';
import instantMatch from './instant-match';
import openingMoves from './opening-moves';
import snoozeMode from './snooze-mode';
import superswipe from './superswipe';
import travelMode from './travel-mode';
import web from './web';

export const features = {
    audioCalls,
    compliments,
    incognitoMode,
    openingMoves,
    snoozeMode,
    superswipe,
    travelMode,
    web,
    instantMatch,
    discover,
};
