import { AppLink } from 'lib/app/app-link';
import Localization from 'lib/localization';

export default {
    get lxIncognitoModeHeroTitle() {
        return Localization.get('bumblesite@pages.features.incognito-mode.hero.title');
    },
    get lxIncognitoModeHeroSubtitle() {
        return Localization.get('bumblesite@pages.features.incognito-mode.hero.subtitle');
    },
    get lxIncognitoModeOneBlockTitle() {
        return Localization.get('bumblesite@pages.features.incognito-mode.one.block.title');
    },
    get lxIncognitoModeOneBlockBodyText() {
        return Localization.get('bumblesite@pages.features.incognito-mode.one.block.body.text');
    },
    get lxFeaturesDownloadBumbleCta() {
        return Localization.get('bumblesite@pages.features.download.bumble.cta');
    },
    get lxIncognitoModeTwoBlockTitle() {
        return Localization.get('bumblesite@pages.features.incognito-mode.two.block.title');
    },
    get lxIncognitoModeTwoBlockBodyText() {
        return Localization.get('bumblesite@pages.features.incognito-mode.two.block.body.text', {
            service_level_lexeme_name: Localization.get('revenue_onboarding@gold.revamp.premium'),
            service_level_lexeme_name_two: Localization.get('bumble_boost@gold.name'),
        });
    },
    get lxIncognitoModeTwoBlockCta() {
        return Localization.get('bumblesite@pages.features.incognito-mode.two.block.cta', {
            service_level_lexeme_name: Localization.get('revenue_onboarding@gold.revamp.premium'),
        });
    },
    get lxIncognitoModeThreeBlockTitle() {
        return Localization.get('bumblesite@pages.features.incognito-mode.three.block.title');
    },
    get lxIncognitoModeThreeBlockSubtitleOne() {
        return Localization.get(
            'bumblesite@pages.features.incognito-mode.three.block.subtitle.one',
        );
    },
    get lxIncognitoModeThreeBlockBodyTextOne() {
        return Localization.get(
            'bumblesite@pages.features.incognito-mode.three.block.body.text.one',
        );
    },
    get lxIncognitoModeThreeBlockSubtitleTwo() {
        return Localization.get(
            'bumblesite@pages.features.incognito-mode.three.block.subtitle.two',
        );
    },
    get lxIncognitoModeThreeBlockBodyTextTwo() {
        return Localization.get(
            'bumblesite@pages.features.incognito-mode.three.block.body.text.two',
        );
    },
    get lxIncognitoModeThreeBlockSubtitleThree() {
        return Localization.get(
            'bumblesite@pages.features.incognito-mode.three.block.subtitle.three',
        );
    },
    get lxIncognitoModeThreeBlockBodyTextThree() {
        return Localization.get(
            'bumblesite@pages.features.incognito-mode.three.block.body.text.three',
        );
    },
    get lxFeaturesFaqTitle() {
        return Localization.get('bumblesite@pages.features.faq.title');
    },
    get lxIncognitoModeFaqQuestionOneTitle() {
        return Localization.get('bumblesite@pages.features.incognito-mode.faq.question.one.title');
    },
    get lxIncognitoModeFaqQuestionOneAnswer() {
        return Localization.get('bumblesite@pages.features.incognito-mode.faq.question.one.answer');
    },
    get lxIncognitoModeFaqQuestionTwoTitle() {
        return Localization.get('bumblesite@pages.features.incognito-mode.faq.question.two.title');
    },
    get lxIncognitoModeFaqQuestionTwoAnswer() {
        return Localization.get(
            'bumblesite@pages.features.incognito-mode.faq.question.two.answer',
            { blocking_on_bumble_url: AppLink.BLOCKING_ON_BUMBLE },
        );
    },
    get lxIncognitoModeFaqQuestionThreeTitle() {
        return Localization.get(
            'bumblesite@pages.features.incognito-mode.faq.question.three.title',
        );
    },
    get lxIncognitoModeFaqQuestionThreeAnswer() {
        return Localization.get(
            'bumblesite@pages.features.incognito-mode.faq.question.three.answer',
        );
    },
    get lxIncognitoModeBrowsePrivatelyText() {
        return Localization.get('bumblesite@pages.features.incognito-mode.browse.privately.text');
    },
};
