/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Gå tilbake",
      "Last ned i App Store",
      "Få det i Google Play",
      "Karusell",
      "Side {{number}}",
      "Neste side",
      "Forrige side",
      "Side",
      "Side {{number}} av {{total}}",
      "Ikke selg eller del min personlige informasjon",
      "Ikke selg eller del min personlige informasjon",
      "Send Compliments på Bumble for å vise at du liker profilen deres, og fang oppmerksomhet deres før du matcher. Prøv det nå",
      "Compliments | Hvordan sende et kompliment | Bumble",
      "En personlig side som fremhever de mest kompatible personene dine, oppdatert daglig.",
      "Oppdag | Finn kompatible personer | Bumble",
      "Date i eget tempo og kontroller hvem som ser din Bumble-profil med Usynlighet. Lær mer om bruk av Usynlighet på Bumble i vår guide.",
      "Usynlighet | Skjul profilen din | Bumble",
      "Etter at dere møtes på et Bumble-event, kan dere bygge videre på kjemien ved å fortsette samtalen i appen.",
      "Umiddelbar match | Fortsett samtalen på appen | Bumble",
      "Prøv en ny måte å starte samtalen på med Bumbles Opening Moves-funksjon. Fang interessen deres, sett stemningen, og kom deg til de gode samtalene, raskere",
      "Bumble Opening Moves | Bumble",
      "Trenger du en liten pause eller litt tid for deg selv? Finn ut hvordan du kan pause Bumble-kontoen din med Bumbles Slumre-modus. Den lar deg date på dine egne vilkår.",
      "Slumre-modus | Pause kontoen din | Bumble",
      "Nettopp sett noen du virkelig liker på Bumble? Bruk en Superswipe for å vise at du er seriøs. Trykk på stjernen på profilen deres for å begynne, eller se flere detaljer her.",
      "Bumble Superswipe | Hva er en Superswipe? | Bumble",
      "På reisefot for jobb eller fornøyelse? Reisemodus på Bumble kan hjelpe deg med å knytte spennende nye forbindelser på et nytt sted og date hvor som helst. Oppdag Reisemodus her nå",
      "Bumble sin Reisemodus | Date hvor som helst | Bumble",
      "Vil du vite om forbindelsen du føler via meldinger betyr at kjemien er der i det virkelige liv? Prøv en videochat eller en telefonsamtale på Bumble nå og sjekk vibbene",
      "Videochat | Ta en telefon | Bumble",
      "Opplev Bumble Web, den ultimate opplevelsen.",
      "Bumble Web | Bumble",
      "Avslutt kontrakter her",
      "Bumble startside",
      "Lukk meny",
      "Åpne meny",
      "Språkvalg",
      "Footer",
      "Tilrettelegging på Bumble",
      "Apple og Apple-logoen er varemerker fra Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Jobbmuligheter",
      "Eventer",
      "Google Play er et varemerke som eies av Google LLC.",
      "Retningslinjer",
      "Investorer",
      "Administrer cookies",
      "Administrer cookies",
      "Modern Slavery Act Statement",
      "Lagring av data",
      "Personvern",
      "Sesongbestemte dating-guider",
      "Vilkår & Betingelser",
      "Appen",
      "Personvernsreglement for Consumer Health Data i Washington",
      "Om",
      "Ambassadører",
      "The Buzz",
      "Få nye venner og finn tilhørighet",
      "Bygg nettverk på profesjonelt vis og gjør karrieretrekk",
      "Finn et forhold, noe mindre seriøst eller noe midt imellom",
      "Finn ut mer",
      "Gå globalt med Reisemodus",
      "Funksjoner",
      "Compliments",
      "Send Compliments for å skille deg ut i mengden.",
      "Oppdag",
      "En personlig side som fremhever de mest kompatible personene dine, oppdatert daglig.",
      "Usynlighet",
      "Bruk Usynlighet hvis du vil skjule profilen din for noen – for eksempel eksen eller sjefen.",
      "Umiddelbar match",
      "Begynte samtalen på et Bumble-event? Fortsett på appen.",
      "Opening Moves",
      "Prøv Opening Moves< for å få chatten i gang på en kul måte.",
      "Slumre-modus",
      "Ta en pause med Slumre-modus. Du vet hvor vi er når du er klar for dating igjen.",
      "Superswipe",
      "Imponert av profilen deres? La dem få vite det med en SuperSwipe.",
      "Reisemodus",
      "Finn matcher hvor som helst i verden med Reisemodus.",
      "Videochat",
      "Bruk videochat eller lydsamtale for å sjekke om du er klar til å møtes på ordentlig.",
      "Web-app",
      "Få Bumble-opplevelsen direkte fra nettleseren din.",
      "The Shop",
      "Hoved",
      "Hopp til hovedside",
      "Bumble logo",
      "Vil du bli kjent med dem? Bruk videochat på Bumble",
      "Bare trykk på video-symbolet i chatten din for å starte en videochat. Helt uten å dele noen personlig kontaktinformasjon.",
      "Hvordan fungerer videochat på Bumble?",
      "Nei. Alle samtaler og chats på Bumble holder dine personlige opplysninger trygge og sikre. De vil ikke få nummeret ditt eller andre kontaktopplysninger med mindre du deler dem.",
      "Vil matchen min se telefonnummeret mitt?",
      "Absolutt! Hvis du ikke er helt klar for å treffes i virkeligheten, kan en lydsamtale være tingen. Bare velg ringe-symbolet i stedet.",
      "Kan jeg prøve lydsamtale i stedet?",
      "Vi vet at det er vanskelig å vite om kjemien dere har på chat blir den samme i virkeligheten. Her kommer videochat og lydsamtaler inn.",
      "Videochat på Bumble",
      "<a href={{video_chat_url}}>Videochatter og lydsamtaler</a> kan hjelpe dere å bli kjent med hverandre og avgjøre om du vil ta ting et steg videre. Det beste? Når dere ringes via Bumble, trenger du ikke å dele telefonnummeret ditt, så dine personlige opplysninger forblir trygge frem til du er klar til å dele dem.",
      "Sjekk kjemien med videochat",
      "Finn ut om tiden er inne til å møtes i virkeligheten.",
      "Bli kjent raskere (og bedre). Man kan bli godt kjent på video eller i en lydsamtale.",
      "Du har kontrollen. Kontaktdetaljene dine blir holdt private, så du kan velge å dele dem når du vil.",
      "Se om du er klar til å møtes i virkeligheten",
      "Sjekk stemningen",
      "Bli kjent uten å gi ut personlig info",
      "Hvorfor du bør bruke videochat og samtaler",
      "Klar til å starte en samtale? Du kan velge å bruke videochat eller lydsamtale direkte fra chatten i Bumble-appen. For å starte, klikk på det grå kamera-ikonet øverst i høyre hjørne. Du vil også se tre prikker ved siden av ikonet – klikk på dette og du vil se muligheten for lydsamtale også.",
      "Hvordan videochatte",
      "Klar til å prøve Compliments?",
      "Når du sender et kompliment, vil personen som mottar det se det når du dukker opp i køen deres.",
      "Hva skjer når du sender et kompliment på Bumble?",
      "Hvis komplimentet deres fanget interessen din, kan dere gå videre og matche. Du ser komplimentet i chatten din, og de vanlige reglene for å <a href={{make_the_first_move_url}}>ta det første steget</a> gjelder.",
      "Hvordan svarer jeg på komplimenter?",
      "Du kan sende {{num}} [[Compliments:num]] gratis. Når du er tom, kan du kjøpe flere i Bumble-appen i pakker på opptil 30. En av disse vil bli brukt for å sende det komplimentet (spar resten til en annen profil du kanskje liker). Ikke bekymre deg, de utløper ikke!",
      "Hvor mange komplimenter kan jeg sende?",
      "Du kommer langt med noen fine ord. Hvis du vil sende komplimenter, men strever etter å finne ordene, kan du prøve disse for inspirasjon:",
      "Se flere eksempler",
      "\"Hvis du liker [artist name] har du god smak, spør du meg!\"",
      "\"Smilet ditt er smittsomt!\"",
      "\"Bioen din fikk meg til å le. Bra jobba!\"",
      "Hvordan sende bedre Compliments på Bumble",
      "Lyst til å gi noen et smil? Da er du klar til å prøve ut Compliments.",
      "Compliments på Bumble",
      "Compliments på Bumble er en funksjon som gjør at du kan la en potensiell match få vite at du liker profilen deres ekstra godt. Du får Compliments som del av medlemskapet ditt på {{service_level_lexeme_name}}. Det er også en måte å sende og motta meldinger på før dere har matchet.",
      "Hvordan Compliments fungerer",
      "Når du får et kompliment, vil dette vises på avsenderens profil. Du vil også få et varsel om at noen liker noe på profilen din.",
      "Se dine Compliments",
      "Du kan sende et kompliment ved å trykke på det gule hjertet øverst på en profil. Hvor du trykker er viktig, fordi du liker da det spesifikke bildet eller delen av profilen! En tekstboks vil så åpnes så du kan skrive en melding (bare sørg for at den er maks 150 tegn).",
      "Send Compliments",
      "LAST NED Bumble FOR Å PRØVE OPPDAG NÅ",
      "Hver dag fra kl. 09 vil du se nye personer i Oppdag. Du kan bli kjent med dem over det dere har til felles.",
      "Når oppdateres Oppdag?",
      "Din Oppdag sorterer potensielle matcher inn i tre hovedkategorier: lignende interesser, lignende datingmål og felles fellesskap/formål. Profilforslag vises også, basert på tidligere treff og forbindelser.",
      "Hvordan finnes profilene i min Oppdag?",
      "En personlig side som fremhever de mest kompatible personene dine, oppdatert daglig",
      "FINN PERSONER SOM MATCHER DEG MED {{qr_code}} OPPDAG",
      "Med Oppdag vil vi vise deg et utvalg av personer vi tror du vil like, basert på dine lignende interesser, datingmål og fellesskap/verdier.",
      "Prøv Oppdag",
      "EN NY OG SPENNENDE MÅTE Å FINNE LIKESINNEDE PÅ",
      "Oppdag oppdateres hver dag, for å vise deg enda flere personer du kan være kompatibel med – så du kan bruke mindre tid på å lete etter en match, og mer tid på å bli kjent med personer du vet du vil komme overens med.",
      "ANBEFALINGER OPPDATERT HVER DAG",
      "Anbefalingene i Oppdag er basert på profilen din og hvem du har matchet med før, så du vil se personer som liker det du liker og ser etter det samme – enten det er en samtale over sashimi eller et langvarig forhold.",
      "SKREDDERSYDD{{__EOL__}}FOR DEG",
      "Last ned Bumble",
      "Ofte Stilte Spørsmål",
      "Klar til å se gjennom Bumble anonymt?",
      "Når du aktiverer Usynlighet vil du ikke vises for andre Bumble-medlemmer, med mindre du liker deres profil.",
      "Hvordan fungerer Usynlighet?",
      "Uten Usynlighet er profilen din synlig for andre personer på Bumble når du matcher deres preferanser, som alder og lokasjon. Når du aktiverer Usynlighet, vil du kun bli sett av folk som du har likt.",
      "Hvem kan se meg hvis jeg har Usynlighet på Bumble?",
      "Usynlighet er ikke det samme som å <a href={{blocking_on_bumble_url}}>blokkere noen på Bumble</a>. Med denne funksjonen vil de du har likt (eller skrevet med) kunne se profilen din.",
      "Er Usynlighet det samme som å blokkere noen?",
      "Kontroller hvem som ser profilen din med Usynlighet på Bumble.",
      "Usynlighet på Bumble",
      "Hvis du vil ta en pause fra dating, eller se på Bumble uten at andre medlemmer ser på profilen din, kan du slå på Usynlighet. Når du skrur på denne funksjonen vil du skjules fra alle – med mindre du liker dem først. Det lar deg kontrollere hva du deler, og hvem som ser deg.",
      "Skjul profilen din med Usynlighet",
      "Du bestemmer hvem som kan se bildene dine og din personlige info.",
      "Vil du ha med noe personlig på profilen din? Du kan diskret dele det du vil med dine nåværende matcher.",
      "Kikk gjennom profiler uten å bekymre deg for å støte på en eks, en kollega eller en annen du helst vil unngå.",
      "Ta kontrollen",
      "Velg hva du deler",
      "Unngå flaue møter",
      "Hvorfor du vil elske Usynlighet",
      "Usynlighet er tilgjengelig for medlemmer som har {{service_level_lexeme_name}} eller {{service_level_lexeme_name_two}}. Når du har fått dette, kan du slå på Usynlighet ved å gå til innstillingene dine øverst i høyre hjørne.",
      "Oppgrader til {{service_level_lexeme_name}}",
      "Hvordan bruke Usynlighet på Bumble",
      "LAST NED Bumble FOR Å PRØVE UMIDDELBAR MATCH I DAG",
      "I Bumble-appen, gå til profilikonet nederst på skjermen din. Her vil du finne symbolet for QR-kode øverst til høyre på skjermen. Trykk på dette.",
      "Hvor kan jeg finne min personlige QR-kode?",
      "Nei! Du må koble deg til nettet og bruke den aktuelle, live QR-koden for at Umiddelbar match skal funke.",
      "Kan jeg ta skjermbilde av QR-koden for å dele den?",
      "Nei! Når en person scanner koden, vil dere bli matchet automatisk.",
      "Må begge personene scanne QR-koden?",
      "Med Umiddelbar match kan du automatisk se hva du og din nye match har til felles – et glimt av hvordan dere passer sammen helt fra starten av.",
      "FINN BØLGELENGDEN, ENDA RASKERE",
      "Begynte samtalen på et Bumble-event? Fortsett på appen.",
      "FRA HYGGELIG MØTE TIL {{qr_code}} UMIDDELBAR MATCH",
      "Med Umiddelbar match er det enkelt å ta et hyggelig møte videre uten å dele telefonnumre eller profiler på sosiale medier. Bare la dem scanne din QR-kode for å matche på Bumble.",
      "Prøv Umiddelbar match",
      "TRUFFET NOEN PÅ ET Bumble-EVENT?",
      "I stedet for å bytte sosiale profiler eller telefonnumre, lar Umiddelbar match deg sjekke stemningen i appen først. På denne måten kan dere lære hverandre bedre å kjenne på eget initiativ, takket være Bumble sine sikkerhetsfunksjoner.",
      "EN TRYGGERE MÅTE Å BLI KJENT PÅ",
      "Etter at dere møtes på et Bumble-event, kan dere bygge videre på kjemien ved å fortsette samtalen i appen og bli kjent over felles interesser – enten det er en kjærlighet for den perfekte cappucinoen eller hagearbeid.",
      "HOLD SAMTALEN GÅENDE",
      "Prøv Opening Moves på Bumble",
      "Opening Moves er en annen måte å chatte med folk du liker på. Når du har valgt en melding å sende, kan du slappe av og vente på svar, og så ta det derfra.",
      "Hvordan bruke Bumble Opening Moves",
      "Med matcher av samme kjønn eller ikke-binære matcher, kan begge skrive eller svare på en Opening Move.",
      "Kan jeg bruke Bumble Opening Moves til å matche med personer av samme kjønn?",
      "Når noen svarer på din Opening Move, vil du se deres profil i chatten din. Kvinner har 24 timer til å svare før matchen utløper. Herfra kan du enten svare på deres svar eller starte en ny samtale.",
      "Hvordan kan jeg se om noen har svart på min Opening Move?",
      "Opening Moves er vår måte å fjerne litt av presset ved å ta det første steget, med en enklere og raskere måte å komme til den gode samtalen på.",
      "Opening Moves på Bumble",
      "Vi er glad du spurte. Opening Moves er en ny, enklere måte å starte en samtale på. Du kan velge en foreslått Opening Move, som “Hva er favorittsangen din på fest?”, eller skrive din egen. Hvis du har en klar, kan matchene dine besvare spørsmålene dine når de ser deg i sine matcher.",
      "Hva er en Opening Move?",
      "Fjerne litt av presset",
      "Dele hva som gjør deg til deg",
      "Starte bedre samtaler",
      "Opening Moves kan hjelpe å...",
      "Du kan angi en Opening Move som sendes når du får en match. Gå til Opening Moves i profilen din, velg en av de ferdige spørsmålene, eller vær kreativ og skriv din egen. Du kan endre dine Opening Moves når som helst.",
      "Prøv Opening Moves",
      "Hvordan bruke Bumble Opening Moves",
      "<a href={{see_more_url}}>Se mer</a>",
      "Vil du ta en pause? Prøv Slumre-modus",
      "Ja, du kan besøke dine innstillinger eller køen din og slå av Slumre når som helst.",
      "Kan jeg deaktivere Slumre-modus tidligere?",
      "Gå til innstillinger, trykk på Slumre, og velg hvor lenge du vil være borte. Kom tilbake når du føler deg klar.",
      "Hvordan bruker jeg Slumre-modus på profilen min?",
      "Nei, når du er i Slumre-modus, vil all aktivitet (sveiping og chatting) settes på pause. Når du går for å se nye folk, vil du se en melding som forteller deg hvor lenge Slumre-modusen varer, med muligheten til å deaktivere den.",
      "Kan jeg likevel sveipe og chatte i Slumre-modus?",
      "Folk du allerede har sendt meldinger til vil se din 'Borte'-status (hvis du har en slik status). Ellers vil de se dine tidligere meldinger i deres innboks som vanlig.",
      "Hva vil nåværende matcher se når jeg er i Slumre-modus?",
      "Du har total kontroll over Bumble-aktiviteten din, og kan ta en pause når som helst.",
      "Du kan holde eksisterende forbindelser oppdatert ved å bruke statusoppdateringer, så det ikke ser ut som om du er <a href={{ghosted_article_url}}>borte for dem</a>. Og det er helt automatisk – du trenger ikke å sende noen meldinger.",
      "Ta en pause fra aktiv dating uten å miste forbindelsene eller chattene dine.",
      "Finn dates (eller ta pause) på dine egne vilkår",
      "Hold forbindelsene dine oppdatert",
      "Gi aktiviteten din en pause, men ikke forbindelsene dine",
      "Hvorfor vi elsker Slumre-modus",
      "Åpne Bumble-appen, gå til profilen din, og trykk på tannhjulet (innstillinger) øverst til høyre. I innstillingene dine, klikk på 'Slumre' og velg hvor lenge du vil være borte. Du kan skjule profilen din i 24 timer, 72 timer, en uke eller på ubestemt tid.{{__EOL__}}{{__EOL__}}Husk at matcher uten aktive chatter vil utløpe mens du er i Slumre-modus. Hvis du velger å sette en status, vil personer du chatter med kunne se at du er borte.",
      "Hvordan Slumre-modus fungerer på Bumble",
      "Når livet blir travelt, er Slumre-modus her for å hjelpe. Sett Bumble-kontoen din på pause, og date på dine egne vilkår.",
      "Slumre-modus på Bumble",
      "Slumre-modus er en enkel måte å ta en pause fra dating når du trenger det. Du kan fortsatt chatte med dine eksisterende forbindelser, det skjuler bare profilen din for andre datere frem til du er klar for å sveipe igjen. Du vil ikke miste profilinfoen eller slette eksisterende forbindelser ved å aktivere Slumre-modus.",
      "Slumre Bumble-profilen din",
      "Når du er i Slumre-modus, kan du velge å informere eksisterende kontakter om at du tar en pause ved å oppdatere statusen din. Velg mellom 'Jeg reiser', 'Jeg jobber', 'Jeg har en digital detox' eller 'Jeg fokuserer på meg selv'.",
      "Legg til en Borte-status",
      "Ved å aktivere Slumre-modus, setter du Bumble-kontoen (eller {{page_partner_name_bff}}) på pause. Matchene dine vil ikke vite at du er i Slumre-modus før du forteller dem det. Hvis du velger å avslutte Slumre-modus tidligere enn tenkt, kan du bare gå til innstillinger og trykke på «deaktiver Slumre-modus». Det er så enkelt som det.",
      "Sett chattene på pause",
      "Prøv Superswipe på Bumble",
      "Så mange som du vil! {{service_level_lexeme_name}}-medlemmer får {{num}} [[superswipe:num]] hver uke, men du kan fylle på saldoen din når som helst. Vanlige medlemmer kan kjøpe Superswipes i pakker.",
      "Hvor mange Superswipes kan jeg sende på Bumble?",
      "Når du har fått en Superswipe på profilen din, vil du bli varslet med det samme. Du vil også se en etikett over denne personens navn på profilen deres, som forteller deg at de liker deg.",
      "Hvordan vet jeg om jeg får en Superswipe fra noen?",
      "Har du en ekstra god følelse for noen? Det finnes en kul måte å bryte isen på. Send en Superswipe for å vise at du er interessert, og kom til de gode samtalene raskere!",
      "Superswipe på Bumble",
      "Det er ikke alltid lett å skille seg ut fra mengden, men Superswipes kan hjelpe deg å gjøre et godt førsteinntrykk.{{__EOL__}}{{__EOL__}}Med en Superswipe, så vil et varsel bli sendt til personen du likte. Du vil da komme foran i køen med et merke på profilen din. Hvis de liker deg tilbake, kan dere begynne å chatte.",
      "Hva er en Superswipe?",
      "Være frempå og skille deg ut",
      "Starte flere samtaler",
      "Få flere forbindelser",
      "Med Superswipe kan du…",
      "Hvis du har <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> eller Bumble {{service_level_lexeme_name_one}}, får du {{num}} gratis [[superswipe:num]] hver uke. Alle medlemmer kan også kjøpe pakker med opptil {{num_two}} [[superswipe:num_two]] fra profilen sin. Hvis du har {{service_level_lexeme_name_three}}, får du {{num_three}} [[superswipe:num_three]] hver uke.{{__EOL__}}{{__EOL__}}Det er enkelt å bruke Superswipes – når du ser noen du liker, trykker du bare på den gule stjernen nederst på profilen.",
      "Hvordan bruke Superswipe",
      "Apple og Apple-logoen er varemerker for Apple Inc.{{__EOL__}}Google Play er et varemerke som eies av Google LLC.",
      "Dates på farten med Bumble reisemodus",
      "Medlemmer som har <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} </a> kan bruke reisemodus. Slå det på, og køen din vil vise deg nye ansikter i den valgte byen din.",
      "Hvordan bruker jeg Reisemodus?",
      "Mer eller mindre – vi har noen sikkerhetsbegrensninger som kan endres over tid, men Reisemodus er tilgjengelig for de fleste destinasjoner.",
      "Kan jeg bruke Reisemodus overalt?",
      "Når du er i Reisemodus, er det sentrum av din valgte destinasjon som vises, slik at nye forbindelser i området kan finne deg. De vil se en notis på profilen din at du er i Reisemodus, så de vet at du er på besøk, eller planlegger å besøke det aktuelle stedet.",
      "Skal du på ferie eller har du en jobbtur rundt hjørnet? Slå på Bumble Reisemodus for å ta datingen med deg på reisen.",
      "Reisemodus på Bumble",
      "Reisemodus er ditt verktøy for å etablere forbindelser mens du er reise. Angi lokasjonen til hvor som helst i verden, og profilen din vil vises som at du er i byen du velger. Det betyr at du kan bli kjent med nye folk uansett hvor eventyrene dine tar deg.",
      "Hva er Reisemodus?",
      "Ingen kjenner et sted bedre enn de lokale. Reisemodus kan hjelpe deg å bli kjent med folk som vil gjøre turen din ekstra spesiell.",
      "Angi posisjonen din til den aktuelle byen opptil syv dager før avreise, så du kan planlegge dates og bli kjent med matcher på forhånd.",
      "Tenk større enn bare strandferier – kanskje du er i hjembyen din på juleferie, på jobbtur eller utforsker en ny by. Finn gnisten uansett hvor du lander.",
      "Finn de beste lokale date-stedene",
      "Få en opplevelse",
      "Skap varige forbindelser",
      "Hvorfor du vil digge Reisemodus",
      "Reisemodus er tilgjengelig for medlemmer som har <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} og {{service_level_lexeme_name_two}} </a> på Bumble. Trykk på tannhjulet øverst til høyre i profilen din for innstillinger, og bla ned til Reisemodus. Velg ny lokasjon for å begynne å se folk i det området. Profilen din vil fortelle potensielle dates at du er i reisemodus.",
      "Hvordan fungerer Reisemodus?",
      "Prøv det nå",
      "Prøv Bumble på nett",
      "Absolutt. Bumble sin nettapp fungerer på PC, Mac og nettbrett, på alle store nettlesere.",
      "Kan jeg bruke Bumble på min PC eller Mac?",
      "Ja, det kan du. Du kan sende meldinger eller til og med ha en video- eller lydsamtale i Bumble på nettappen.",
      "Kan jeg chatte med matcher på nettappen?",
      "For å logge inn, klikk på 'Logg inn', eller klikk <a href={{sign_in_url}}>her</a>. Du kan logge inn med Apple ID, Facebook eller telefonnummer.",
      "Hvordan logger jeg inn på Bumble på nettet?",
      "Trenger du en pause fra skjermen? Prøv Bumble på nett – den samme Bumble-opplevelsen du elsker, bare på en større skjerm.",
      "Bruk Bumble på nett",
      "Det er enkelt å logge inn på Bumble. Besøk nettsiden, klikk på 'Logg inn', og velg din vanlige innloggingsmetode med Apple ID, Facebook eller telefonnummer. Voila! Du er inne, og dine chatter og forbindelser er der de var sist du var logget inn.",
      "Logg inn",
      "Logg inn via nettleseren din",
      "Rediger eller gjør endringer på profilen din, og bruk avanserte filtre.",
      "Bekreft at det er deg for å øke sannsynligheten for bedre matcher.",
      "Del søte nye bilder eller slett eldre.",
      "Oppdater profilen din",
      "La folk få vite at du er deg",
      "Legg til eller fjern bilder",
      "Hva du kan gjøre på Bumble på nett",
      "Du vil finne chattene dine og listen over forbindelser til venstre, og potensielle nye matcher til høyre.{{__EOL__}}{{__EOL__}}Bruk venstre- og høyrepilene, eller klikk på symbolene for å sjekke ut profiler, og lik folk som du ville gjort på mobiltelefonen din.",
      "Hvordan bruker jeg Bumble på nett?",
      "Hos oss mener vi at vennlighet er sexy, og her kan du være deg selv. Og kvinnene sitter i førersetet.",
      "Ta det første steget",
      "Vi tror at sunne forhold er noe av det viktigste i livet. Bumble er designet for å gi alle en plattform med fokus på gjensidig respekt og likestilling – i dating, nye vennskap, potensielle forhold eller i nye karriererelasjoner.",
      "Hvorfor Bumble er viktig",
      "Vi oppmuntrer til integritet, likestilling og respekt fra en match oppstår. Alle er velkomne her – uansett legning, og uansett hva du ser etter.",
      "Hva Bumble står for",
      "På Bumble er det kvinnene som setter an tonen ved å ta det første steget, eller sende en Opening Move til matcher. Vi har endret dynamikken og oppmuntrer til likestilling helt fra starten.",
      "Hvordan Bumble fungerer",
      "Om oss",
      "Last ned nå",
      "Det er bedre på appen.",
      "Tjenester designet for å gjøre nettdating – faktisk – fornøyelig",
      "12. februar 2024",
      "Hvordan forbedre og øke emosjonell intimitet i forhold",
      "25. januar 2024",
      "Hvordan bruke Bumble når du aldri har brukt en dating-app før",
      "5. februar 2024",
      "Deception Detector™",
      "Alle dating-nyhetene du trenger",
      "Sjekk ut <b>Til Deg</b> for å få utvalgte profiler basert på dine preferanser og tidligere matcher.",
      "Utvalgt til deg",
      "Ta en pause med <b>Slumre-modus</b>. Du vet hvor vi er når du er klar for dating igjen.",
      "Ta en pause",
      "Bruk <b>videosamtale</b> og <b>lydsamtale</b> for å bli kjent før dere treffes på ekte.",
      "Sett på lyden",
      "Se mer",
      "Prøv <b>Opening Moves</b> for å få chatten i gang på en kul måte.",
      "Mindre mas, mer dating",
      "Finn matcher hvor som helst i verden med <b>Reisemodus</b>.",
      "Bli med på tur",
      "Imponert av profilen deres? La dem få vite det med en <b>SuperSwipe</b>.",
      "Fang deres oppmerksomhet",
      "Send <b>Compliments</b> for å skille deg ut i mengden.",
      "Gi dem et smil",
      "Bruk <b>Usynlighet</b> hvis du vil skjule profilen din for noen – for eksempel eksen eller sjefen.",
      "Gå undercover",
      "Dating på din måte",
      "Noen måneder senere, møtte hun en av brudepikene der, også",
      "Hannah møtte sin fremtidige mann på Bumble.",
      "Før de bygde mikrohuset sitt sammen, møttes Carène og You-Liang på Bumble – hvor You-Liang ble fascinert av reisebildene til Carène.",
      "Carène og You-Liang",
      "Brian og Kaci var stasjonert i utlandet sammen – men de møttes på Bumble",
      "Brian og Kaci",
      "Det funket for dem, det kan funke for deg også",
      "Ta det neste steget",
      "Se hva som er nytt",
      "Du fortjener det beste. Derfor har vi designet fantastiske måter du kan date mer, og stresse mindre på.",
      "Sett listen høyt igjen",
      "Om Bumble",
      "Vi er den eneste appen som setter kvinnens opplevelse først. For vi tror på at når ting er bedre for kvinner, er det bedre for alle.",
      "Make the first move™",
      "Finn gnisten med noen! Ta det første steget, finn en genuin forbindelse, bli kjent over felles interesser, men viktigst av alt – ha det gøy.",
      "Treff noen med Bumble Date",
      "Finn venner eller mentorer. Bli kjent med andre i bransjen for å finne din neste jobb, noen å sparre med eller en helt ny karriere.",
      "Bygg nettverk med Bumble Bizz",
      "Treff noen som deg. Eller ikke som deg. Finn nye vennskap og ting å finne på sammen – uansett om du er hjemme eller på tur.",
      "Finn nye venner på Bumble For Friends",
      "Ikke kun for dating",
      "Bumble er dating-appen hvor damene tar det første steget. Det er appen som har endret måten folk dater på, danner meningsfulle vennskap og bygger nettverk. Last ned nå.",
      "Bumble på {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Vi beklager, vi kunne ikke finne siden du leter etter.",
      "Kontakt oss",
      "Logg inn",
      "Oops, det oppsto en feil",
      "(opens in new window)",
      "Ofte stilte spørsmål",
      "Cookie Policy",
      "Premium",
      "Bumble - The Blog",
      "Jobbmuligheter",
      "Chat",
      "Byer",
      "Administrer cookies",
      "Copyright © 2006 – nå. Bumble. Alle rettigheter forbeholdes.",
      "Datingrapport",
      "Apple og Apple-logoen er varemerker for Apple Inc.{{__EOL__}}Google Play er et varemerke som eies av Google LLC.",
      "Last ned",
      "Facebook",
      "Følg oss",
      "Hjelpesenter",
      "Instagram",
      "Språk",
      "LinkedIn",
      "Bumble er en del av Bumble Inc.",
      "Personvernerklæring",
      "Safety Centre",
      "Sidekart",
      "Modern Slavery Act statement",
      "Vilkår og betingelser",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "komplimenter"
            ],
            "singular": [
                  "kompliment"
            ]
      },
      "day": {
            "plural": [
                  "dager"
            ],
            "singular": [
                  "dag"
            ]
      },
      "hour": {
            "plural": [
                  "timer"
            ],
            "singular": [
                  "time"
            ]
      },
      "minute": {
            "plural": [
                  "minutter"
            ],
            "singular": [
                  "minutt"
            ]
      },
      "month": {
            "plural": [
                  "måneder"
            ],
            "singular": [
                  "måned"
            ]
      },
      "second": {
            "plural": [
                  "sekunder"
            ],
            "singular": [
                  "sekund"
            ]
      },
      "superswipe": {
            "plural": [
                  "Superswipes"
            ],
            "singular": [
                  "Superswipe"
            ]
      },
      "year": {
            "plural": [
                  "år"
            ],
            "singular": [
                  "år"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
