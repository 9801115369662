import { AppLink } from 'lib/app/app-link';
import Localization from 'lib/localization';

export default {
    get lxTravelModeHeroTitle() {
        return Localization.get('bumblesite@pages.features.travel-mode.hero.title');
    },
    get lxTravelModeHeroSubtitle() {
        return Localization.get('bumblesite@pages.features.travel-mode.hero.subtitle');
    },
    get lxTravelModeOneBlockTitle() {
        return Localization.get('bumblesite@pages.features.travel-mode.one.block.title');
    },
    get lxTravelModeOneBlockBodyText() {
        return Localization.get('bumblesite@pages.features.travel-mode.one.block.body.text');
    },
    get lxFeaturesTryItNowCta() {
        return Localization.get('bumblesite@pages.features.try.it.now.cta');
    },
    get lxTravelModeTwoBlockTitle() {
        return Localization.get('bumblesite@pages.features.travel-mode.two.block.title');
    },
    get lxTravelModeTwoBlockBodyText() {
        return Localization.get('bumblesite@pages.features.travel-mode.two.block.body.text', {
            service_level_lexeme_name: Localization.get('revenue_onboarding@gold.revamp.premium'),
            service_level_lexeme_name_two: Localization.get('bumble_boost@gold.name'),
            bumble_premium_url: AppLink.BUMBLE_PREMIUM,
        });
    },
    get lxFeaturesDownloadBumbleCta() {
        return Localization.get('bumblesite@pages.features.download.bumble.cta');
    },
    get lxTravelModeThreeBlockTitle() {
        return Localization.get('bumblesite@pages.features.travel-mode.three.block.title');
    },
    get lxTravelModeThreeBlockSubtitleOne() {
        return Localization.get('bumblesite@pages.features.travel-mode.three.block.subtitle.one');
    },
    get lxTravelModeThreeBlockBodyTextOne() {
        return Localization.get('bumblesite@pages.features.travel-mode.three.block.body.text.one');
    },
    get lxTravelModeThreeBlockSubtitleTwo() {
        return Localization.get('bumblesite@pages.features.travel-mode.three.block.subtitle.two');
    },
    get lxTravelModeThreeBlockBodyTextTwo() {
        return Localization.get('bumblesite@pages.features.travel-mode.three.block.body.text.two');
    },
    get lxTravelModeThreeBlockSubtitleThree() {
        return Localization.get('bumblesite@pages.features.travel-mode.three.block.subtitle.three');
    },
    get lxTravelModeThreeBlockBodyTextThree() {
        return Localization.get(
            'bumblesite@pages.features.travel-mode.three.block.body.text.three',
        );
    },
    get lxFeaturesFaqTitle() {
        return Localization.get('bumblesite@pages.features.faq.title');
    },
    get lxTravelModeFaqQuestionOneTitle() {
        return Localization.get('bumblesite@pages.features.travel-mode.faq.question.one.title');
    },
    get lxTravelModeFaqQuestionOneAnswer() {
        return Localization.get('bumblesite@pages.features.travel-mode.faq.question.one.answer', {
            service_level_lexeme_name: Localization.get('revenue_onboarding@gold.revamp.premium'),
            bumble_premium_url: AppLink.BUMBLE_PREMIUM,
        });
    },
    // Same as two block title
    get lxTravelModeFaqQuestionTwoTitle() {
        return Localization.get('bumblesite@pages.features.travel-mode.two.block.title');
    },
    get lxTravelModeFaqQuestionTwoAnswer() {
        return Localization.get('bumblesite@pages.features.travel-mode.faq.question.two.answer');
    },
    get lxTravelModeFaqQuestionThreeTitle() {
        return Localization.get('bumblesite@pages.features.travel-mode.faq.question.three.title');
    },
    get lxTravelModeFaqQuestionThreeAnswer() {
        return Localization.get('bumblesite@pages.features.travel-mode.faq.question.three.answer');
    },
    get lxTravelModeCta() {
        return Localization.get('bumblesite@pages.features.travel-mode.cta');
    },
};
