import { AppLink } from 'lib/app/app-link';
import Localization, { TrustedValue } from 'lib/localization';

export default {
    get lxSnoozeModeHeroTitle() {
        return Localization.get('bumblesite@pages.features.snooze-mode.hero.title');
    },
    get lxSnoozeModeHeroSubtitle() {
        return Localization.get('bumblesite@pages.features.snooze-mode.hero.subtitle');
    },
    get lxSnoozeModeOneBlockTitle() {
        return Localization.get('bumblesite@pages.features.snooze-mode.one.block.title');
    },
    get lxSnoozeModeOneBlockBodyText() {
        return Localization.get('bumblesite@pages.features.snooze-mode.one.block.body.text');
    },
    get lxFeaturesDownloadBumbleCta() {
        return Localization.get('bumblesite@pages.features.download.bumble.cta');
    },
    get lxSnoozeModeTwoBlockTitle() {
        return Localization.get('bumblesite@pages.features.snooze-mode.two.block.title');
    },
    get lxSnoozeModeTwoBlockBodyText() {
        return Localization.get('bumblesite@pages.features.snooze-mode.two.block.body.text', {
            page_partner_name_bff: 'Bumble For Friends',
        });
    },
    get lxSnoozeModeThreeBlockHeader() {
        return Localization.get('bumblesite@pages.features.snooze-mode.three.block.header');
    },
    get lxSnoozeModeThreeBlockBodyText() {
        return Localization.get('bumblesite@pages.features.snooze-mode.three.block.body.text');
    },
    get lxSnoozeModeFourBlockTitle() {
        return Localization.get('bumblesite@pages.features.snooze-mode.four.block.title');
    },
    get lxSnoozeModeFourBlockBodyText() {
        return Localization.get('bumblesite@pages.features.snooze-mode.four.block.body.text', {
            __EOL__: TrustedValue.for('<br/>'),
        });
    },
    get lxSnoozeModeFiveBlockTitle() {
        return Localization.get('bumblesite@pages.features.snooze-mode.five.block.title');
    },
    get lxSnoozeModeFiveBlockSubtitleOne() {
        return Localization.get('bumblesite@pages.features.snooze-mode.five.block.subtitle.one');
    },
    get lxSnoozeModeFiveBlockBodyTextOne() {
        return Localization.get('bumblesite@pages.features.snooze-mode.five.block.body.text.one');
    },
    get lxSnoozeModeFiveBlockSubtitleTwo() {
        return Localization.get('bumblesite@pages.features.snooze-mode.five.block.subtitle.two');
    },
    get lxSnoozeModeFiveBlockBodyTextTwo() {
        return Localization.get('bumblesite@pages.features.snooze-mode.five.block.body.text.two');
    },
    get lxSnoozeModeFiveBlockSubtitleThree() {
        return Localization.get('bumblesite@pages.features.snooze-mode.five.block.subtitle.three');
    },
    get lxSnoozeModeFiveBlockBodyTextThree() {
        return Localization.get(
            'bumblesite@pages.features.snooze-mode.five.block.body.text.three',
            { ghosted_article_url: AppLink.BUMBLE_WHAT_IS_GHOSTING },
        );
    },
    get lxFeaturesFaqTitle() {
        return Localization.get('bumblesite@pages.features.faq.title');
    },
    get lxSnoozeModeFaqQuestionOneTitle() {
        return Localization.get('bumblesite@pages.features.snooze-mode.faq.question.one.title');
    },
    get lxSnoozeModeFaqQuestionOneAnswer() {
        return Localization.get('bumblesite@pages.features.snooze-mode.faq.question.one.answer');
    },
    get lxSnoozeModeFaqQuestionTwoTitle() {
        return Localization.get('bumblesite@pages.features.snooze-mode.faq.question.two.title');
    },
    get lxSnoozeModeFaqQuestionTwoAnswer() {
        return Localization.get('bumblesite@pages.features.snooze-mode.faq.question.two.answer');
    },
    get lxSnoozeModeFaqQuestionThreeTitle() {
        return Localization.get('bumblesite@pages.features.snooze-mode.faq.question.three.title');
    },
    get lxSnoozeModeFaqQuestionThreeAnswer() {
        return Localization.get('bumblesite@pages.features.snooze-mode.faq.question.three.answer');
    },
    get lxSnoozeModeFaqQuestionFourTitle() {
        return Localization.get('bumblesite@pages.features.snooze-mode.faq.question.four.title');
    },
    get lxSnoozeModeFaqQuestionFourAnswer() {
        return Localization.get('bumblesite@pages.features.snooze-mode.faq.question.four.answer');
    },
    get lxSnoozeModeCta() {
        return Localization.get('bumblesite@pages.features.snooze-mode.cta');
    },
};
