/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Atrás",
      "Descargar en la App Store",
      "Descargar en Google Play",
      "Carrusel",
      "Diapositiva {{number}}",
      "Próxima diapositiva",
      "Diapositiva anterior",
      "Diapositiva",
      "Diapositiva {{number}} de {{total}}",
      "No vender o compartir mi información personal",
      "No vender o compartir mi información personal",
      "Enviá Compliments en Bumble para que sepan que te gusta su perfil y llamá su atención antes de conectar. ¡Probá ahora!",
      "Compliments | Cómo enviar un Compliment | Bumble",
      "Una página personalizada que destaca a las personas más compatibles contigo, actualizada cada día.",
      "Descubre | Encuentra personas compatibles | Bumble",
      "Salí con alguien a tu propio ritmo y controlá quién ve tu perfil de Bumble con el Modo Incógnito. Para saber cómo usar el Modo Incógnito en Bumble, consultá nuestra guía.",
      "Modo Incógnito | Ocultá tu perfil | Bumble",
      "Después de conocerse en un evento IRL de Bumble, mantén la química conversando en la app.",
      "Conexión inmediata | Sigue la conversación en la App | Bumble",
      "Probá una nueva forma de iniciar la conversación con la función Opening Moves de Bumble. Con esta función, llamá su atención, marcá el ritmo y terminá en una buena charla más rápido.",
      "Opening Moves de Bumble | Bumble",
      "¿Necesitás un descanso o un tiempo para vos? Descubrí cómo poner en pausa tu cuenta de Bumble con el Modo No Molestar de Bumble. Conocé gente a tu manera.",
      "Modo No Molestar | Poné en pausa tu cuenta | Bumble",
      "¿Acabás de ver a alguien que te encanta en Bumble? Usá SuperSwipe para mostrarle tu interés. Tocá la estrella en su perfil para empezar, o mirá más detalles acá.",
      "SuperSwipe de Bumble | ¿Qué es un SuperSwipe? | Bumble",
      "¿De viaje por trabajo o diversión? Con el Modo Travel en Bumble podés hacer nuevas conexiones divertidas en un lugar nuevo y salir con gente donde quieras. Descubrí el Modo Travel acá.",
      "Modo Travel de Bumble | Salí donde quieras | Bumble",
      "¿Querés saber si la conexión que tienen por mensaje es real? Probá hacer una videollamada o una llamada de voz en Bumble ahora y sacate la duda.",
      "Videollamada | Hacé un llamada | Bumble",
      "Sentí el entusiasmo de Bumble Web, la función más novedosa.",
      "Bumble Web | Bumble",
      "Terminar contratos acá",
      "Página principal de Bumble",
      "Cerrá el menú",
      "Abrí el menú",
      "Selección de idioma",
      "Pie de página",
      "Accesibilidad en Bumble",
      "Apple y el logotipo de Apple son marcas comerciales de Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Empleo",
      "Eventos",
      "Google Play es una marca de Google LLC.",
      "Reglas",
      "Inversores",
      "Gestionar cookies",
      "Gestionar cookies",
      "Declaración sobre la Modern Slavery Act",
      "Aviso de recopilación",
      "Política de privacidad",
      "Consejos sobre citas por temporada",
      "Condiciones de Uso",
      "La aplicación",
      "Política de privacidad de datos de salud del consumidor de Washington",
      "Acerca de",
      "Embajadores",
      "Blog",
      "Hacé nuevas amistades y encontrá tu comunidad",
      "Generá una red de contactos y avanzá en tu carrera profesional",
      "Encontrá una relación, un vínculo casual o algo intermedio",
      "¡Enterate!",
      "Globalizate con el Modo Travel",
      "Funciones",
      "Compliments",
      "Sorprendé con tus Compliments y marcá la diferencia",
      "Descubrí",
      "Una página personalizada que destaca a las personas más compatibles contigo, actualizada cada día.",
      "Modo Incógnito",
      "Usá el Modo Incógnito para ocultar tu perfil de tu jefe, familiares o exparejas.",
      "Conexión inmediata",
      "¿Iniciaste la conversación en un evento IRL de Bumble? Sigan hablando en la app.",
      "Opening Moves",
      "Usá la opción Opening Moves para que tu conexión te empiece a escribir antes que vos.",
      "Modo No Molestar",
      "Usa el Modo no molestar para tomarte un tiempo. Cuando quieras volver a conocer gente nueva, te esperamos.",
      "SuperSwipe",
      "Si te gustó mucho un perfil, dejá que se sepa gracias a SuperSwipe.",
      "Travel",
      "Encontrá lo que te hace feliz en cualquier lugar del mundo y conectá con gente de todas partes con el Modo travel.",
      "Videollamada",
      "Usá la videollamada y las llamadas de audio para ver si estás listo para que se conozcan en persona.",
      "Aplicación web",
      "Obtené la experiencia de Bumble directamente desde tu navegador.",
      "Nuestra Tienda",
      "Navegación principal",
      "Ir al contenido principal",
      "Logotipo de Bumble",
      "¿Te da curiosidad? Probá hacer una videollamada en Bumble para conocer a tu próxima cita.",
      "Simplemente, toca el ícono de video en tu chat para iniciar una videollamada, sin compartir ninguna información de contacto personal.",
      "¿Cómo funciona la videollamada en Bumble?",
      "No. Todas las llamadas y chats en Bumble mantienen tu información personal segura y protegida. No obtendrá tu número ni ningún otro detalle de contacto hasta que vos se lo digas.",
      "¿Mi cita va a ver mi número de teléfono?",
      "¡Absolutamente! Si todavía no estás listo para verte en persona, las llamadas de voz te permiten conectarte a través del audio. Solo tenés que seleccionar el icono de llamada.",
      "¿Puedo probar solamente una videollamada?",
      "Nos encanta recibir notificaciones nuevas. Pero sabemos que es difícil saber si tus conversaciones van a ser tan buenas como en persona. Para eso, tenemos las videollamadas y las llamadas de audio.",
      "Videollamada en Bumble",
      "<a href={{video_chat_url}}>Las videollamadas y las llamadas de audio</a> pueden ayudarte a conocerse y a decidir si querés avanzar fuera de la aplicación. Lo mejor de todo es que cuando hacés una llamada a través de Bumble, no tenés que intercambiar números de teléfono, por lo que tu información personal permanece segura hasta que estés listo para compartirla.",
      "Comprobá si hay química con una videollamada",
      "Conocé mejor a tu cita por video antes de decidir si es hora de dar el salto y reunirte en persona.",
      "Conocé mejor a tu cita (y más rápido). Una conversación cara a cara o una charla por teléfono te permite saber mucho más del otro.",
      "Vos tenés el control. Mantenemos tus datos de contacto en privado para que puedas elegir cuándo compartirlos.",
      "Descubrí si llegó la hora de conocer a tu cita en persona",
      "Detectá en el momento si hay onda",
      "Conectate sin brindar información personal",
      "¿Por qué deberías usar llamadas y videollamadas?",
      "¿Sentís que es hora de iniciar una conversación? Podés elegir comenzar una videollamada o una llamada de audio directamente desde el chat de Bumble. Para iniciar una videollamada, tocá el icono de la cámara de video gris en la esquina superior derecha. También vas a ver tres puntos junto al icono, en el que tenés que hacer clic y vas a ver también la opción de videollamada.",
      "Cómo hacer videollamadas",
      "¿Todo listo para probar Compliments?",
      "Cuando enviás un Compliment, la persona que lo recibe lo verá cuando aparezcas en su lista.",
      "¿Qué pasa cuando le enviás un Compliment a alguien en Bumble?",
      "Si te llamó la atención el Compliment de alguien, animate y conectá. Vas a ver el Compliment en tu chat, y se aplican las reglas habituales de <a href={{make_the_first_move_url}}>Dar el primer paso</a>.",
      "¿Cómo respondo a un Compliment?",
      "Podés enviar {{num}} [[Compliments:num]] de forma gratuita. Cuando no te queden más, podés comprar en la aplicación Bumble en paquetes de hasta 30. Uno de esos se utilizará para enviar ese Compliment (guardá el resto para cuando veas otro perfil que te guste). No te preocupes, no se vencen.",
      "¿Cuántos Compliments puedo enviar?",
      "Unas lindas palabras pueden lograr muchas cosas. Si querés enviar un Compliment, pero no te salen las palabras indicadas, probá estas frases para inspirarte:",
      "Ver más ejemplos",
      "“A cualquiera que le guste [nombre de artista], tiene buen gusto”.",
      "“¡Tu sonrisa es contagiosa!”",
      "“Tu bio me causó mucha gracia. Muy original”.",
      "Cómo enviar mejores cumplidos en Bumble",
      "¿Querés alegrarle el día a alguien? Entonces es hora de usar Compliments.",
      "Compliments en Bumble",
      "Usá Compliments en Bumble para que una posible conexión sepa que te gusta su perfil (e incluso hacerla sonrojar). Recibís Compliments como parte de tu membresía de {{service_level_lexeme_name}}. También es una forma de enviar y recibir mensajes antes de haber conectado.",
      "Cómo funciona la función de Compliments",
      "Cuando recibas un Compliment, va a aparecer en el perfil del remitente. También vas a recibir una notificación que te informará que a alguien le gusta parte de tu perfil.",
      "Descubrí tus Compliments",
      "Para enviar un Compliment, tocá el icono del corazón amarillo en la parte inferior del perfil de alguien. Es importante tener en cuenta dónde tocás, ya que vas a darle un cumplido a una foto específica o parte de su perfil. Cuando hagas clic, se va a abrir un cuadro de texto para que escribas tu mensaje (asegurate de que el contenido sea de hasta 150 caracteres).",
      "Enviá un Compliment",
      "DESCARGÁ Bumble Y PROBÁ LA FUNCIÓN DESCUBRÍ",
      "Todos los días, desde las 9:00 de la mañana, vas a ver gente nueva para vos en Descubrí. Podés conectarte con ellos por lo que tengan en común.",
      "¿Cuándo se actualiza la página Descubrí?",
      "Tu página Descubrí ordena posibles conexiones en 3 categorías principales: intereses similares, mismos objetivos en una cita y comunidades en común. Las recomendaciones de perfil también se muestran, según las conexiones y contactos anteriores.",
      "¿Cómo funciona la selección de mi página Descubrí?",
      "Una página personalizada que destaca a las personas más compatibles contigo, actualizada cada día",
      "ENCUENTRA PERSONAS CON TU MISMA VIBRA CON {{qr_code}} DESCUBRE",
      "Descubre te muestra una selección de personas que creemos que te gustarán, basándonos sus intereses, intenciones y comunidades afines.",
      "Probar Descubre",
      "¿BUSCAS UNA NUEVA FORMA DE SABER LO QUE TIENEN EN COMÚN?",
      "La página DescubrÍ se actualiza todos los días, para mostrarte aún más personas con las que serías compatible para que puedas pasar menos tiempo buscando una conexión, y más tiempo conociendo a la persona con la que tendrás química.",
      "RECOMENDACIONES ACTUALIZADAS TODOS LOS DÍAS",
      "Las recomendaciones de Descubre se basan en tu perfil y en las personas con las que has conectado previamente, de modo que verás a personas que buscan lo mismo y que tienen gustos en común, ya sean rollitos veganos o una relación estable.",
      "PERSONALIZADA{{__EOL__}}A TU MEDIDA",
      "Descargar Bumble",
      "Preguntas frecuentes",
      "¿Todo listo para navegar por Bumble en privacidad?",
      "Cuando activás el Modo Incógnito, no aparecés para otros miembros de Bumble, a menos que le des me gusta a su perfil.",
      "¿Cómo funciona el Modo Incógnito?",
      "Sin el Modo Incógnito, tu perfil es visible para otras personas en Bumble cuando tenés sus mismas preferencias, como la edad y la ubicación. Cuando activás el Modo Incógnito, solo te van a ver las personas una vez que les hayas dado me gusta.",
      "¿Quién puede verme en el Modo Incógnito de Bumble?",
      "Usar el Modo Incógnito no es lo mismo que <a href={{blocking_on_bumble_url}}>bloquear a alguien en Bumble</a>. Con esta función, si te gusta alguien (o si le enviaste un mensaje en el pasado), va a poder ver tu perfil.",
      "¿El Modo Incógnito es como bloquear a alguien?",
      "Controlá quién mira tu perfil con el Modo Incógnito de Bumble.",
      "Modo Incógnito en Bumble",
      "Si querés salir del mundo de las citas o navegar por Bumble sin que otras personas vean tu perfil, podés activar el Modo Incógnito. Con esta función, ocultaremos tu perfil a todos, a menos que te guste primero un perfil. Esto te permite controlar lo que compartís y quién te ve.",
      "Ocultá tu perfil con el Modo Incógnito",
      "Vos decidís quién puede ver tus fotos y tu información personal.",
      "¿Querés incluir algo personal en tu perfil? Podés compartir discretamente lo que quieras con tus conexiones actuales.",
      "Navegá libremente sin preocuparte por encontrarte con un ex, un compañero de trabajo o cualquier otra persona que no esté en tu radar de citas.",
      "Tomá todo el control",
      "Elegí qué compartir",
      "Evitá encuentros incómodos",
      "¿Por qué el Modo Incógnito es para vos?",
      "El Modo Incógnito está disponible para los miembros de {{service_level_lexeme_name}} y {{service_level_lexeme_name_two}}. Después de que te hayas registrado, podés activarlo desde la pestaña de tu perfil, para eso, tocá Configuración en la esquina superior derecha y cambiá a Modo Incógnito.",
      "Pasate a {{service_level_lexeme_name}}",
      "Cómo usar el Modo Incógnito en Bumble",
      "DESCARGÁ Bumble Y PROBÁ LA FUNCIÓN CONECTAR EN EL ACTO",
      "En la app Bumble, andá al icono del Perfil en la parte inferior de la pantalla. Ahí vas a encontrar el icono del código QR, en la parte superior derecha de la pantalla, que vas a tocar para acceder.",
      "¿Dónde puedo encontrar mi código QR personal?",
      "¡No hace falta! Tenés que conectarte a wifi y usar el código QR en vivo actual de Conectar en el acto para que funcione.",
      "¿Puedo hacer una captura de pantalla de mi código QR para compartir?",
      "No es necesario. Si una persona escanea el código, vas a tener una conexión automáticamente.",
      "¿Ambas personas necesitan escanear el código QR?",
      "Con Conectar en el acto, podés ver automáticamente lo que vos y tu nueva conexión tienen en común, lo que te da una idea de su compatibilidad desde el principio.",
      "ENCONTRAR LO QUE NOS UNE",
      "¿Empezaste la conversación en persona en un evento de Bumble? Seguí disfrutando de la app.",
      "TRANSFORMÁ UN ENCUENTRO ÚNICO EN UNA CONEXIÓN EN EL ACTO {{qr_code}}",
      "Conectar en el acto simplifica la transición de un encuentro único a un \"sigamos hablando\" sin tener que intercambiar números ni perfiles sociales. Simplemente escanéa tu código QR para conectar con alguien en Bumble.",
      "Probá Conectar en el acto",
      "¿CONOCISTE A ALGUIEN EN UN EVENTO DE Bumble?",
      "En lugar de intercambiar perfiles sociales o números de teléfono, Conectar en el acto te permite saber si primero hay química. De esta manera, pueden conocerse mejor a su propio ritmo, gracias a las funciones de seguridad de Bumble.",
      "UNA FORMA MÁS SEGURA DE CONECTAR",
      "Después de conocerse en persona en un evento de Bumble, descubran si hay química con una charla en la app y conéctense según lo que tengan en común, ya sea el amor por el café perfecto o el cuidado de las suculentas.",
      "CONÓZCANSE MÁS",
      "Probá Opening Moves en Bumble",
      "Los Opening Moves son otra forma de chatear con personas que te gustan. Una vez que hayas elegido un mensaje para enviar, podés relajarte, esperar las respuestas y responder a las que realmente se destacan.",
      "Cómo usar los Opening Moves de Bumble",
      "Para conexiones no binarias y del mismo género, cualquiera de las personas puede configurar y responder a un Opening Move.",
      "¿Puedo usar Opening Moves de Bumble para salir con personas del mismo género?",
      "Cuando alguien responde a tu Opening Move, vas a ver que su perfil aparece en tus chats. A las mujeres se les da 24 horas para responder antes de que expire la conexión. Desde ahí, podés responderle o iniciar una nueva conversación.",
      "¿Cómo puedo ver si alguien ha respondido a mis Opening Moves?",
      "Los Opening Moves son nuestra manera de quitar la presión al momento de dar el primer paso, con una forma más fácil y rápida de lograr una buena conversación.",
      "Opening Moves en Bumble",
      "Qué bien que preguntaste. Los Opening Moves son una nueva forma más fácil de iniciar una conversación. Tenés dos opciones: escribir tu propio Opening Move o elegir uno sugerido como, \"¿Cuál es la canción ideal para pasar en una fiesta?\". Si ya tenés uno configurado, tus conexiones pueden responderte cuando aparezcas en su pantalla de conexiones.",
      "¿Qué es un Opening Move?",
      "Charlar sin presiones",
      "Compartir tu esencia única",
      "Empezar mejores conversaciones",
      "Con los Opening Moves vas a poder...",
      "Podés configurar un Opening Move para enviarlo cuando conectes con otras personas. Solo tenés que ir a Opening Moves en tu perfil, elegir una de las preguntas ya escritas o usar tu imaginación para escribir la tuya. Podés cambiar tus Opening Moves en cualquier momento.",
      "Probar Opening Moves",
      "Cómo usar los Opening Moves de Bumble",
      "<a href={{see_more_url}}>Más info</a>",
      "¿Querés tomarte un descanso? Probá el Modo No Molestar.",
      "Sí, podés ir a tu configuración o tu fila y desactivar el Modo No Molestar en cualquier momento.",
      "¿Puedo desactivar el Modo No Molestar anticipadamente?",
      "Tenés que ir a tu configuración, tocar No Molestar y elegir por cuánto tiempo querés estar ausente. Podés volver cuando creas que es el momento.",
      "¿Cómo uso el Modo No Molestar en mi perfil?",
      "No. Mientras estés en el Modo No Molestar, toda la actividad de deslizar y chatear está pausada. Si vas para ver nuevas personas, vas a ver un mensaje que te dice cuánto tiempo durará el Modo No Molestar, con la opción de desactivarlo.",
      "¿Puedo seguir deslizando y chateando en el Modo No Molestar?",
      "Las personas a las que ya les hayas enviado un mensaje van a ver tu estado 'Ausente' (si decidís configurarlo). De lo contrario, van a ver tus mensajes anteriores en su Bandeja de entrada como de costumbre.",
      "¿Qué verán las conexiones actuales cuando estés en el Modo No Molestar?",
      "Tenés todo el control sobre tu actividad en Bumble, y podés alejarte de la plataforma en cualquier momento.",
      "Podés mantener al tanto a tus conexiones existentes con la actualización de tu estado, para que no parezca que <a href={{ghosted_article_url}}>ghosteaste</a>. Además, como todo es automático, no necesitás enviar ningún mensaje.",
      "Tomate un descanso de la búsqueda activa de una pareja sin perder tus conexiones ni chats.",
      "Salí con alguien (o desconectate) según tus reglas",
      "Mantené al tanto a tus conexiones",
      "Poné en pausa tu actividad, pero no tus conexiones",
      "¿Por qué amamos el Modo No molestar?",
      "Abrí la app de Bumble, andá a la pestaña de tu perfil y tocá el engranaje en la esquina superior derecha. En Configuración, tocá el Modo No Molestar y elegí cuánto tiempo querés ausentarte. Podés ocultar tu perfil durante 24 horas, 72 horas, una semana o indefinidamente.{{__EOL__}}{{__EOL__}}Recordá que las conexiones sin conversaciones activas expirarán mientras estés en Modo No Molestar. Si decidís poner un estado, las personas con las que estás actualmente conversando podrán ver que estás ausente.",
      "¿Cómo funciona el Modo No Molestar en Bumble?",
      "Cuando tenés una vida agitada, el Modo No Molestar llega para ayudarte. Poné en pausa tu cuenta de Bumble, y salí con gente cuando vos quieras.",
      "Modo No Molestar en Bumble",
      "El Modo No Molestar es una forma fácil de poner en pausa tu cuenta cuando querés tomar un respiro de las citas. De todos modos, podés seguir chateando con tus conexiones existentes, solo que oculta tu perfil a otras personas hasta que tengas todo listo para volver al ruedo. Cuando activás el Modo No Molestar, no perdés tu información de perfil ni eliminás las conexiones existentes.",
      "Ocultá tu perfil de Bumble",
      "Cuando estés en Modo No Molestar, actualizá tu estado para que tus conexiones existentes sepan que estás descansando. Elegí entre 'Estoy de viaje', 'Foco en el trabajo', 'Estoy de detox digital' o 'Me priorizo a mí'. Cualquiera sea tu vibra, nosotros estamos para vos.",
      "Agregá un estado cuando no estés",
      "Activar el Modo No Molestar pone en pausa tu cuenta de Bumble (o {{page_partner_name_bff}}). Tus conexiones no sabrán que estás en Modo No Molestar, a menos que se los digas. Si decidís desactivar el Modo No Molestar antes del plazo que elegiste inicialmente, solo tenés que volver a Configuración y tocar “Desactivar Modo No Molestar”. ¡Es muy simple!",
      "Poné en pausa tus chats",
      "Probá SuperSwipe en Bumble",
      "¡Todos los que quieras! Los usuarios de {{service_level_lexeme_name}} obtienen {{num}} [[superswipe:num]] cada semana, pero podés recargar más en cualquier momento. Los miembros gratuitos pueden comprar paquetes de SuperSwipes.",
      "¿Cuántos SuperSwipes puedo enviar en Bumble?",
      "Después de que alguien te haya dado un SuperSwipe en tu perfil, vas a recibir una notificación de inmediato. También vas a ver una etiqueta sobre el nombre de esa persona en su perfil, que indica que le gustás.",
      "¿Cómo sé si alguien me dio un SuperSwipe?",
      "¿Te pasan cosas con alguien? Animate y enviale un SuperSwipe para que sepa que le interesás y lleguen a una buena charla sin vueltas.",
      "SuperSwipe en Bumble",
      "Destacarse de los demás no siempre es fácil, pero con SuperSwipe podés causar una primera buena impresión.{{__EOL__}}{{__EOL__}}Cuando usás un SuperSwipe, se enviará una notificación a la persona a la que le gustaste. De este modo, aparecés al principio de su lista con una etiqueta en tu perfil. Si también le gustás, es hora de empezar a chatear.",
      "¿Qué es un SuperSwipe?",
      "Animarte a más y destacarte",
      "Empezar más chats",
      "Hacer más conexiones",
      "Con SuperSwipe vas a poder…",
      "Si tenés <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> o Bumble {{service_level_lexeme_name_one}}, obtenés {{num}} [[superswipe:num]] gratis por semana. Además, todos los miembros pueden comprar paquetes de hasta {{num_two}} [[superswipe:num_two]] en la pestaña del perfil. Si tenés {{service_level_lexeme_name_three}}, vas a obtener {{num_three}} [[superswipe:num_three]] por semana.{{__EOL__}}{{__EOL__}}Usar SuperSwipe es fácil: cuando veas a alguien que te guste, simplemente tocá el icono de la estrella amarilla en la parte inferior de tu perfil.",
      "Cómo usar SuperSwipe",
      "Apple y el logotipo de Apple son marcas comerciales de Apple Inc. Google Play es una marca de Google LLC.",
      "Salí con alguien con el Modo Travel de Bumble",
      "Los miembros de <a href={{bumble_premium_url}}>{{service_level_lexeme_name}}</a> pueden usar el Modo Travel. Activalo y van a aparecer nuevas caras en la fila de la ciudad que elijas.",
      "¿Cómo uso el Modo Travel?",
      "Básicamente, tenemos algunas restricciones de seguridad que pueden cambiar con el tiempo, pero el Modo Travel está disponible para la mayoría de los destinos.",
      "¿Puedo usar el Modo Travel en cualquier lugar?",
      "El Modo Travel mostrará tu ubicación como el centro de la ciudad elegida, así te van a encontrar nuevas conexiones en el área. Las personas verán en tu perfil una nota que dice que estás en Modo Travel, para que sepan que estás visitando, o planeando visitar ese lugar.",
      "¿Estás por irte de vacaciones o yendo a una nueva ciudad por trabajo? Activá el Modo Travel de Bumble para tener citas en cualquier parte del mundo.",
      "Modo Travel en Bumble",
      "El Modo Travel es tu herramienta definitiva para hacer conexiones mientras estás afuera. Configurá tu ubicación en cualquier parte del mundo, y tu perfil aparecerá como si estuvieras en la ciudad que elijas. Esto significa que podés tener una citas en dondequiera que te lleven tus aventuras.",
      "¿Qué es el Modo Travel?",
      "Nadie conoce mejor un lugar que los locales. El Modo Travel puede ayudarte a conectarte con las personas que harán que tu viaje sea aún más especial.",
      "Configurá tu ubicación en la ciudad hasta siete días antes de visitarla, para que puedas planificar citas y conocer conexiones con anticipación.",
      "Además de pasear por la playa, podrías estar en casa durante las vacaciones, viajando por trabajo o explorando una nueva ciudad. Encontrá el amor donde aterrices.",
      "Encontrar los mejores lugares para una cita local",
      "Tener una aventura",
      "Tener vínculos duraderos",
      "Razones por las que el Modo Travel te va a encantar",
      "El Modo Travel está disponible para los miembros de <a href={{bumble_premium_url}}>{{service_level_lexeme_name}} y {{service_level_lexeme_name_two}}</a> en Bumble. Tocá en el engranaje de Configuración en la parte superior derecha de tu perfil y desplazate hasta que veas el Modo Travel. Elegí tu nueva ubicación para comenzar a ver personas en esa área. En tu perfil, podrán saber que estás en Modo Travel.",
      "¿Cómo funciona el Modo Travel?",
      "Probar ahora",
      "Probá Bumble en el sitio web.",
      "Absolutamente. La aplicación web de Bumble funciona en PC, Mac y tabletas en todos los navegadores web principales.",
      "¿Puedo usar Bumble en mi PC o Mac?",
      "Sí, se puede. Podés enviar mensajes o incluso hacer una llamada de video o de audio en la aplicación web de Bumble.",
      "¿Puedo chatear con conexiones en la aplicación web?",
      "Para iniciar sesión, elegí 'Entrar' o hacé clic <a href={{sign_in_url}}>acá</a>. Podés iniciar sesión utilizando tu Apple ID, Facebook o número de teléfono.",
      "¿Cómo inicio sesión en Bumble desde el sitio web?",
      "¿Necesitás un descanso del celular? Probá la aplicación web de Bumble: la misma experiencia de Bumble que amás, en una pantalla más grande.",
      "Usá Bumble desde el sitio web",
      "Iniciar sesión en Bumble desde el sitio web es sencillo. Solo visitá nuestro sitio web, hacé clic en 'Iniciar sesión' y elegí tu método de inicio de sesión habitual con tu ID de Apple, detalles de Facebook o número de teléfono. ¡Listo! Iniciaste sesión, y tus chats y conexiones están justo donde los dejaste.",
      "Entrar",
      "Iniciá sesión con tu navegador",
      "Editá o realizá cambios en tu perfil, y aplicá Filtros avanzados.",
      "Confirmá que sos vos para tener más chances de que hagas una conexión increíble.",
      "Compartí nuevas fotos tiernas o eliminá las más antiguas.",
      "Actualizá tu perfil",
      "Mostrá a los demás que realmente sos vos",
      "Agregá o eliminá fotos",
      "Lo que podés hacer en Bumble desde el sitio web",
      "Vas a encontrar tus chats y la lista de conexiones en tu izquierda, y posibles nuevas coincidencias en tu derecha.{{__EOL__}}{{__EOL__}}Utilizá las flechas izquierda y derecha, o hacé clic en los símbolos del lado derecho de tu pantalla para ver los perfiles y darle me gusta a las personas como lo harías en tu teléfono.",
      "¿Cómo uso Bumble en la web?",
      "Acá seducís con tu gentileza, podés ser como realmente sos, y las mujeres siempre tienen la iniciativa.",
      "La confianza de poder dar el primer paso™",
      "Las relaciones sanas son fundamentales para llevar una vida productiva y positiva. El objetivo de Bumble es lograr que te empoderes mientras conocés gente nueva, ya sea en una cita, en una salida para hacer nuevas amistades o simplemente para ampliar tu red de contactos profesional.",
      "Por qué Bumble es diferente",
      "Defendemos la integridad, amabilidad, igualdad, confianza y respeto durante todas las etapas de cualquier relación. No solo damos la bienvenida, sino que también celebramos cualquier orientación sexual, ya sea que estés buscando pareja o simplemente quieras pasar un buen momento.",
      "Qué valoramos en Bumble",
      "En Bumble, las mujeres tienen la palabra, ya que son las que dan el primer paso o permiten iniciar la conversación con una Opening Move. Empezá a relacionarte priorizando la igualdad y olvidate de las dinámicas de poder antiguas.",
      "Cómo funciona Bumble",
      "Sobre nosotros",
      "Descargar ahora",
      "En la app todo es mejor.",
      "Funciones diseñadas para pasar un buen momento en las citas en línea",
      "12 de febrero, 2024",
      "Cómo crear más intimidad emocional en tu relación",
      "25 de enero, 2024",
      "Cómo usar Bumble cuando nunca usaste una app de citas",
      "5 de febrero, 2024",
      "Deception Detector™",
      "Todo lo que necesitás saber sobre citas",
      "Buscá la función <b>Para vos</b> y encontrá los perfiles especialmente sugeridos según tus gustos y conexiones previas.",
      "Sugerencias especiales",
      "Usa el <b>Modo no molestar</b> para tomarte un tiempo. Cuando quieras volver a conocer gente nueva, te esperamos.",
      "Tomate un descanso",
      "Usá <b>videollamadas</b> y <b>audiochats</b> para estar más cerca que nunca de tu conexión sin salir de tu casa.",
      "Decilo a los cuatro vientos",
      "Ver más",
      "Usá la opción <b>Opening Moves</b> para que tu conexión te empiece a escribir antes que vos.",
      "Menos es más",
      "Encontrá lo que te hace feliz en cualquier lugar del mundo y conectá con gente de todas partes con el <b>Modo Travel</b>.",
      "Llevanos con vos",
      "Si te gustó mucho un perfil, dejá que se sepa gracias a <b>SuperSwipe</b>.",
      "Llamá su atención",
      "Sorprendé con tus <b>Compliments</b> y marcá la diferencia.",
      "Regalá una sonrisa",
      "Usá el <b>Modo Incógnito</b> para ocultar tu perfil de tu jefe, familiares o exparejas.",
      "Usá la discreción",
      "Relacionate como quieras",
      "Unos meses después, ella también conoció a su dama de honor",
      "Ana conoció a su actual marido en Bumble.",
      "Antes de que se hicieran la casa juntos, Carina y Javier se conocieron en Bumble. En ese momento, Javier se enamoró cuando vio las fotos de los viajes de Carina.",
      "Carina y Javier",
      "Bauti y Camila trabajaban en la misma ciudad en el exterior, pero se conocieron en Bumble",
      "Bauti y Camila",
      "Si funcionó para otras personas, podría funcionar para vos",
      "Da el próximo paso",
      "Ver las novedades",
      "Como te merecés lo mejor, diseñamos nuevas maneras de conocer más gente en un entorno más relajado.",
      "Encontrá lo que te merecés",
      "Acerca de Bumble",
      "Esta es única aplicación que pone la experiencia de las mujeres en primer lugar para mejorar la experiencia de las citas. Porque un mundo mejor para las mujeres es un lugar mejor para todo el mundo.",
      "Make the first move™",
      "Encontrá a alguien que realmente quieras conocer y animate a dar el primer paso. Conectá genuinamente, dejate llevar por lo que sentís y, sobre todo, pasala bien.",
      "Conocé gente con Bumble Date",
      "Conocé amistades con beneficios laborales. Conectá con otros profesionales para encontrar tu próximo trabajo, mentoría o incluso cambiar tu carrera profesional.",
      "Conectá con profesionales gracias a Bumble Bizz",
      "Conocé gente distinta o que esté en la misma que vos. Generá nuevas amistades para compartir nuevas experiencias.",
      "Encontrá nuevas amistades en Bumble For Friends",
      "No es solo una app de citas",
      "Bumble es la app de citas en la que las mujeres dan el primer paso. Es la app que revolucionó la manera de tener citas, y fomentar relaciones y conexiones reales. Descargala ahora.",
      "Bumble en {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Lo sentimos, pero no pudimos encontrar la página que estabas buscando.",
      "Contacto",
      "Entrar",
      "Parece que algo falló...",
      "(se abre en otra ventana)",
      "Preguntas",
      "Política de cookies",
      "Premium",
      "Bumble - El Blog",
      "Empleos",
      "Chat",
      "Ciudades",
      "Gestionar cookies",
      "Copyright © 2006 – presente. Bumble. Todos los derechos reservados.",
      "Informe de confianza para citas",
      "Apple y el logotipo de Apple son marcas comerciales de Apple Inc. {{__EOL__}}Google Play es una marca de Google LLC.",
      "Descargar",
      "Facebook",
      "Seguinos",
      "Centro de Soporte",
      "Instagram",
      "Idioma",
      "LinkedIn",
      "Bumble es parte de Bumble Inc.",
      "Política de privacidad",
      "Centro de Seguridad",
      "Mapa del sitio",
      "Declaración sobre la Ley de Esclavitud Moderna",
      "Términos y condiciones",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "Cumplidos"
            ],
            "singular": [
                  "Cumplido"
            ]
      },
      "day": {
            "plural": [
                  "días"
            ],
            "singular": [
                  "día"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mes"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipes"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "años"
            ],
            "singular": [
                  "año"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
