import Localization, { TrustedValue } from 'lib/localization';

export default {
    lxDiscoverHeroTitle({ qr_code }: { qr_code: string }) {
        return Localization.get('bumblesite@pages.features.discover.hero.title', { qr_code });
    },
    get lxDiscoverHeroSubtitle() {
        return Localization.get('bumblesite@pages.features.discover.hero.subtitle');
    },
    get lxDiscoverOneBlockTitle() {
        return Localization.get('bumblesite@pages.features.discover.one.block.title');
    },
    get lxDiscoverOneBlockBodyText() {
        return Localization.get('bumblesite@pages.features.discover.one.block.body.text');
    },
    get lxDiscoverOneBlockCta() {
        return Localization.get('bumblesite@pages.features.discover.one.block.cta');
    },
    get lxDiscoverTwoBlockTitle() {
        return Localization.get('bumblesite@pages.features.discover.two.block.title', {
            __EOL__: TrustedValue.for('<br/>'),
        });
    },
    get lxDiscoverTwoBlockBodyText() {
        return Localization.get('bumblesite@pages.features.discover.two.block.body.text');
    },
    get lxFeaturesDownloadBumbleCta() {
        return Localization.get('bumblesite@pages.features.download.bumble.cta');
    },
    get lxDiscoverThreeBlockTitle() {
        return Localization.get('bumblesite@pages.features.discover.three.block.title');
    },
    get lxDiscoverThreeBlockBodyText() {
        return Localization.get('bumblesite@pages.features.discover.three.block.body.text');
    },
    get lxFeaturesFaqTitle() {
        return Localization.get('bumblesite@pages.features.faq.title');
    },
    get lxDiscoverFaqQuestionOneTitle() {
        return Localization.get('bumblesite@pages.features.discover.faq.question.one.title');
    },
    get lxDiscoverFaqQuestionOneAnswer() {
        return Localization.get('bumblesite@pages.features.discover.faq.question.one.answer');
    },
    get lxDiscoverFaqQuestionTwoTitle() {
        return Localization.get('bumblesite@pages.features.discover.faq.question.two.title');
    },
    get lxDiscoverFaqQuestionTwoAnswer() {
        return Localization.get('bumblesite@pages.features.discover.faq.question.two.answer');
    },
    get lxDiscoverCtaText() {
        return Localization.get('bumblesite@pages.features.discover.cta.text');
    },
};
