import Localization from 'lib/localization';

export default {
    get lxPageSEOTitle() {
        // check if it is server lexeme
        return 'Bumble | Date, Chat, Meet New People & Network Better';
        // return Localization.get('bumblesite@rebrand.2024.page.seo.title');
    },

    get lxPageSEOText() {
        return Localization.get('bumblesite@rebrand.2024.page.seo.text');
    },

    // Feature pages

    get lxFeatureSuperswipeSEOTitle() {
        return Localization.get('bumblesite@feature.superswipe.seo.title');
    },

    get lxFeatureSuperswipeSEOText() {
        return Localization.get('bumblesite@feature.superswipe.seo.text');
    },

    get lxFeatureComplimentsSEOTitle() {
        return Localization.get('bumblesite@feature.compliments.seo.title');
    },

    get lxFeatureComplimentsSEOText() {
        return Localization.get('bumblesite@feature.compliments.seo.text');
    },

    get lxFeatureVideoChatSEOTitle() {
        return Localization.get('bumblesite@feature.video-chat.seo.title');
    },

    get lxFeatureVideoChatSEOText() {
        return Localization.get('bumblesite@feature.video-chat.seo.text');
    },

    get lxFeatureIncognitoModeSEOTitle() {
        return Localization.get('bumblesite@feature.incognito-mode.seo.title');
    },

    get lxFeatureIncognitoModeSEOText() {
        return Localization.get('bumblesite@feature.incognito-mode.seo.text');
    },

    get lxFeatureSnoozeModeSEOTitle() {
        return Localization.get('bumblesite@feature.snooze-mode.seo.title');
    },

    get lxFeatureSnoozeModeSEOText() {
        return Localization.get('bumblesite@feature.snooze-mode.seo.text');
    },

    get lxFeatureTravelModeSEOTitle() {
        return Localization.get('bumblesite@feature.travel-mode.seo.title');
    },

    get lxFeatureTravelModeSEOText() {
        return Localization.get('bumblesite@feature.travel-mode.seo.text');
    },

    get lxFeatureOpeningMovesSEOTitle() {
        return Localization.get('bumblesite@feature.opening-moves.seo.title');
    },

    get lxFeatureOpeningMovesSEOText() {
        return Localization.get('bumblesite@feature.opening-moves.seo.text');
    },

    get lxFeatureInstantMatchSEOTitle() {
        return Localization.get('bumblesite@feature.instant-match.seo.title');
    },

    get lxFeatureInstantMatchSEOText() {
        return Localization.get('bumblesite@feature.instant-match.seo.text');
    },

    get lxFeatureDiscoverSEOTitle() {
        return Localization.get('bumblesite@feature.discover.seo.title');
    },

    get lxFeatureDiscoverSEOText() {
        return Localization.get('bumblesite@feature.discover.seo.text');
    },

    get lxFeatureWebSEOTitle() {
        return Localization.get('bumblesite@feature.web.seo.title');
    },

    get lxFeatureWebSEOText() {
        return Localization.get('bumblesite@feature.web.seo.text');
    },
};
