import Localization from 'lib/localization';

export default {
    get lxHeaderItemLabelShop() {
        return Localization.get('bumblesite@LAYOUT.HEADER.THE_SHOP');
    },

    get lxHeaderItemLabelApp() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.THEAPP');
    },

    get lxHeaderItemLabelAppModeDate() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_DATE');
    },

    get lxHeaderItemDescriptionAppModeDate() {
        return Localization.get('bumblesite@layout.header.bumble_date.description');
    },

    get lxHeaderItemLabelAppModeBFF() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_BFF');
    },

    get lxHeaderItemDescriptionAppModeBFF() {
        return Localization.get('bumblesite@layout.header.bumble_bff.description');
    },

    get lxHeaderItemLabelAppModeBizz() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_BIZZ');
    },

    get lxHeaderItemDescriptionAppModeBizz() {
        return Localization.get('bumblesite@layout.header.bumble_bizz.description');
    },

    get lxHeaderItemLabelAmbassadors() {
        return Localization.get('bumblesite@LAYOUT.HEADER.AMBASSADORS');
    },

    get lxHeaderItemLabelEvents() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.EVENTS');
    },

    get lxHeaderItemLabelBuzz() {
        return Localization.get('bumblesite@LAYOUT.HEADER.BEEHIVE');
    },

    get lxHeaderItemLabelAbout() {
        return Localization.get('bumblesite@LAYOUT.HEADER.ABOUT');
    },

    get lxA11yNavigationMain() {
        return Localization.get('bumblesite@layout.main.a11y.label');
    },

    get lxHeaderItemLabelFeatures() {
        return Localization.get('bumblesite@layout.header.features');
    },

    get lxHeaderItemLabelFeaturesOpeningMoves() {
        return Localization.get('bumblesite@layout.header.features.opening_moves');
    },

    get lxHeaderItemDescriptionFeaturesOpeningMoves() {
        return Localization.get('bumblesite@layout.header.features.opening_moves.description');
    },

    get lxHeaderItemLabelFeaturesVideoChat() {
        return Localization.get('bumblesite@layout.header.features.video_chat');
    },

    get lxHeaderItemDescriptionFeaturesVideoChat() {
        return Localization.get('bumblesite@layout.header.features.video_chat.description');
    },

    get lxHeaderItemLabelFeaturesTravelMode() {
        return Localization.get('bumblesite@layout.header.features.travel_mode');
    },

    get lxHeaderItemDescriptionFeaturesTravelMode() {
        return Localization.get('bumblesite@layout.header.features.travel_mode.description');
    },

    get lxHeaderCtaTitleFeaturesTravelMode() {
        return Localization.get('bumblesite@layout.header.cta.title.features.travel_mode');
    },

    get lxHeaderItemLabelFeaturesIncognitoMode() {
        return Localization.get('bumblesite@layout.header.features.incognito_mode');
    },

    get lxHeaderItemDescriptionFeaturesIncognitoMode() {
        return Localization.get('bumblesite@layout.header.features.incognito_mode.description');
    },

    get lxHeaderItemLabelFeaturesSnoozeMode() {
        return Localization.get('bumblesite@layout.header.features.snooze_mode');
    },

    get lxHeaderItemDescriptionFeaturesSnoozeMode() {
        return Localization.get('bumblesite@layout.header.features.snooze_mode.description');
    },

    get lxHeaderItemLabelFeaturesCompliments() {
        return Localization.get('bumblesite@layout.header.features.compliments');
    },

    get lxHeaderItemDescriptionFeaturesCompliments() {
        return Localization.get('bumblesite@layout.header.features.compliments.description');
    },

    get lxHeaderItemLabelFeaturesSuperswipe() {
        return Localization.get('bumblesite@layout.header.features.superswipe');
    },

    get lxHeaderItemDescriptionFeaturesSuperswipe() {
        return Localization.get('bumblesite@layout.header.features.superswipe.description');
    },

    get lxHeaderItemLabelFeaturesInstantMatch() {
        return Localization.get('bumblesite@layout.header.features.instant-match');
    },

    get lxHeaderItemDescriptionFeaturesInstantMatch() {
        return Localization.get('bumblesite@layout.header.features.instant-match.description');
    },
    get lxHeaderItemLabelFeaturesDiscover() {
        return Localization.get('bumblesite@layout.header.features.discover');
    },

    get lxHeaderItemDescriptionFeaturesDiscover() {
        return Localization.get('bumblesite@layout.header.features.discover.description');
    },
    get lxHeaderItemLabelFeaturesWebApp() {
        return Localization.get('bumblesite@layout.header.features.web_app');
    },

    get lxHeaderItemDescriptionFeaturesWebApp() {
        return Localization.get('bumblesite@layout.header.features.web_app.description');
    },

    get lxHeaderCtaButtonFindOutMore() {
        return Localization.get('bumblesite@layout.header.cta.find_out_more.button');
    },

    get lxA11yNavigationMenuOpen() {
        return Localization.get('bumblesite@header.navigation.menu.open.a11y.label');
    },
    get lxA11yNavigationMenuClose() {
        return Localization.get('bumblesite@header.navigation.menu.close.a11y.label');
    },
};
