import { AppLink } from 'lib/app/app-link';
import { FEATURE_COMPLIMENTS_AMOUNT_NUM } from 'lib/features/constants';
import Localization from 'lib/localization';

export default {
    get lxComplimentsHeroTitle() {
        return Localization.get('bumblesite@pages.features.compliments.hero.title');
    },
    get lxComplimentsHeroSubtitle() {
        return Localization.get('bumblesite@pages.features.compliments.hero.subtitle');
    },
    get lxComplimentsOneBlockTitle() {
        return Localization.get('bumblesite@pages.features.compliments.one.block.title');
    },
    get lxComplimentsOneBlockBodyText() {
        return Localization.get('bumblesite@pages.features.compliments.one.block.body.text', {
            service_level_lexeme_name: Localization.get('bumble_boost@gold.name'),
        });
    },
    get lxFeaturesDownloadBumbleCta() {
        return Localization.get('bumblesite@pages.features.download.bumble.cta');
    },
    get lxComplimentsTwoBlockTitle() {
        return Localization.get('bumblesite@pages.features.compliments.two.block.title');
    },
    get lxComplimentsTwoBlockBodyText() {
        return Localization.get('bumblesite@pages.features.compliments.two.block.body.text');
    },
    // same as two block title
    get lxComplimentsTwoBlockCta() {
        return Localization.get('bumblesite@pages.features.compliments.two.block.title');
    },
    get lxComplimentsThreeBlockTitle() {
        return Localization.get('bumblesite@pages.features.compliments.three.block.title');
    },
    get lxComplimentsThreeBlockSubtitle() {
        return Localization.get('bumblesite@pages.features.compliments.three.block.subtitle');
    },
    get lxComplimentsFourBlockTitle() {
        return Localization.get('bumblesite@pages.features.compliments.four.block.title');
    },
    get lxComplimentsFourBlockBodyText() {
        return Localization.get('bumblesite@pages.features.compliments.four.block.body.text');
    },
    get lxComplimentsFourBlockExampleOne() {
        return Localization.get('bumblesite@pages.features.compliments.four.block.example.one');
    },
    get lxComplimentsFourBlockExampleTwo() {
        return Localization.get('bumblesite@pages.features.compliments.four.block.example.two');
    },
    get lxComplimentsFourBlockExampleThree() {
        return Localization.get('bumblesite@pages.features.compliments.four.block.example.three');
    },
    get lxComplimentsFourBlockCta() {
        return Localization.get('bumblesite@pages.features.compliments.four.block.cta');
    },
    get lxFeaturesFaqTitle() {
        return Localization.get('bumblesite@pages.features.faq.title');
    },
    get lxComplimentsFaqQuestionOneTitle() {
        return Localization.get('bumblesite@pages.features.compliments.faq.question.one.title');
    },
    get lxComplimentsFaqQuestionOneAnswer() {
        return Localization.get('bumblesite@pages.features.compliments.faq.question.one.answer');
    },
    get lxComplimentsFaqQuestionTwoTitle() {
        return Localization.get('bumblesite@pages.features.compliments.faq.question.two.title');
    },
    get lxComplimentsFaqQuestionTwoAnswer() {
        return Localization.get('bumblesite@pages.features.compliments.faq.question.two.answer', {
            num: FEATURE_COMPLIMENTS_AMOUNT_NUM,
        });
    },
    get lxComplimentsFaqQuestionThreeTitle() {
        return Localization.get('bumblesite@pages.features.compliments.faq.question.three.title');
    },
    get lxComplimentsFaqQuestionThreeAnswer() {
        return Localization.get('bumblesite@pages.features.compliments.faq.question.three.answer', {
            make_the_first_move_url: AppLink.BUMBLE_MAKE_THE_FIRST_MOVE,
        });
    },
    get lxComplimentsCta() {
        return Localization.get('bumblesite@pages.features.compliments.cta');
    },
};
