import { AppLink } from 'lib/app/app-link';
import Localization from 'lib/localization';

export default {
    get lxAudioCallsHeroTitle() {
        return Localization.get('bumblesite@pages.features.audio-calls.hero.title');
    },
    get lxAudioCallsHeroSubtitle() {
        return Localization.get('bumblesite@pages.features.audio-calls.hero.subtitle');
    },
    get lxAudioCallsOneBlockTitle() {
        return Localization.get('bumblesite@pages.features.audio-calls.one.block.title');
    },
    get lxAudioCallsOneBlockBodyText() {
        return Localization.get('bumblesite@pages.features.audio-calls.one.block.body.text', {
            video_chat_url: AppLink.BUMBLE_VIDEO_CHAT_VOICE_CALL,
        });
    },
    get lxFeaturesDownloadBumbleCta() {
        return Localization.get('bumblesite@pages.features.download.bumble.cta');
    },
    get lxAudioCallsTwoBlockTitle() {
        return Localization.get('bumblesite@pages.features.audio-calls.two.block.title');
    },
    get lxAudioCallsTwoBlockBodyText() {
        return Localization.get('bumblesite@pages.features.audio-calls.two.block.body.text');
    },
    get lxFeaturesTryItNowCta() {
        return Localization.get('bumblesite@pages.features.try.it.now.cta');
    },
    get lxAudioCallsThreeBlockTitle() {
        return Localization.get('bumblesite@pages.features.audio-calls.three.block.title');
    },
    get lxAudioCallsThreeBlockSubtitleOne() {
        return Localization.get('bumblesite@pages.features.audio-calls.three.block.subtitle.one');
    },
    get lxAudioCallsThreeBlockBodyTextOne() {
        return Localization.get('bumblesite@pages.features.audio-calls.three.block.body.text.one');
    },
    get lxAudioCallsThreeBlockSubtitleTwo() {
        return Localization.get('bumblesite@pages.features.audio-calls.three.block.subtitle.two');
    },
    get lxAudioCallsThreeBlockBodyTextTwo() {
        return Localization.get('bumblesite@pages.features.audio-calls.three.block.body.text.two');
    },
    get lxAudioCallsThreeBlockSubtitleThree() {
        return Localization.get('bumblesite@pages.features.audio-calls.three.block.subtitle.three');
    },
    get lxAudioCallsThreeBlockBodyTextThree() {
        return Localization.get(
            'bumblesite@pages.features.audio-calls.three.block.body.text.three',
        );
    },
    get lxFeaturesFaqTitle() {
        return Localization.get('bumblesite@pages.features.faq.title');
    },
    get lxAudioCallsFaqQuestionOneTitle() {
        return Localization.get('bumblesite@pages.features.audio-calls.faq.question.one.title');
    },
    get lxAudioCallsFaqQuestionOneAnswer() {
        return Localization.get('bumblesite@pages.features.audio-calls.faq.question.one.answer');
    },
    get lxAudioCallsFaqQuestionTwoTitle() {
        return Localization.get('bumblesite@pages.features.audio-calls.faq.question.two.title');
    },
    get lxAudioCallsFaqQuestionTwoAnswer() {
        return Localization.get('bumblesite@pages.features.audio-calls.faq.question.two.answer');
    },
    get lxAudioCallsFaqQuestionThreeTitle() {
        return Localization.get('bumblesite@pages.features.audio-calls.faq.question.three.title');
    },
    get lxAudioCallsFaqQuestionThreeAnswer() {
        return Localization.get('bumblesite@pages.features.audio-calls.faq.question.three.answer');
    },
    get lxAudioCallsCta() {
        return Localization.get('bumblesite@pages.features.audio-calls.cta');
    },
};
