export enum AppLink {
    // @TODO add dynamic parameters
    BUMBLE_GET_STARTED = '/get-started?utm_source=report&utm_medium=web&utm_campaign=bumble_feature_pages&utm_id=bumble_feature_page&utm_content=cta-button',
    BUMBLE_PREMIUM = '/the-buzz/bumble-premium',
    BUMBLE_COMPLIMENT_TIPS = '/the-buzz/bumble-compliments-tips',
    BUMBLE_MAKE_THE_FIRST_MOVE = '/the-buzz/bumble-make-the-first-move',
    BUMBLE_VIDEO_CHAT_VOICE_CALL = '/the-buzz/video-chat-voice-call',
    BUMBLE_WHAT_IS_GHOSTING = '/the-buzz/what-is-ghosting-and-how-not-to-do-it-dating',
    BUMBLE_BOOST = '/the-buzz/bumble-boost',
    BLOCKING_ON_BUMBLE = '/the-buzz/block-report-bumble',
}
