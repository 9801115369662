import { FeatureRoutes } from 'lib/features';

import { RouteName } from 'containers/routes';

export type Route = RouteName | FeatureRoutes;

type MapRouteToSeoTitleRecord = { [key in Route]: string };

export const domain = `https://bumble.com`;

export enum SeoCanonical {
    index = domain,
    about = `${domain}/about/`,
    date = `${domain}/date/`,
    features_opening_moves = `${domain}/features/opening-moves/`,
    features_compliments = `${domain}/features/compliments/`,
    features_incognito_mode = `${domain}/features/incognito-mode/`,
    features_snooze_mode = `${domain}/features/snooze-mode/`,
    features_superswipe = `${domain}/features/superswipe/`,
    features_travel_mode = `${domain}/features/travel-mode/`,
    features_video_chat = `${domain}/features/video-chat/`,
    features_instant_match = `${domain}/features/instant-match/`,
    features_discover = `${domain}/features/discover/`,
    features_web = `${domain}/features/web/`,
    localised_features_opening_moves = `${domain}/[lang]/features/opening-moves/`,
    localised_features_compliments = `${domain}/[lang]/features/compliments/`,
    localised_features_incognito_mode = `${domain}/[lang]/features/incognito-mode/`,
    localised_features_snooze_mode = `${domain}/[lang]/features/snooze-mode/`,
    localised_features_superswipe = `${domain}/[lang]/features/superswipe/`,
    localised_features_travel_mode = `${domain}/[lang]/features/travel-mode/`,
    localised_features_video_chat = `${domain}/[lang]/features/video-chat/`,
    localised_features_instant_match = `${domain}/[lang]/features/instant-match/`,
    localised_features_discover = `${domain}/[lang]/features/discover/`,
    localised_features_web = `${domain}/[lang]/features/web/`,
}

export const seoCanonicalFabric: (pathname: Route) => string = (pathname) => {
    const seoCanonical: MapRouteToSeoTitleRecord = {
        [RouteName.index]: SeoCanonical.index,
        [RouteName.about]: SeoCanonical.about,
        [RouteName.date]: SeoCanonical.date,
        [RouteName.localisedIndex]: SeoCanonical.index,
        [RouteName.localisedAbout]: SeoCanonical.about,
        [RouteName.localisedDate]: SeoCanonical.date,
        [FeatureRoutes.Compliments]: SeoCanonical.features_compliments,
        [FeatureRoutes.Incognito_mode]: SeoCanonical.features_incognito_mode,
        [FeatureRoutes.Opening_moves]: SeoCanonical.features_opening_moves,
        [FeatureRoutes.Snooze_mode]: SeoCanonical.features_snooze_mode,
        [FeatureRoutes.Superswipe]: SeoCanonical.features_superswipe,
        [FeatureRoutes.Travel_mode]: SeoCanonical.features_travel_mode,
        [FeatureRoutes.Video_chat]: SeoCanonical.features_video_chat,
        [FeatureRoutes.Instant_match]: SeoCanonical.features_instant_match,
        [FeatureRoutes.Discover]: SeoCanonical.features_discover,
        [FeatureRoutes.Web]: SeoCanonical.features_web,
        [FeatureRoutes.LocalisedCompliments]: SeoCanonical.localised_features_compliments,
        [FeatureRoutes.LocalisedIncognito_mode]: SeoCanonical.localised_features_incognito_mode,
        [FeatureRoutes.LocalisedOpening_moves]: SeoCanonical.localised_features_opening_moves,
        [FeatureRoutes.LocalisedSnooze_mode]: SeoCanonical.localised_features_snooze_mode,
        [FeatureRoutes.LocalisedSuperswipe]: SeoCanonical.localised_features_superswipe,
        [FeatureRoutes.LocalisedTravel_mode]: SeoCanonical.localised_features_travel_mode,
        [FeatureRoutes.LocalisedVideo_chat]: SeoCanonical.localised_features_video_chat,
        [FeatureRoutes.LocalisedInstantMatch]: SeoCanonical.localised_features_instant_match,
        [FeatureRoutes.LocalisedDiscover]: SeoCanonical.localised_features_discover,
        [FeatureRoutes.LocalisedWeb]: SeoCanonical.localised_features_web,
    };
    return seoCanonical[pathname as Route];
};

export const setSeoTitle = (pathname: Route) => {
    return seoCanonicalFabric(pathname as Route);
};
