import { AppLink } from 'lib/app/app-link';
import Localization from 'lib/localization';

export default {
    get lxWebHeroTitle() {
        return Localization.get('bumblesite@pages.features.web.hero.title');
    },
    get lxWebHeroSubtitle() {
        return Localization.get('bumblesite@pages.features.web.hero.subtitle');
    },
    get lxWebOneBlockTitle() {
        return Localization.get('bumblesite@pages.features.web.one.block.title');
    },
    get lxWebOneBlockBodyText() {
        return Localization.get('bumblesite@pages.features.web.one.block.body.text');
    },
    get lxWebOneBlockCta() {
        return Localization.get('bumblesite@pages.features.web.one.block.cta');
    },
    get lxWebTwoBlockTitle() {
        return Localization.get('bumblesite@pages.features.web.two.block.title');
    },
    get lxWebTwoBlockBodyText() {
        return Localization.get('bumblesite@pages.features.web.two.block.body.text');
    },
    get lxFeaturesTryItNowCta() {
        return Localization.get('bumblesite@pages.features.try.it.now.cta');
    },
    get lxWebThreeBlockTitle() {
        return Localization.get('bumblesite@pages.features.web.three.block.title');
    },
    // @TODO - update this url - not confirmed yet where this should link to. Linked to get started temporarily
    get lxFeaturesSeeMoreCta() {
        return Localization.get('bumblesite@pages.features.see.more.cta', {
            see_more_url: AppLink.BUMBLE_GET_STARTED,
        });
    },
    get lxWebThreeBlockSubtitleOne() {
        return Localization.get('bumblesite@pages.features.web.three.block.subtitle.one');
    },
    get lxWebThreeBlockBodyTextOne() {
        return Localization.get('bumblesite@pages.features.web.three.block.body.text.one');
    },
    get lxWebThreeBlockSubtitleTwo() {
        return Localization.get('bumblesite@pages.features.web.three.block.subtitle.two');
    },
    get lxWebThreeBlockBodyTextTwo() {
        return Localization.get('bumblesite@pages.features.web.three.block.body.text.two');
    },
    get lxWebThreeBlockSubtitleThree() {
        return Localization.get('bumblesite@pages.features.web.three.block.subtitle.three');
    },
    get lxWebThreeBlockBodyTextThree() {
        return Localization.get('bumblesite@pages.features.web.three.block.body.text.three');
    },
    get lxFeaturesFaqTitle() {
        return Localization.get('bumblesite@pages.features.faq.title');
    },
    get lxWebFaqQuestionOneTitle() {
        return Localization.get('bumblesite@pages.features.web.faq.question.one.title');
    },
    get lxWebFaqQuestionOneAnswer() {
        return Localization.get('bumblesite@pages.features.web.faq.question.one.answer');
    },
    get lxWebFaqQuestionTwoTitle() {
        return Localization.get('bumblesite@pages.features.web.faq.question.two.title');
    },
    get lxWebFaqQuestionTwoAnswer() {
        return Localization.get('bumblesite@pages.features.web.faq.question.two.answer', {
            sign_in_url: AppLink.BUMBLE_GET_STARTED,
        });
    },
    get lxWebFaqQuestionThreeTitle() {
        return Localization.get('bumblesite@pages.features.web.faq.question.three.title');
    },
    get lxWebFaqQuestionThreeAnswer() {
        return Localization.get('bumblesite@pages.features.web.faq.question.three.answer');
    },
    get lxWebCta() {
        return Localization.get('bumblesite@pages.features.web.cta');
    },
};
