/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Mene takaisin",
      "Lataa App Storesta",
      "Hanki se Google Playsta",
      "Karuselli",
      "Kuva {{number}}",
      "Seuraava dia",
      "Edellinen dia",
      "Kuvasarja",
      "Kuva {{number}} kautta {{total}}",
      "Älä myy tai jaa henkilökohtaisia tietojani",
      "Älä myy tai jaa henkilökohtaisia tietojani",
      "Lähetä Compliments Bumblessa osoittaaksesi, että tykkäät profiilista ja kiinnittääksesi käyttäjän huomion ennen yhteyden luomista. Kokeile sitä nyt",
      "Compliments | Näin lähetät kehun | Bumble",
      "Sinulle räätälöity sivu, josta löydät sopivimmat henkilöt juuri sinulle – uusia profiileja päivittäin",
      "Ehdotukset | Löydä sinulle sopivia ihmisiä | Bumble",
      "Deittaile omaan tahtiisi ja päätä itse, kuka näkee Bumble-profiilisi käyttämällä Incognito-tilaa. Lisätietoja Bumblen Incognito-tilan käytöstä löydät oppaastamme",
      "Incognito-tila | Piilota profiilisi | Bumble",
      "Tavattuanne Bumblen IRL-tapahtumassa voitte katsoa, miten kemianne kehittyvät ja jatkaa keskustelua sovelluksessa.",
      "Luo yhteys heti | Jatka juttelua sovelluksessa | Bumble",
      "Kokeile uutta tapaa aloittaa keskustelu Bumblen Opening Moves -ominaisuudella. Kiinnitä hänen huomionsa, luo oikea tunnelma ja pääse nopeammin hyviin keskusteluihin",
      "Bumble Opening Moves | Bumble",
      "Tarvitsetko taukoa tai hieman aikaa itsellesi? Ota selvää, miten voit laittaa Bumble-tilisi tauolle Bumblen Poissaolotilalla. Se antaa sinulle vapauden deittailla omilla ehdoillasi",
      "Poissaolotila | Laita tilisi tauolle | Bumble",
      "Kiinnostaako joku toden teolla Bumblessa? Näytä, että olet tosissasi lähettämällä SuperSwipe. Napauta hänen profiilissaan olevaa tähteä aloittaaksesi, tai katso lisätietoja tästä",
      "Bumble SuperSwipe | Mikä on SuperSwipe? | Bumble",
      "Matkustatko työn tai vapaa-ajan merkeissä? Bumblen Matkatila mahdollistaa uusien hauskojen yhteyksien luomisen uudessa paikassa ja deittailun missä vaan. Tutustu nyt Matkatilaan täältä",
      "Bumblen Matkatila | Deittaile missä tahansa | Bumble",
      "Haluatko tietää, onko viesteissä tuntemasi kemia yhtä vahvaa myös kasvotusten? Kokeile video- tai äänipuhelua Bumblessa ja ota selvää fiiliksistä",
      "Videopuhelu | Soita puhelu | Bumble",
      "Koe Bumble Webin kiehtova maailma – täydellinen käyttökokemus!",
      "Bumble Web | Bumble",
      "Päätä sopimukset tästä",
      "Bumblen kotisivu",
      "Sulje valikko",
      "Avaa valikko",
      "Kielivalinnat",
      "Alatunniste",
      "Saavutettavuus Bumblessa",
      "Apple ja Apple-logo ovat Apple Inc:n tavaramerkkejä.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Työpaikat",
      "Tapahtumat",
      "Google Play on Google LLC:n tavaramerkki.",
      "Käyttöohjeet",
      "Sijoittajat",
      "Hallinnoi evästeitä",
      "Hallinnoi evästeitä",
      "Lausunto nykyajan orjuudesta",
      "Tietojen kerääminen",
      "Tietosuoja",
      "Kausiluontoisia deittioppaita",
      "Käyttöehdot",
      "Appi",
      "Kuluttajien terveystietojen tietosuoja Washingtonissa",
      "Yleistä",
      "Tuote-edustajat",
      "Buzz",
      "Laajenna ystäväpiiriäsi ja löydä yhteisösi",
      "Verkostoidu ammatillisesti ja tee urasiirtoja",
      "Löydä parisuhde, jotain rennompaa tai mitä tahansa siltä väliltä",
      "Lisätietoa",
      "Seikkaile maailmalla Matkatilassa",
      "Ominaisuudet",
      "Compliments",
      "Erotu muista Complimentsin, eli kehujen avulla.",
      "Discover",
      "Sinulle räätälöity sivu, josta löydät sopivimmat henkilöt juuri sinulle – uusia profiileja päivittäin",
      "Incognito-tila",
      "Piilota profiilisi pomoltasi, sukulaisiltasi tai exiltäsi Incognito-tilalla.",
      "Luo yhteys heti",
      "Luistiko juttu jonkun kanssa Bumblen IRL-tapahtumassa? Jatka samaan malliin sovelluksessa!",
      "Opening Moves",
      "<b>Opening Movesin</b> avulla yhteytesi voi aloittaa keskustelun, jotta sinun ei tarvitse.",
      "Poissaolotila",
      "Poissaolotilalla voit pitää taukoa deittailusta. Toivotamme sinut aina ilolla takaisin.",
      "SuperSwipe",
      "Natsasiko joku täysillä? Kerro se SuperSwipellä.",
      "Matkatila",
      "Matkatilassa löydät verottomien herkkujen lisäksi yhteyksiä maailmalta.",
      "Videopuhelu",
      "Käytä videopuheluja ja äänipuheluja selvittääksesi, oletko valmis tapaamaan kasvokkain.",
      "Verkkosovellus",
      "Nauti Bumble-kokemuksesta suoraan selaimestasi",
      "Kauppa",
      "Päävalikko",
      "Palaa kotisivulle",
      "Bumblen logo",
      "Haluatko tutustua paremmin? Käytä Bumblen videopuhelua",
      "Napauta vain videokuvaketta keskustelussasi aloittaaksesi videopuhelun – tämä onnistuu helposti ilman henkilötietojen jakamista.",
      "Miten videopuhelu Bumblessa toimii?",
      "Ei. Kaikki puhelut ja keskustelut Bumblessa pitävät henkilötietosi salattuina ja turvassa. Muut eivät saa puhelinnumeroasi tai muita yhteystietojasi ennen kuin sinä kerrot ne heille.",
      "Näkeekö yhteyteni puhelinnumeroni?",
      "Tottakai! Jos et ole ihan vielä valmis näkemään kasvotusten, äänipuhelu mahdollistaa tutustumisen puhumalla. Valitse vain äänipuhelu siinä tapauksessa.",
      "Voinko sen sijaan kokeilla äänipuhelua?",
      "Mikä sen parempaa kuin \"Saapunut viesti\" -ilmoitus? Voi olla kuitenkin hankala tietää, sujuuko jutustelu yhtä hyvin kasvotusten. Juuri siksi video- ja äänipuhelut ovat erinomainen ratkaisu.",
      "Videopuhelu Bumblessa",
      "<a href={{video_chat_url}}>Videopuhelut ja äänipuhelut</a> antavat sinulle mahdollisuuden tutustua toiseen paremmin ja päättää, haluatko tavata sovelluksen ulkopuolella. Ja mikä parasta? Kun soitat videopuhelun Bumblessa, sinun ei tarvitse antaa puhelinnumeroasi – henkilötietosi pysyvät siis turvassa kunnes päätät itse jakaa ne.",
      "Tarkista kohtaavatko kemianne videopuhelulla",
      "Täydellinen keino siirtyä verkkodeittailusta hitaasti kohti oikeaa deittailua ja ottaa selvää, onko henkilö tapaamisen arvoinen.",
      "Tutustu toiseen paremmin (ja nopeammin). Keskustelu kasvokkain tai jutustelu puhelimessa voi kertoa paljon toisesta.",
      "Sinä päätät. Me pidämme yhteystietosi salassa, jotta voit itse valita, milloin ne jaat.",
      "Ota selvää, oletko valmis tapaamaan kasvokkain",
      "Tsekkaa mikä fiilis",
      "Ota yhteyttä ilman henkilötietojen jakamista",
      "Tämän vuoksi video- ja äänipuhelut kannattaa",
      "Valmiina aloittamaan keskustelun? Voit valita, aloitatko äänipuhelun tai videopuhelun suoraan keskustelustasi Bumblessa. Aloittaaksesi videopuhelun napauta harmaata videokamerakuvaketta oikealla yläkulmassa. Vieressä olevaa kolmea pistettä klikkaamalla voit aloittaa myös äänipuhelun.",
      "Näin soitat videopuhelun",
      "Valmis kokeilemaan Complimentsia?",
      "Kun lähetät kehun, sen saanut henkilö näkee sen, kun ilmestyt hänen jonoonsa.",
      "Mitä tapahtuu, kun lähetät kehun jollekin Bumblessa?",
      "Jos käyttäjän lähettämä Compliment nappasi, niin anna ihmeessä kehu takaisin. Näet Complimentin keskustelussasi, ja normaali <a href={{make_the_first_move_url}}>Tee ensimmäinen siirto</a> -sääntö on voimassa.",
      "Miten vastaan Complimentiin eli kehuun?",
      "Voit lähettää {{num}} [[Compliments:num@1]] ilmaiseksi. Kun ne loppuvat, voit ostaa niitä lisää Bumble-sovelluksessa jopa 30 kpl pakettina. Yksi Compliment käytetään aina viestin lähettämiseen (säästä loput toista kiinnostavaa profiilia varten). Älä huoli, ne eivät vanhene!",
      "Kuinka monta kehua voin lähettää?",
      "Ystävällinen kommentti (jos toinenkin) voi viedä pitkälle. Jos haluat lähettää Complimentin, mutta pohdit vielä mitä sanoa, ota näistä inspiraatiota:",
      "Katso lisää esimerkkejä",
      "“Jos oot [artistin nimi]-fani, niin sulla on takuuvarmasti hyvä maku.”",
      "\"Sun hymy on tosi tarttuva!\"",
      "“Sun profiiliteksti sai mut nauramaan. Ihan paras.”",
      "Vinkkejä parempien kehujen lähettämiseen Bumblessa",
      "Haluatko piristää toisen päivää? Kuulostaa siltä, että Compliments on juttusi.",
      "Compliments Bumblessa",
      "Käytä Complimentsia Bumblessa kertoaksesi potentiaaliselle yhteydelle, että tykkäät hänen profiilistaan (ja saatat jopa saada hänet punastumaan). Saat Complimentseja osana {{service_level_lexeme_name}}-jäsenyyttäsi. Niiden avulla voit lähettää ja vastaanottaa viestejä jo ennen yhteyden luomista.",
      "Näin Compliments-ominaisuus toimii",
      "Kun saat Complimentin, se näkyy lähettäjän profiilissa. Saat myös ilmoituksen, että joku on tykännyt jostain profiilisi kohdasta.",
      "Katso kehusi",
      "Voit lähettää Complimentin napauttamalla keltaista sydänsymbolia profiilin alaosassa. Se missä kohtaa napautat on tärkeää, sillä kehu kohdistuu sille tietylle kuvalle tai profiilin kohdalle! Klikatessasi sitä tekstilaatikko avautuu, johon voit kirjoittaa viestisi (maks. 150 merkkiä).",
      "Lähetä kehu",
      "LATAA Bumble JA KOKEILE EHDOTUKSET-OSIOTA",
      "Joka päivä klo 9 alkaen näet uusia ihmisiä Ehdotukset-sivullasi. Voit tutustua heihin yhteisten kiinnostuksen kohteiden kautta.",
      "Milloin Ehdotukset-sivu päivitetään?",
      "Ehdotukset-sivusi lajittelee potentiaaliset yhteydet kolmeen pääkategoriaan: samanlaiset kiinnostuksen kohteet, samat deittailutavoitteet ja yhteiset yhteisöt. Lisäksi profiileja suositellaan myös aiempien yhteyksien perusteella.",
      "Miten Ehdotukset-sivuni muodostetaan?",
      "Sinulle räätälöity sivu, josta löydät sopivimmat henkilöt juuri sinulle – uusia profiileja päivittäin",
      "LÖYDÄ SAMANHENKISIÄ IHMISIÄ {{qr_code}} EHDOTUKSET-OSIOSTA",
      "Ehdotuksista löytyy henkilöitä, joista uskomme sinun pitävän. Heidät on valittu yhteisten kiinnostuksen kohteiden, yhteisöjen ja deittailutavoitteiden perusteella.",
      "Kokeile Ehdotukset-osiota",
      "ETSITKÖ UUTTA TAPAA LÖYTÄÄ SAMANHENKISIÄ TYYPPEJÄ?",
      "Ehdotukset-sivu päivitetään joka päivä, jotta näet entistä enemmän sinulle sopivia ihmisiä. Näin voit käyttää vähemmän aikaa etsimiseen ja enemmän aikaa syvien, merkityksellisten yhteyksien luomiseen samanhenkisten ihmisten kanssa.",
      "UUSIA EHDOTUKSIA PÄIVITTÄIN",
      "Ehdotukset perustuvat profiiliisi ja aiempiin yhteyksiisi, joten näet ihmisiä, joilla on samat mieltymykset ja tavoitteet kuin sinulla - olipa kyseessä vegaaninen hummerirulla tai pitkäaikainen parisuhde.",
      "RÄÄTÄLÖITY{{__EOL__}}JUURI SINULLE",
      "Lataa Bumble",
      "Usein kysytyt kysymykset",
      "Valmis selaamaan profiileja Bumblessa anonyyminä?",
      "Kun otat Incognito-tilan käyttöön, profiilisi ei näy muille Bumblen jäsenille, ellet itse tykkää heidän profiileistaan.",
      "Miten Incognito-tila toimii?",
      "Ilman Incognito-tilaa profiilisi on näkyvissä muille Bumblen käyttäjille, jos se vastaa heidän hakukriteereitään, kuten ikää ja sijaintia. Kun otat Incognito-tilan käyttöön, profiilisi näkyy vain niille henkilöille, joista itse tykkäsit ensin.",
      "Kuka voi nähdä minut Bumblen Incognito-tilassa?",
      "Incognito-tila ei ole sama asia kuin <a href={{blocking_on_bumble_url}}>henkilön estäminen Bumblessa</a>. Tämän ominaisuuden avulla henkilöt, joista tykkäät (tai ne, joille olet aiemmin lähettänyt viestiä) voivat nähdä profiilisi.",
      "Onko Incognito-tila sama asia kuin toisen estäminen?",
      "Hallitse, ketkä näkevät profiilisi Bumblen Incognito-tilalla.",
      "Incognito-tila Bumblessa",
      "Jos haluat ottaa taukoa deittailusta tai selata Bumblea ilman, että muut käyttäjät näkevät profiilisi, voit vaihtaa Incognito-tilaan. Tämän ominaisuuden käyttö piilottaa sinut kaikilta muilta – paitsi niiltä, joista itse tykkäät ensin. Incognito-tila antaa sinun hallita mitä jaat ja kuka sinut näkee.",
      "Piilota profiilisi Incognito-tilalla",
      "Sinä päätät, kuka saa nähdä kuvasi ja henkilökohtaiset tietosi.",
      "Haluatko lisätä jotain henkilökohtaista profiiliisi? Nyt voit huomaamattomasti jakaa sen nykyisille yhteyksillesi.",
      "Selaile vapaasti ilman huolta siitä, että törmäät exääsi, työkaveriisi tai kehenkään muuhun, jota et toivo treffailevasi.",
      "Päätä itse",
      "Valitse, mitä haluat jakaa",
      "Vältä kiusallisia kohtaamisia",
      "Tämän vuoksi Incognito-tila kannattaa",
      "Incognito-tila on saatavilla {{service_level_lexeme_name}}- ja {{service_level_lexeme_name_two}}-jäsenille. Tehtyäsi tilauksen voit ottaa sen käyttöön siirtymällä profiili-välilehdelle, napauttamalla oikealla yläkulmassa olevaa Asetukset-kohtaa ja vaihtamalla Incognito-tilaan.",
      "Päivitä {{service_level_lexeme_name}}-palveluun",
      "Näin käytät Incognito-tilaa Bumblessa",
      "LATAA Bumble JA KOKEILE LUO YHTEYS HETI -OMINAISUUTTA",
      "Napauta profiilikuvaketta Bumble-sovelluksen alareunassa. Sieltä löydät QR-koodikuvakkeen näytön oikeasta yläkulmasta. Napauta sitä avataksesi sen.",
      "Mistä löydän henkilökohtaisen QR-koodini?",
      "Et voi. Sinun täytyy olla wifissä ja käyttää live-QR-koodia, jotta Luo yhteys heti -ominaisuus toimii.",
      "Voinko ottaa kuvakaappauksen QR-koodistani ja jakaa sen?",
      "Ei tarvitse. Jos toinen skannaa koodin, yhteys luodaan saman tien.",
      "Tarvitseeko molempien skannata QR-koodi?",
      "Luo yhteys heti -ominaisuuden avulla näet suoraan mitä yhteistä teillä on ja saat selville, kuinka hyvin sovitte yhteen.",
      "LÖYDÄ YHTEINEN SÄVEL – NOPEAMMIN",
      "Luistiko juttu jonkun kanssa Bumblen IRL-tapahtumassa? Jatka samaan malliin sovelluksessa!",
      "JATKA TUTUSTUMISTA ENSIKOHTAAMISEN JÄLKEEN {{qr_code}} LUO YHTEYS HETI -OMINAISUUDELLA",
      "Luo yhteys heti -ominaisuus tekee juttelun jatkamisesta helppoa ilman puhelinnumeroiden tai someprofiilien vaihtamista. Anna hänen vain skannata QR-koodisi, niin saatte yhteyden saman tien Bumblessa.",
      "Kokeile Luo yhteys heti -ominaisuutta",
      "TAPASITKO JONKUN LIVENÄ Bumblen IRL-TAPAHTUMASSA?",
      "Sen sijaan, että alkaisitte seurata toisianne somessa tai vaihtaisitte puhelinnumeroita, Luo yhteys heti -ominaisuuden avulla voitte ensin tutustua toisiinne rauhassa sovelluksessa. Näin voitte edetä omilla ehdoillanne Bumblen kattavien turvaominaisuuksien ansiosta.",
      "TURVALLISEMPI TAPA LUODA YHTEYKSIÄ",
      "Tavattuanne Bumblen IRL-tapahtumassa voitte katsoa, miten kemianne kehittyvät ja jatkaa keskustelua sovelluksessa yhteisten kiinnostuksen kohteiden parissa – olipa kyseessä rakkaus laadukkaaseen kahviin tai harvinaisiin viherkasveihin.",
      "PIDÄ KESKUSTELU KÄYNNISSÄ",
      "Kokeile Opening Movesia Bumblessa",
      "Opening Moves on toinen tapa keskustella tykkäämiesi henkilöiden kanssa. Kun olet valinnut haluamasi viestin, voit vain ottaa rennosti, odottaa vastauksia ja vastata niihin, jotka todella erottuvat joukosta.",
      "Miten Bumblen Opening Movesia käytetään?",
      "Ei-binäärisissä ja saman sukupuolen välisissä yhteyksissä kumpi tahansa voi asettaa ja vastata Opening Moveen.",
      "Voinko käyttää Bumblen Opening Movesia deittaillessani samaa sukupuolta olevia henkilöitä?",
      "Kun henkilö vastaa Opening Moveesi, hänen profiilinsa ilmestyy keskusteluihisi. Naisilla on 24 tuntia aikaa vastata ennen kuin yhteys vanhenee. Voit joko vastata heidän vastaukseensa tai aloittaa uuden keskustelun.",
      "Miten näen, onko joku vastannut Opening Moveeni?",
      "Opening Movesin avulla haluamme vähentää painettasi ensimmäisen siirron suhteen ja tarjota sinulle nopeamman ja helpomman tavan päästä hyviin keskusteluihin.",
      "Opening Moves Bumblessa",
      "Olemme iloisia, että kysyit. Opening Moves on uusi, helpompi tapa aloittaa keskustelu. Voit joko valita ehdotetun Opening Movesin, kuten “Mikä on kappalevalintasi, jos olet musiikkivastaavana juhlissa?”, tai kirjoittaa omasi. Jos sinulla on kysymys asetettuna, yhteytesi voivat vastata siihen, kun olette luoneet yhteyden.",
      "Mikä on Opening Move?",
      "Poistamaan paineita",
      "Jakamaan muille, kuka todella olet",
      "Aloittamaan parempia keskusteluja",
      "Opening Moves auttaa...",
      "Voit asettaa Opening Moven, joka lähetetään uusille yhteyksillesi. Siirry Opening Moves -kohtaan profiilissasi, valitse yksi valmiista kysymyksistä tai ole luova ja kirjoita omasi. Voit muuttaa Opening Moves -kysymyksiä milloin tahansa.",
      "Kokeile Opening Movesia",
      "Miten Bumblen Opening Movesia käytetään?",
      "<a href={{see_more_url}}>Näytä lisää</a>",
      "Haluatko pitää taukoa? Kokeile Poissaolotilaa",
      "Kyllä. Voit mennä asetuksiisi tai jonoosi ja ottaa Poissaolotilan pois päältä milloin tahansa.",
      "Voinko ottaa Poissaolotilan pois käytöstä aikaisemmin?",
      "Siirry asetuksiin, napauta Poissaolotilaa ja valitse, kuinka kauan haluat olla poissa. Palaa milloin tahansa, kun olet valmis.",
      "Miten käytän Poissaolotilaa profiilissani?",
      "Et. Poissaolotilassa pyyhkäisyt ja keskustelut ovat tauolla. Kun menet selailemaan uusia profiileja, näet viestin, jossa lukee, kuinka kauan Poissaolotila on käytössä, sekä mahdollisuuden poistaa sen käytöstä.",
      "Voinko silti selailla profiileja ja keskustella Poissaolotilassa?",
      "Henkilöt, joiden kanssa olet jo lähetellyt viestejä, näkevät \"Poissa\"-tilapäivityksesi (jos olet asettanut sen). Muussa tapauksessa he näkevät edelliset viestisi keskustelussa normaalisti.",
      "Mitä nykyiset yhteyteni näkevät, kun olen Poissaolotilassa?",
      "Sinulla on täysi kontrolli aktiivisuudestasi Bumblessa, ja voit ottaa taukoa sovelluksesta milloin tahansa.",
      "Voit pitää jo olemassa olevat yhteytesi ajantasalla tilapäivityksen avulla, jotta he eivät luule sinun <a href={{ghosted_article_url}}>ghostanneen</a> heitä. Lisäksi päivitys on automaattinen, joten sinun ei tarvitse itse lähettää viestejä.",
      "Pidä pieni tauko deittailusta ilman, että menetät yhteyksiäsi tai keskustelujasi.",
      "Deittaa (tai ole deittailematta) omilla ehdoillasi",
      "Pidä yhteytesi ajantasalla",
      "Laita toimintasi tauolle, mutta pidä yhteytesi",
      "Poissaolotilan parhaat puolet",
      "Avaa Bumble-sovellus, siirry profiilivälilehdelle ja napauta oikean yläkulman asetusratasta. Etsi Poissaolotila ja valitse sieltä, kuinka pitkään haluat olla poissa. Voit piilottaa profiilisi 24 tunniksi, 72 tunniksi, viikoksi tai määrittelemättömäksi ajaksi.{{__EOL__}}{{__EOL__}}Muista, että yhteydet ilman aktiivisia keskusteluja vanhenevat ollessasi Poissaolotilassa. Jos päätät asettaa syyn poissaololle, nykyiset keskustelukumppanisi näkevät, että olet poissa.",
      "Näin Poissaolotila toimii Bumblessa",
      "Kun elämä käy kiireiseksi, Poissaolotila on apunasi. Laita Bumble-tilisi tauolle ja deittaa omilla ehdoillasi.",
      "Poissaolotila Bumblessa",
      "Poissaolotila on kätevä tapa laittaa tilisi tauolle, kun haluat pitää taukoa deittailusta. Voit silti keskustella yhteyksiesi kanssa, mutta profiilisi pysyy piilossa muilta jäseniltä siihen saakka, kunnes olet valmis palaamaan takaisin deittailun pariin. Poissaolotilan käyttöönotto ei poista tietoja profiilistasi tai jo olemassa olevia yhteyksiäsi.",
      "Aseta Bumble-profiilisi tauolle",
      "Poissaolotilassa voit halutessasi ilmoittaa nykyisille yhteyksillesi tilapäivityksellä, että pidät taukoa. Valitse sopiva seuraavista: \"Olen matkoilla\", \"Keskityn työntekoon\", \"Olen digipaastolla\" tai \"Otan aikaa itselleni\". Mikä fiiliksesi onkin, me olemme tukenasi.",
      "Lisää \"Poissa\"-tila",
      "Poissaolotilan aktivointi asettaa Bumble-tilisi (tai {{page_partner_name_bff}} -tilisi) tauolle. Yhteytesi eivät tiedä, että olet Poissaolotilassa, ellet kerro heille. Jos päätät palata Poissaolotilasta aikaisemmin kuin mitä alunperin valitsit, mene vain asetuksiin ja napauta \"Poista Poissaolotila käytöstä\". Helppoa ja nopeaa!",
      "Laita keskustelut tauolle",
      "Kokeile SuperSwipeä Bumblessa",
      "Niin monta kuin haluat! {{service_level_lexeme_name}}-käyttäjät saavat {{num}} [[superswipe:num@1]] joka viikko, mutta voit ostaa niitä lisää milloin tahansa. Ilmaiskäyttäjät voivat ostaa SuperSwipe-paketteja.",
      "Kuinka monta SuperSwipeä voin lähettää Bumblessa?",
      "Kun joku on antanut sinulle SuperSwipen, saat ilmoituksen samantien. Näet myös henkilön profiilissa nimen päällä merkinnän, joka kertoo, että hän tykkää sinusta.",
      "Miten tiedän, että olen saanut SuperSwipen joltakin?",
      "Tunnetko vahvaa vetoa johonkuhun? Nyt on helppo tapa ilmaista se. Osoita kiinnostuksesi lähettämällä SuperSwipe ja pääse nopeammin keskustelun alkuun.",
      "SuperSwipe Bumblessa",
      "Massasta erottuminen ei ole aina helppoa, mutta SuperSwipen avulla voit tehdä erinomaisen ensivaikutelman.{{__EOL__}}{{__EOL__}}Kun lähetät SuperSwipen, tykkäämäsi henkilö saa siitä ilmoituksen. Profiilisi näkyy hänelle selkeästi merkittynä ja siirtyy jonon kärkeen. Jos hän tykkää sinusta takaisin, voitte aloittaa keskustelun.",
      "Mikä on SuperSwipe?",
      "Olla rohkea ja erottua joukosta",
      "Aloittaa enemmän keskusteluja",
      "Saada useampia yhteyksiä",
      "SuperSwipen avulla voit...",
      "Jos sinulla on <a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a> tai Bumble {{service_level_lexeme_name_one}}, saat {{num}} [[superswipe:num@1]] viikossa ilmaiseksi. Lisäksi kaikki jäsenet voivat profiili-välilehdeltä ostaa paketteja, jotka sisältävät jopa {{num_two}} [[superswipe:num_two@1]]. Jos sinulla on {{service_level_lexeme_name_three}}, saat {{num_three}} [[superswipe:num_three@1]] viikossa.{{__EOL__}}{{__EOL__}}SuperSwipen käyttäminen on helppoa – kun näet mieluisan tyypin, napauta vain keltaista tähteä profiilin alaosassa.",
      "Näin käytät SuperSwipeä",
      "Apple ja Apple-logo ovat Apple Inc:n tavaramerkkejä. Google Play on Google LLC:n tavaramerkki.",
      "Deittaile reissun päällä Bumblen Matkatilassa",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}}-jäsenet</a> voivat käyttää Matkatilaa. Laita se päälle, niin jonoosi ilmestyy uusia kasvoja valitsemassasi kaupungissa.",
      "Miten käytän Matkatilaa?",
      "Melko lailla – meillä on joitain turvallisuusrajoituksia, jotka voivat muuttua ajan myötä, mutta Matkatila on saatavilla useimmille kohteille.",
      "Voinko käyttää Matkatilaa missä tahansa?",
      "Matkatilassa sijaintisi asetetaan valitsemasi kaupungin keskustaan, jotta uudet yhteydet alueella voivat löytää sinut. He näkevät Matkatila-merkinnän profiilissasi, joten he tietävät, että olet käymässä tai suunnittelet vierailua.",
      "Pakkaamassa laukkuja lomaa varten vai onko tiedossa työreissu uuteen kaupunkiin? Ota käyttöön Bumblen Matkatila ja pidä deittimahdollisuutesi avoinna, minne menetkin.",
      "Matkatila Bumblessa",
      "Matkatila on paras apuvälineesi yhteyksien luomiseen matkoillasi. Aseta sijaintisi minne tahansa päin maailmaa, ja profiilisi näytetään valitsemassasi kaupungissa. Sen avulla voit tutkiskella deittimahdollisuuksia minne tahansa seikkailusi sinut vievätkään.",
      "Mikä on Matkatila?",
      "Kukaan ei tunne paikkaa paremmin kuin paikalliset. Matkatilan avulla voit löytää ihmisiä, jotka tekevät matkastasi erityisen.",
      "Aseta sijaintisi kaupunkiin jopa seitsemän päivää ennen lähtöäsi, jotta voit suunnitella treffejä ja tutustua yhteyksiin jo etukäteen.",
      "Ajattele rantalomia pidemmälle – olipa kyseessä sitten loma kotosalla, työreissu tai uuteen kaupunkiin tutustuminen, romantiikkaa voit löytää mistä tahansa.",
      "Löydä parhaat paikalliset treffipaikat",
      "Nauti seikkailusta",
      "Luo pysyviä yhteyksiä",
      "Tämän vuoksi Matkatila kannattaa",
      "Matkatila on saatavilla <a href={{bumble_premium_url}}>{{service_level_lexeme_name}}- ja {{service_level_lexeme_name_two}}</a>-jäsenille Bumblessa. Napauta asetusratasta oikealta ylhäältä profiilistasi ja vieritä alas, kunnes näet Matkatilan. Valitse uusi sijaintisi aloittaaksesi profiilien selailun sillä alueella. Profiilisi ilmoittaa potentiaalisille yhteyksille, että olet Matkatilassa.",
      "Miten Matkatila toimii?",
      "Kokeile nyt",
      "Kokeile Bumblea verkossa",
      "Ehdottomasti. Bumble-verkkosovellus toimii PC:llä, Macilla ja tableteilla kaikilla yleisillä verkkoselaimilla.",
      "Voinko käyttää Bumblea PC:llä tai Macilla?",
      "Kyllä! Voit lähettää viestejä tai soittaa video- tai äänipuheluja Bumble-verkkosovelluksessa.",
      "Voinko keskustella yhteyksien kanssa verkkosovelluksessa?",
      "Kirjaudu sisään valitsemalla \"Kirjaudu sisään\" – tai napsauttamalla <a href={{sign_in_url}}>tästä</a>. Voit kirjautua sisään käyttämällä Apple ID:tä, Facebookia tai puhelinnumeroa.",
      "Miten kirjaudun sisään Bumbleen verkossa?",
      "Tarvitsetko taukoa puhelimen näytöstä? Kokeile Bumblen verkkosovellusta – sama rakastamasi Bumble-kokemus, nyt suuremmalla näytöllä.",
      "Käytä Bumblea verkossa",
      "Kirjautuminen Bumbleen verkossa on helppoa. Mene verkkosivuillemme, napsauta \"Kirjaudu sisään\" ja valitse tavallinen kirjautumistapasi joko Apple ID:llä, Facebookilla tai puhelinnumerolla. Voilá! Olet sisällä ja keskustelusi sekä yhteytesi ovat juuri siellä, mihin ne jäivät.",
      "Kirjaudu",
      "Kirjaudu selaimellasi",
      "Muokkaa tai muuta profiiliasi ja käytä Tarkennettua hakua.",
      "Vahvista profiilisi, jotta löydät todennäköisemmin parhaan mahdollisen yhteyden.",
      "Jaa uusia söpöjä otoksia tai poista vanhempia.",
      "Päivitä profiiliasi",
      "Näytä muille, että olet aito oma itsesi",
      "Lisää tai poista kuvia",
      "Mitä voit tehdä Bumblen verkkoversiossa",
      "Löydät keskustelusi ja yhteysluettelosi vasemmalta ja uudet mahdolliset yhteytesi oikealta.{{__EOL__}}{{__EOL__}}Käytä vasenta ja oikeaa nuolinäpäintä tai klikkaa näytön oikealla puolella olevia symboleita, jotta voit tarkastella profiileja ja tykätä ihmisistä aivan kuten puhelimellasi.",
      "Miten käytän Bumblea verkossa?",
      "Täällä ystävällisyys ja aitous ovat etusijalla, ja naiset otetaan huomioon.",
      "Ensimmäisen siirron tekeminen sai alkunsa täältä",
      "Hyvät ihmissuhteet ovat keskeinen osa elämää. Bumble on sosiaalinen verkosto, jonka kautta voit luoda arvokkaita ihmissuhteita seurustelu-, kaveri- tai uramielessä. Ensimmäinen siirto Bumblessa voi muuttaa koko elämäsi.",
      "Miksi Bumble on merkittävä",
      "Rehellisyys, kiltteys, tasa-arvo, itsevarmuus ja kunnioitus ovat suhteen perusta. Me juhlistamme kaikkia suuntaumuksia – olitpa sitten etsimässä rakkautta tai pitämässä hauskaa.",
      "Bumble-arvot",
      "Bumblessa naiset tekevät ensimmäisen siirron tai asettavat Opening Moven muiden vastattavaksi. Vanhanaikaiset suhdedynamiikat joutavat meillä romukoppaan tasa-arvon tieltä.",
      "Miten Bumble toimii",
      "Tietoa meistä",
      "Lataa nyt",
      "Sovelluksessa sujuu paremmin.",
      "Ominaisuutemme tekevät nettideittailusta oikeasti kivaa",
      "12. helmikuuta 2024",
      "Näin voit lisätä emotionaalista läheisyyttä suhteessasi",
      "25. tammikuuta 2024",
      "Ovatko deittisovellukset vieraita? Lue Bumble-vinkkimme",
      "5. helmikuuta 2024",
      "Deception Detector™",
      "Nämä deittiuutiset sopisivat lehteenkin",
      "Selaa <b>Sinulle</b>-osiota saadaksesi juuri sinulle sopivia suosituksia.",
      "Sinulle sopivat tyypit",
      "<b>Poissaolotilalla</b> voit pitää taukoa deittailusta. Kun olet taas valmis deittailemaan, tiedät mistä meidät löytää.",
      "Pidä tauko",
      "<b>Videopuheluilla</b> ja <b>äänipuheluilla</b> pääset lähemmäs aitoa yhteyttä kotisi rauhasta.",
      "Sano se ääneen",
      "Katso lisää",
      "<b>Opening Movesin</b> avulla yhteytesi voi aloittaa keskustelun, jotta sinun ei tarvitse.",
      "Vaivatonta deittailua",
      "<b>Matkatilassa</b> löydät verottomien herkkujen lisäksi yhteyksiä maailmalta.",
      "Reissun päälle",
      "Tekikö profiili vaikutuksen? Kerro se <b>SuperSwipellä</b>.",
      "Herätä huomiota",
      "Erotu joukosta lähettämällä <b>Compliments</b> eli kehu.",
      "Ilahduta kehuin",
      "Piilota profiilisi pomoltasi, sukulaisiltasi tai exiltäsi <b>Incognito-tilalla</b>.",
      "Pysy piilossa",
      "Deittaile omalla tavallasi",
      "Muutamaa kuukautta myöhemmin hän tapasi yhden morsiusneidoistaankin sovelluksessa",
      "Hanna tapasi miehensä Bumblessa.",
      "Ennen kotinsa rakentamista Carène ja You-Liang tapasivat Bumblessa, jossa Carènen lomakuvat vetivät You-Liangia puoleensa.",
      "Carène ja You-Liang",
      "Brian ja Kaci olivat molemmat ulkomailla armeijan hommissa, mutta tapasivat Bumblessa.",
      "Brian ja Kaci",
      "Sinä voi olla seuraava onnenpekka",
      "Tee seuraava siirto",
      "Katso, mitä uutta tarjoamme",
      "Ansaitset parempaa, joten olemme suunnitelleet ominaisuuksia, joiden avulla deittailet enemmän ja stressaat vähemmän.",
      "Nosta ansaitusti rimaa",
      "Tietoa Bumblesta",
      "Olemme ainoa deittisovellus, jossa naisten kokemus on etusijalla. Koska naiset viihtyvät, niin viihtyvät muutkin.",
      "Make the first move™",
      "Etsi sinua miellyttävä tyyppi ja tee ensimmäinen siirto. Luo merkityksellisiä yhteyksiä, juttele harrastuksistasi ja pidä hauskaa.",
      "Tapaa se oikea Bumble Datessa",
      "Löydä kavereita tai töitä. Luo yhteys muihin ammattilaisiin löytääksesi töitä, mentorin tai kokonaan uuden uran.",
      "Tee urasiirtoja Bumble Bizzillä.",
      "Tapaa kaltaisesi. Tai joku erilainen. Ystävysty ja tee jotain uutta, olitpa sitten kotona tai maailmalla.",
      "Löydä uusia ystäviä Bumble For Friendsissä",
      "Deittailua ja paljon muutakin",
      "Bumblessa naiset tekevät ensimmäisen siirron. Sovelluksemme muutti deittailun maailmaa, ja nyt meillä luodaan merkityksellisiä suhteita ja verkostoja. Lataa nyt.",
      "Bumble {{social_network}} alustalla",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "Valitettavasti etsimääsi sivua ei löytynyt.",
      "Yhteystiedot",
      "Kirjaudu sisään",
      "No voi, jotain on vialla",
      "(opens in new window)",
      "UKK",
      "Cookie Policy",
      "Premium",
      "Bumble - The Blog",
      "Työt",
      "Chattaa",
      "Kaupunkeja",
      "Hallinnoi evästeitä",
      "Copyright © 2006 – tähän päivään. Bumble. Kaikki oikeudet pidätetään.",
      "Deittailuraportti",
      "Apple ja Apple-logo ovat Apple Inc:n tavaramerkkejä.{{__EOL__}}Google Play on Google LLC:n tavaramerkki.",
      "Lataa",
      "Facebook",
      "Seuraa meitä",
      "Asiakastuki",
      "Instagram",
      "Kieli",
      "LinkedIn",
      "Bumble-palvelun tarjoaa Bumble Inc.",
      "Tietosuoja",
      "Turvallisuuskeskus",
      "Sivustokartta",
      "Lausunto nykyajan orjuudesta",
      "Käyttöehdot",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "kehua",
                  "kehua",
                  "kehun",
                  "kehuja",
                  "kehuina",
                  "kehuiksi",
                  "kehuihin",
                  "kehuissa",
                  "kehuista",
                  "kehuille",
                  "kehuilla",
                  "kehuista",
                  "kehuitta",
                  "kehuine",
                  "kehuin"
            ],
            "singular": [
                  "kehu",
                  "kehun",
                  "kehun",
                  "kehua",
                  "kehuna",
                  "kehuksi",
                  "kehuun",
                  "kehussa",
                  "kehusta",
                  "kehulle",
                  "kehulla",
                  "kehusta",
                  "kehutta",
                  "-",
                  "-"
            ]
      },
      "day": {
            "plural": [
                  "päivää",
                  "päivää",
                  "päivän",
                  "päivää",
                  "days",
                  "days",
                  "days",
                  "days",
                  "days",
                  "days",
                  "days",
                  "days",
                  "days",
                  "days",
                  "days"
            ],
            "singular": [
                  "päivä",
                  "päivän",
                  "päivän",
                  "päivää",
                  "päivänä",
                  "päiväksi",
                  "päivään",
                  "päivässä",
                  "päivästä",
                  "päivälle",
                  "päivällä",
                  "päivältä",
                  "päivättä",
                  "päivineen",
                  "päivin"
            ]
      },
      "hour": {
            "plural": [
                  "tuntia",
                  "tuntia",
                  "tunnin",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia"
            ],
            "singular": [
                  "tunti",
                  "tunti",
                  "tunnin",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti"
            ]
      },
      "minute": {
            "plural": [
                  "minuuttia",
                  "minuuttia",
                  "minuutin",
                  "minuuttia",
                  "minutes",
                  "minutes",
                  "minutes",
                  "minutes",
                  "minutes",
                  "minutes",
                  "minutes",
                  "minutes",
                  "minutes",
                  "minutes",
                  "minutes"
            ],
            "singular": [
                  "minuutti",
                  "minuutti",
                  "minuutin",
                  "minuuttia",
                  "minute",
                  "minute",
                  "minute",
                  "minute",
                  "minute",
                  "minute",
                  "minute",
                  "minute",
                  "minute",
                  "minute",
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta"
            ],
            "singular": [
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi"
            ]
      },
      "second": {
            "plural": [
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia"
            ],
            "singular": [
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipeä",
                  "SuperSwipeä",
                  "SuperSwipen",
                  "SuperSwipeä",
                  "SuperSwipenä",
                  "SuperSwipeksi",
                  "Superswipeen",
                  "Superswipessä",
                  "Superswipestä",
                  "Superswipelle",
                  "Superswipellä",
                  "Superswipeltä",
                  "Superswipettä",
                  "Superswipeineen",
                  "Superswipein"
            ],
            "singular": [
                  "SuperSwipe",
                  "SuperSwipen",
                  "SuperSwipen",
                  "SuperSwipeä",
                  "SuperSwipenä",
                  "SuperSwipeksi",
                  "Superswipeen",
                  "Superswipessä",
                  "Superswipestä",
                  "Superswipelle",
                  "Superswipellä",
                  "Superswipeltä",
                  "Superswipettä",
                  "Superswipeineen",
                  "Superswipein"
            ]
      },
      "year": {
            "plural": [
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta"
            ],
            "singular": [
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
