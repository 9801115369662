import { AppLink } from 'lib/app/app-link';
import { FEATURE_SUPERSWIPE_AMOUNT_NUM } from 'lib/features/constants';
import Localization, { TrustedValue } from 'lib/localization';

export default {
    get lxSuperswipeHeroTitle() {
        return Localization.get('bumblesite@pages.features.superswipe.hero.title');
    },
    get lxSuperswipeHeroSubtitle() {
        return Localization.get('bumblesite@pages.features.superswipe.hero.subtitle');
    },
    get lxSuperswipeOneBlockTitle() {
        return Localization.get('bumblesite@pages.features.superswipe.one.block.title');
    },
    get lxSuperswipeOneBlockBodyText() {
        return Localization.get('bumblesite@pages.features.superswipe.one.block.body.text', {
            __EOL__: TrustedValue.for('<br/>'),
        });
    },
    get lxFeaturesDownloadBumbleCta() {
        return Localization.get('bumblesite@pages.features.download.bumble.cta');
    },
    get lxSuperswipeTwoBlockTitle() {
        return Localization.get('bumblesite@pages.features.superswipe.two.block.title');
    },
    get lxSuperswipeTwoBlockBodyText() {
        return Localization.get('bumblesite@pages.features.superswipe.two.block.body.text', {
            __EOL__: TrustedValue.for('<br/>'),
            bumble_boost_url: AppLink.BUMBLE_BOOST,
            service_level_lexeme_name: 'Boost',
            service_level_lexeme_name_one: Localization.get(
                'revenue_onboarding@gold.revamp.premium',
            ),
            service_level_lexeme_name_three: Localization.get('bumble_boost@gold.name'),
            num: 5,
            num_two: 30,
            num_three: 10,
        });
    },
    get lxFeaturesTryItNowCta() {
        return Localization.get('bumblesite@pages.features.try.it.now.cta');
    },
    get lxSuperswipeThreeBlockTitle() {
        return Localization.get('bumblesite@pages.features.superswipe.three.block.title');
    },
    get lxSuperswipeThreeBlockPointOne() {
        return Localization.get('bumblesite@pages.features.superswipe.three.block.point.one');
    },
    get lxSuperswipeThreeBlockPointTwo() {
        return Localization.get('bumblesite@pages.features.superswipe.three.block.point.two');
    },
    get lxSuperswipeThreeBlockPointThree() {
        return Localization.get('bumblesite@pages.features.superswipe.three.block.point.three');
    },
    get lxFeaturesFaqTitle() {
        return Localization.get('bumblesite@pages.features.faq.title');
    },
    get lxSuperswipeFaqQuestionOneTitle() {
        return Localization.get('bumblesite@pages.features.superswipe.faq.question.one.title');
    },
    get lxSuperswipeFaqQuestionOneAnswer() {
        return Localization.get('bumblesite@pages.features.superswipe.faq.question.one.answer', {
            service_level_lexeme_name: Localization.get('bumble_boost@gold.name'),
            num: FEATURE_SUPERSWIPE_AMOUNT_NUM,
        });
    },
    get lxSuperswipeFaqQuestionTwoTitle() {
        return Localization.get('bumblesite@pages.features.superswipe.faq.question.two.title');
    },
    get lxSuperswipeFaqQuestionTwoAnswer() {
        return Localization.get('bumblesite@pages.features.superswipe.faq.question.two.answer');
    },
    get lxSuperswipeCta() {
        return Localization.get('bumblesite@pages.features.superswipe.cta');
    },
};
