/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "戻る",
      "App Storeでダウンロード",
      "Google Playで入手",
      "スライドショー",
      "スライド{{number}}",
      "次へ",
      "前へ",
      "スライド",
      "スライド{{total}}枚中{{number}}枚目",
      "個人情報の販売・共有を許可しない",
      "個人情報を販売または共有しない",
      "BumbleでComplimentsを送って、お相手のプロフィールを好意をもったことを伝え、マッチング前にお相手の注目を集めましょう。今すぐお試しください。",
      "Compliments | Complimentsの送り方 | Bumble",
      "相性抜群のお相手を紹介する、あなた用にカスタマイズしたページです。毎日更新します。",
      "見つける | 相性抜群のお相手を見つけよう | Bumble",
      "プライベートモードを使って、あなたのBumbleのプロフィールを誰が閲覧しているかを管理して、自分のペースで出会おう。Bumbleのプライベートモードの使用方法については、こちらのガイドをご覧ください。",
      "プライベートモード | プロフィールを隠す｜Bumble",
      "Bumbleの対面イベントで出会った後は、アプリで会話を続けましょう。",
      "瞬間マッチ | アプリで会話を続けよう | Bumble",
      "BumbleのOpening Moves機能を活用して、新しい会話の始め方を試してみよう。お相手の目を引き、調子を合わせえ、心地良い会話をすばやく始めましょう。",
      "BumbleのOpening Moves | Bumble",
      "休息や自分のための時間が必要ですか？BumbleのスヌーズモードでBumbleのアカウントを一時停止機能をご活用ください。自分のタイミングで出会いましょう。",
      "スヌーズモード | アカウントの一時停止 | Bumble",
      "Bumbleで、本当につながりたいお相手を見つけましたか？ SuperSwipeで、あなたの真剣度を伝えましょう。お相手のプロフィールにある星マークをタップするか、ここで詳細をご確認ください。",
      "Bumble SuperSwipe | SuperSwipeとは？ | Bumble",
      "出張ですか？それとも個人的な旅行ですか？Bumbleのトラベルモードなら、どこを訪れた際にも、新しい場所で、新しい出会いとつながりを楽しみながら探すことができます。ぜひトラベルモードをお試しください。",
      "Bumbleのトラベルモード | どこに居ても出会える | Bumble",
      "メッセージを通じて感じるつながりが、実際に会っても相性の良さを意味しているかどうか、知りたいですか？今すぐBumbleのビデオ通話や音声通話をお試しください。バイブスの相性を確かめられます。",
      "ビデオ通話｜電話をかけよう｜Bumble",
      "Bumbleウェブで究極の機能を体験しよう。",
      "Bumbleウェブ | Bumble",
      "契約のキャンセル",
      "Bumbleホーム画面",
      "メニューを閉じる",
      "メニューを開く",
      "言語選択",
      "フッター",
      "Bumbleのアクセシビリティ",
      "AppleとAppleロゴは、Apple Inc.の商標です。",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "採用情報",
      "イベント",
      "Google Pay は Google LLC の商標です。",
      "ガイドライン",
      "株主・投資家の方へ",
      "Cookieの管理",
      "Cookieの管理",
      "現代奴隷法に関する声明",
      "回収時の注意事項",
      "プライバシーポリシー",
      "季節ごとのデートガイド",
      "利用規約",
      "アプリ",
      "ワシントン州消費者健康データ・プライバシーポリシー",
      "概要",
      "アンバサダー",
      "ブログ",
      "友達をつくってコミュニティを探そう",
      "プロフェッショナルなネットワークを構築し、キャリアアップを目指そう",
      "真剣な交際相手、カジュアルな関係、その中間など様々な出会いを探そう",
      "詳しくみる",
      "トラベルモードでグローバルに",
      "機能",
      "Compliments",
      "あなたのプロフィールが際立つように、お相手にComplimentsを贈ってみましょう。",
      "見つける",
      "相性抜群のお相手を紹介する、あなた用にカスタマイズされた毎日更新のページ",
      "プライベートモード",
      "プライベートモードで上司、親戚、元恋人からプロフィールを隠せます。",
      "瞬間マッチ",
      "Bumbleの対面イベントをきっかけに会話が始まりましたか？アプリ上で会話を続けませんか。",
      "Opening Moves",
      "Opening Movesを選択して、マッチング相手からチャットを開始してもらうようにしましょう。そうすれば、あなたが開始する必要はありません。",
      "スヌーズモード",
      "スヌーズモードで出会いを一時休止できます。再び出会う準備ができた際にはすぐに再開できます。",
      "SuperSwipe",
      "バイブスに響くプロフィールがありましたか？SuperSwipeでそのことを伝えよう",
      "トラベルモード",
      "トラベルモードで、訪れた場所でお相手を見つけ、世界中どこででもマッチしましょう。",
      "ビデオ通話",
      "ビデオチャットや音声通話を利用して、対面で会う心の準備が整っているかを確認してみましょう。",
      "ウェブアプリ",
      "ブラウザでBumble体験をすぐに始めましょう。",
      "ショップ",
      "メイン",
      "スキップして本文へ",
      "Bumbleの商標",
      "お相手のことをもっと知りたい時は、Bumbleのビデオ通話を試そう",
      "個人情報を一切共有することなく、チャット内でビデオアイコンをタップするだけでビデオ通話を開始できます。",
      "Bumbleのビデオ通話はどのように機能しますか？",
      "いいえ。Bumble上での電話とチャットはすべて、ユーザーの個人情報を安全に保護します。ご自身で伝えるまで、お相手があなたの電話番号やその他の連絡先情報を取得することはありません。",
      "マッチング相手は私の電話番号を見られますか？",
      "もちろんです！顔を見て会話をする心の準備ができていない場合は、音声通話で話せます。その場合は、通話アイコンを選択します。",
      "音声通話だけ試すことはできますか？",
      "「メッセージを受信しました」通知は嬉しいものです。でも、チャットが対面での会話より楽しいかどうかはわかりませんよね。そこで、ビデオ通話と音声通話の出番です。",
      "Bumbleのビデオ通話",
      "<a href={{video_chat_url}}>ビデオ通話や音声通話</a>でお互いのことをよく知ることは、アプリ外で会うかどうかを決定するのに役立ちます。一番の利点は、Bumble上で通話すると電話番号を交換する必要がないため、個人情報を共有する心の準備が整う前でも、安全を維持できることです。",
      "ビデオ通話で相性を確認しよう",
      "オンラインとオフラインの出会いのギャップを埋めて、対面で会いたいと思うタイミングが訪れたかどうかを判断しましょう。",
      "お相手のことをより深く（そしてより早く）知りましょう。顔を見て話したり、電話で会話したりすることでお相手についてたくさんのことを知ることができます。",
      "連絡先は非公開の個人情報です。あなた自身が共有するタイミングを選び、コントロールします。",
      "対面で会う心の準備ができているかを確認しよう",
      "バイブスをすばやく確認する方法",
      "個人情報を明かすことなく関係を進展",
      "ビデオ通話と音声通話がおすすめな理由",
      "会話を始める心の準備はできましたか？Bumbleアプリの[チャット]から、音声通話かビデオ通話のどちらを開始するかを選択します。ビデオ通話を開始するには、右上の灰色のビデオカメラ型のアイコンをタップします。アイコンの隣にある三つの点をクリックすると、音声通話オプションも表示されます。",
      "ビデオ通話をする方法",
      "Complimentsをお試しください！",
      "Complimentを贈った場合、受信したお相手は、待機列にあなたのプロフィールが表示された際に、そのComplimentを確認できます。",
      "Bumbleで誰かにComplimentを贈るとどうなりますか？",
      "もしお相手のComplimentが目に留まったら、返答してマッチングしてみましょう。チャット画面にComplimentが表示され、通常通り<a href={{make_the_first_move_url}}>「自分から行動を起こす」</a>ルールが適用されます。",
      "Complimentに返答する方法は？",
      "[[Compliments:num]]は{{num}}回まで無料で送信できます。すべて使い切ってしまった場合は、Bumbleのアプリから30回分までセットで購入でき、Complimentを送信する度に1回分ずつ使用します（残りは気に入った別のお相手に使えるように保存されます）。有効期限はありませんので、ご心配なく！",
      "Complimentsは何回まで贈ることができますか？",
      "優しい一言（や二言）は、大きな効果をもたらします。Complimentを贈りたい気持ちはあるのに、言葉が浮かばない場合は、次のような例からインスピレーションを高めてみてください。",
      "他の例を見る",
      "「（アーティスト名）を高く評価されている方は、センスが良いと思います。」",
      "「あなたの笑顔は伝染します！」",
      "「経歴を見て思わず笑ってしまいました。お見事です！」",
      "Bumbleでお相手を褒める良い方法",
      "お相手の一日を輝かせてみませんか？Complimentsを試してみましょう。",
      "Bumble のCompliments",
      "BumbleのCompliments機能を使って、気になるお相手のプロフィールに好感を持っていることを伝えられます（お相手が喜ぶかもしれません！）。Compliments機能は、{{service_level_lexeme_name}}の会員特典としてご利用いただけます。マッチング前にメッセージを送受信できる方法としてもご活用ください！",
      "Compliments機能の仕組み",
      "Complimentを受信した場合、そのComplimentは送信元のお相手のプロフィールに表示されます。また、プロフィールに好感を持ったお相手がいるという通知を受け取ります。",
      "コンプリメントを見る",
      "お相手のプロフィールページの下部にある黄色いハートのアイコンをタップすると、Complimentを送ることができます。特定の写真やプロフィールページの一部に対してコンプリメントを贈ることになるため、タップする場所が重要です！クリックすると、メッセージを入力するためのテキストボックスが開きます（最大150文字まで入力できます）。",
      "コンプリメントを送信",
      "Bumbleをダウンロードして、今すぐ[見つける]を試そう",
      "毎日午前9時に、[見つける]にあなたと共通点を持つ新しいお相手が表示されます。共通点を通じて、お相手とつながることができます。",
      "[見つける]はいつ更新されますか？",
      "[見つける]ページでは、潜在的なマッチング相手を3つのカテゴリーで分類しています。興味が似ている、出会いの目的が同じ、共通のコミュニティの3つです。また、過去のマッチングやつながりを基にしたプロフィールのおすすめも表示されます。",
      "[見つける]ページはどのようにキューレーションされていますか？",
      "相性抜群のお相手を紹介する、あなた用にカスタマイズされた毎日更新のページ",
      "バイブスに合うお相手を{{qr_code}}[見つける]で探そう",
      "[見つける]では、あなたの興味や出会いの目的、コミュニティなどの類似性に基づいて、あなたと共通点のあるお相手を紹介しています。",
      "[見つける]を試す",
      "共通点のあるお相手を見つける新しい方法を探していますか？",
      "[見つける]ページは毎日更新され、より多くの相性の良いお相手を紹介します。そのため、マッチング相手を探す時間を減らし、共通点を持つお相手とつながりを深めやすくなります。",
      "毎日更新される、おすすめのお相手",
      "[見つける]でおすすめするお相手は、プロフィール内容と過去のマッチング相手に基づいて選出しているため、例えば、ヴィーガンのお寿司を好むお相手や長期的な関係を求めているお相手など好きなものや求めているものが共通するお相手を見ることができます。",
      "あなたを中心に{{__EOL__}}パーソナライズ",
      "Bumbleをダウンロード",
      "よくある質問",
      "情報を公開せずにBumbleでお相手を探せる方法です",
      "プライベートモードを有効にすると、あなたがいいね!をしたお相手にのみ、あなたのプロフィールが表示されるようになります。",
      "プライベートモードはどのように機能しますか？",
      "プライベートモードが無効の場合、年齢や居住地などの条件が一致すると、あなたのプロフィールが他のBumbleのユーザーに表示されます。プライベートモードを有効にすると、あなたがいいね!をしたお相手にのみ表示されるようになります。",
      "Bumbleでプライベートモードを使用した場合、誰が私のプロフィールを見ることができますか？",
      "プライベートモードを使用しても、<a href={{blocking_on_bumble_url}}>Bumble上で誰かをブロックすることにはなりません</a>。この機能では、あなたがいいね!したお相手（または過去にメッセージを送ったことがあるお相手）はあなたのプロフィールを見ることができます。",
      "プライベートモードは、誰かをブロックするような機能ですか？",
      "Bumbleのプライベートモードで、あなたのプロフィールが表示されるお相手をコントロールしましょう",
      "プライベートモード",
      "新しい出会いからは少し距離を置きつつ、他のユーザーにあなたのプロフィールを見られることなくBumbleを閲覧したい場合は、プライベートモードに切り替えることができます。この機能を有効にすると、あなたが誰かをいいね!しない限り、あなたのプロフィールは非公開になります。これにより、あなた自身で共有する内容と公開するお相手をコントロールできます。",
      "プライベートモードでプロフィールを非表示",
      "あなた自身があなたの写真や個人情報を見られるユーザーを決めます。",
      "プロフィールに個人的な内容を記載したい場合、現在のマッチング相手だけにこっそり共有できます。",
      "元恋人や職場の同僚など、交際対象ではない人に遭遇する心配をせずに、自由に閲覧できます。",
      "自分でコントロールしよう",
      "共有する内容を厳選",
      "気まずい出会いを避けよう",
      "プライベートモードが愛用される理由",
      "プライベートモードは、{{service_level_lexeme_name}}や{{service_level_lexeme_name_two}}のメンバーが利用できる機能です。サブスクリプション登録後、プロフィールタブに移動し、右上の [設定] をタップしてプライベートモードに切り替えることができます。",
      "{{service_level_lexeme_name}}にアップグレード",
      "Bumbleのプライベートモードの使い方",
      "Bumbleをダウンロードして、今すぐ瞬間マッチを体験しよう",
      "Bumbleアプリで、画面下部のプロフィールアイコンに移動します。そこから、画面右上にQRコードのアイコンをタップしてアクセスします。",
      "自分のQRコードはどこで見つけられますか？",
      "いいえ！瞬間マッチ機能を使用するには、Wi-Fiへ接続して、現在有効なQRコードを使用する必要があります。",
      "スクリーンショットで保存したQRコードを共有することはできますか？",
      "いいえ！どちらかがQRコードをスキャンすることで、自動的にマッチングします。",
      "双方ともQRコードをスキャンする必要がありますか？",
      "瞬間マッチ機能では、あなたと新しいマッチング相手の共通点が自動的に表示されるため、始まりの時点から相性の良さを垣間見ることができます。",
      "よりすばやく、共通点を見つけよう",
      "Bumbleの対面イベントをきっかけに会話が始まりましたか？アプリ上で会話を続けませんか。",
      "出会いを{{qr_code}}で瞬間マッチに結びつけよう",
      "瞬間マッチは、電話番号やソーシャルメディアのプロフィールを交換することなく、気軽に会話を継続できる機能です。あなたのQRコードをお相手にスキャンしてもらうだけで、Bumble上でマッチングできます。",
      "瞬間マッチを試す",
      "Bumbleの対面イベントで出会いを見つけましたか？",
      "ソーシャルメディアのプロフィールや電話番号を交換する代わりに、瞬間マッチを利用することで、まずアプリでお互いの雰囲気や詳細を確認できます。Bumbleの安全機能をフル活用しながら、お互いのペースでより深く知り合うことができます。",
      "もっと安全につながりを築く方法",
      "Bumbleの対面イベントで出会った後は、アプリで会話を続けましょう。完璧なハンドドリップコーヒーへの愛であれ、珍しい多肉植物の育成であれ、共通の趣味や関心を通じて関係を深め、相性を確かめましょう。",
      "会話を継続させよう",
      "BumbleのOpening Movesを試そう",
      "Opening Movesは気になるお相手とチャットを楽しむ、もう一つの方法です。送信するメッセージを選択したら、あとはリラックスして返答を待ち、特に目を引かれる返信があれば対応しましょう。",
      "BumbleのOpening Movesの使い方",
      "はい。ノンバイナリーや同性間のマッチングでは、どちら側もOpening Moveを設定でき、またどちら側からでも返答を送ることができます。",
      "同性間の恋愛でもBumbleでOpening Moves を活用できますか？",
      "誰かがあなたのOpening Moveに返答すると、チャットにお相手のプロフィールが表示されるようになります。マッチングが失効しないためには、女性の場合は24時間以内に返答する必要があります。その後、お相手の返答に返信するか、新しい会話を始められるようになります。",
      "Opening Movesに反応があったかどうかを確認するにはどうすればよいですか？",
      "Opening Movesは、最初の行動を起こす際のプレッシャーを軽減して、すばやく、手軽に充実した会話を始められる機能です。",
      "BumbleのOpening Moves",
      "Opening Movesは、会話のきっかけを簡単に作れる新しい方法です。「パーティーで音楽をかけるとしたら、よくかける曲は何？」など選択肢の中からOpening Moveを選ぶか、自分で新しく入力することもできます。選択しておくと、マッチング画面でマッチング相手が即座にあなたの質問に返信できます。",
      "Opening Moveはどんな機能？",
      "プレッシャーをより少なく",
      "あなたらしさを共有しよう",
      "もっと良い会話を始めよう",
      "Opening Movesの活用法",
      "マッチングした際にマッチング相手へ送信するOpening Moveを設定できます。プロフィールページの[Opening Moves]に移動し、質問の選択肢から1つ選択するか、ご自身で質問を入力することもできます。Opening Movesはいつでも変更できます。",
      "Opening Movesを試す",
      "BumbleのOpening Movesの使い方",
      "<a href={{see_more_url}}>もっと見る</a>",
      "休憩が必要な時はスヌーズモードをお試しください",
      "はい、いつでも[設定]かスワイプ画面から、スヌーズモードをオフに切り替えられます。",
      "スヌーズモードを早期に解除できますか？",
      "設定に移動し、[スヌーズ] をタップして、休止中にする期間を選択します。再開する準備が整いましたら、いつでも戻ってきてください。",
      "スヌーズモードはどのように使用しますか？",
      "いいえ。スヌーズモード中は、スワイプとチャットに関するすべてのアクティビティが一時休止になります。スワイプ画面に移動すると、スヌーズモードの継続期間が表示され、解除オプションも選択できます。",
      "スヌーズモード中でもスワイプやチャットを続けられますか？",
      "ステータス設定をしている場合、すでにメッセージを送ったお相手には、「休止中」ステータスが表示されます。設定していない場合は、通常通り受信トレイに前回までのメッセージが表示されます。",
      "スヌーズモードにすると、現在のマッチングはどうなりますか？",
      "Bumbleでの活動を自分でコントロールできるので、好きな時にプラットフォームから離れられます。",
      "ステータス更新機能を利用すると、既存のマッチングを最新の状態に保つことができて、<a href={{ghosted_article_url}}>音信不通</a>になったように見られることはありません。すべて自動化されているので、メッセージを送信する必要はありません。",
      "マッチングやチャット履歴を失うことなく、積極的な出会いから一時的に休む時間も時に大切です。",
      "出会いもマッチング解除も自分のタイミングで",
      "マッチングを常に最新の状態に保とう",
      "休憩中もマッチングは継続",
      "スヌーズモードをおすすめする理由",
      "アプリを開き、[プロフィール]タブに移動し、右上の歯車アイコンの設定をタップします。設定画面で[スヌーズ]をタップし、[24時間]、[72時間]、[1週間]、または[無期限]からプロフィールを非公開にする期間を選択します。{{__EOL__}}{{__EOL__}}最近やりとりのなかったマッチング相手とのチャットは、スヌーズモード中に期限切れとなります。ステータス変更を設定すると、現在チャット中のお相手には、あなたが休止中であることが表示されます。",
      "Bumbleのスヌーズモードの仕組み",
      "忙しいときにはスヌーズモードをご活用ください。Bumbleのアカウントを一時的に停止し、自分のタイミングで出会いましょう。",
      "Bumbleのスヌーズモード",
      "スヌーズモードは、少し休憩したい時にアカウントを一時的に休止する簡単な方法です。既にマッチングしているお相手とは引き続きチャットできますが、再びスワイプを開始する準備ができるまで、それ以外のお相手のスワイプ画面にあなたのプロフィールは表示されません。スヌーズモードを有効にしても、プロフィール情報が失われたり、既存のマッチングが削除されたりすることはありません。",
      "Bumbleでプロフィールの表示を一時的に休止する方法",
      "スヌーズモードにしている間、ステータスを更新して、既存のマッチング相手に休止中であることを知らせることができます。「旅行中」、「仕事に集中しています」、「デジタルデトックス中です」、「自分時間を優先しています」から選択してください。どんなバイブスにも対応します。",
      "「休止中」ステータスを追加しよう",
      "スヌーズモードを有効にすると、Bumble（または{{page_partner_name_bff}}）のアカウントが一時休止状態になります。お相手に通知しない限り、お相手にはあなたがスヌーズモード中であることはわかりません。もし、最初に選択した期間よりも早くスヌーズモードを解除したくなった場合は[設定]から[スヌーズモードを解除]をタップするだけで解除できます。簡単です。",
      "チャットを一時的に中断しよう",
      "BumbleのSuperSwipeを試そう",
      "お好きなだけどうぞ！{{service_level_lexeme_name}}のメンバーは毎週{{num}}回分の[[superswipe:num]]を獲得でき、いつでも追加できます。無料メンバーはSuperSwipesをセット購入できます。",
      "BumbleでSuperSwipeを何回送ることができますか？",
      "お相手があなたにSuperSwipeを送ると、すぐに通知が届きます。また、そのお相手のプロフィール画面にラベルが表示されるので、あなたに好意を寄せていることが分かります。",
      "誰かからSuperSwipeを受信した場合、通知がありますか？",
      "とても気になるお相手がいますか？ 知り合うきっかけに最適な方法があります！SuperSwipeを送ってお相手にあなたの好意を伝えることで、よりスピーディにチャットが始められます。",
      "BumbleのSuperSwipe",
      "お相手の注意を引くことは簡単ではありませんが、SuperSwipeは第一印象を良くするお手伝いをします。{{__EOL__}}{{__EOL__}}SuperSwipeを使用すると、あなたがいいね!したお相手に通知が送信されます。あなたのプロフィールにラベルが表示され、お相手に表示されるリストの一番上に移動します。お相手があなたにいいね!を返すと、チャットが開始できます。",
      "SuperSwipeはどんな機能？",
      "大胆に、注目を集めよう",
      "もっとたくさんチャットを始めよう",
      "もっとつながろう！",
      "SuperSwipeの活用方法",
      "<a href={{bumble_boost_url}}>Bumble {{service_level_lexeme_name}}</a>やBumble {{service_level_lexeme_name_one}}をご利用の場合、毎週{{num}}回分の[[superswipe:num]]を無料で取得できます。サブスクリプションを登録していない場合でも[プロフィール]タブから最大{{num_two}}回分の[[superswipe:num_two]]をセットで購入できます。{{service_level_lexeme_name_three}}をご利用の場合、毎週{{num_three}}回分の[[superswipe:num_three]]を取得できます。{{__EOL__}}{{__EOL__}}SuperSwipeの使い方は簡単です。気になるお相手を見つけたら、お相手のプロフィール画面の左下にある黄色い星アイコンをタップするだけです。",
      "SuperSwipeの使い方",
      "AppleおよびAppleのロゴはApple Inc.の商標です。{{__EOL__}}Google PlayはGoogle LLCの商標です。",
      "トラベルモードで旅先でもデートしよう",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}}\nメンバー</a>はトラベルモードを使用できます。トラベルモードをオンにすると、選択した都市にいるお相手がスワイプ画面に表示されます。",
      "トラベルモードはどうしたら使えますか？",
      "いくつかの安全上の制限は変更される可能性がありますが、トラベルモードはほとんどの目的地で利用できます。",
      "トラベルモードはどこででも使えますか？",
      "トラベルモードを使用すると、あなたの現在地は選択した都市の中心と表示されるため、その地域のお相手とマッチングすることができます。また、プロフィールにはトラベルモードの利用中であることが表示されるので、あなたが旅行中か、旅行の計画中であることがお相手に伝わります。",
      "Bumbleでトラベルモードをオンにして、旅先でも出会いを楽しんでください。",
      "Bumbleのトラベルモード",
      "トラベルモードは、旅行中につながりを築ける最高のツールです。 現在地を世界のどこかに設定すると、あなたのプロフィールは選択した都市で表示されます。 つまり、旅先がどこであろうと、その場所の出会いのチャンスへ参加できるということです。",
      "トラベルモードとは何ですか？",
      "地元の人々ほどその土地に詳しいお相手はいません。トラベルモードは、あなたの旅を特別なものにしてくれるお相手とつながるお手伝いをします。",
      "最大で出発7日前から旅行先の都市を設定できるため、出発前にマッチングして、お相手とのデートを計画できます。",
      "旅先はビーチに限りません！休暇で地元に戻ったり、仕事の出張だったり、未知の都市を散策したり。ロマンスはどこででも実現します。",
      "現地で最高のデートスポットを見つけよう",
      "冒険に出かけよう",
      "長く続く関係を築こう",
      "トラベルモードが便利な理由",
      "<a href={{bumble_premium_url}}>{{service_level_lexeme_name}}と{{service_level_lexeme_name_two}}</a>のメンバーはBumbleでトラベルモードを利用できます。[プロフィール]画面の右上にある歯車アイコンをタップし、[トラベルモード]が表示されるまでスクロールします。新しい場所を選択すると、その地域のユーザーが表示されるようになります。プロフィールにはトラベルモード中であることが表示され、お相手にも通知されます。",
      "トラベルモードとはどのような機能ですか？",
      "今すぐ試す",
      "ウェブ版のBumbleをお試しください。",
      "もちろんです。Bumbleのウェブアプリは、PC、Mac、タブレットの、すべての主要ウェブブラウザで動作します。",
      "PCやMacでBumbleを使用できますか？",
      "はい、できます。Bumbleのウェブ版では、メッセージの送信、ビデオ通話や音声通話も可能です。",
      "ウェブ版でマッチングしたお相手とチャットできますか？",
      "ログインするには、[ログイン] を選択するか、<a href={{sign_in_url}}>ここ</a>をクリックします。Apple ID、Facebook、または電話番号を使ってログインできます。",
      "ウェブでBumbleにログインするにはどうすればよいですか？",
      "携帯電話のスクリーンから距離をおきたい時は、ウェブ版のBumbleをお試しください。おなじみのBumbleを、そのままより大きな画面で体験できます。",
      "Bumbleのウェブでのご利用について",
      "ウェブ版のBumbleへのログインは簡単です。Bumbleのウェブサイトへアクセスし、[ログイン] をクリックし、Apple ID、Facebookの詳細、または電話番号を使用するなど、いつものログイン方法を選択するだけで、完了です！チャットやマッチングの記録は引き継がれます。",
      "ログイン",
      "ブラウザでログイン",
      "プロフィールを編集や変更し、アドバンスフィルターを適用します。",
      "本人確認を行うとマッチングの確率を高めることができます。",
      "キュートな写真を新しく共有したり、古い写真を削除したりします。",
      "プロフィールの更新",
      "本人確認",
      "写真の追加や削除",
      "ウェブ版のBumbleでできること",
      "左側にチャットとマッチングリスト、右側に新しいマッチング候補が表示されます。{{__EOL__}}{{__EOL__}}左と右の矢印キーを使用するか、画面の右側にある印をクリックしてプロフィールを確認し、スマートフォンで行うようにいいね!を押します。",
      "ウェブ版のBumbleはどのように利用しますか？",
      "ここでは、優しいことがクールで、あなたらしくいることが大切で、女性が最優先です。「常に」です。",
      "自分から行動を起こす場所",
      "健全な人間関係は、ポジティブで生産的な人生を送るために重要です。 Bumbleは、恋愛相手や友達探しでも、仕事上のネットワーク開拓でも、つながりを築きながらエンパワーされるようにデザインされています。",
      "Bumbleが配慮する理由",
      "私たちは、誠実さ、優しさ、平等さ、自信、そして人間関係のすべての段階における尊重を大切にしています。恋愛相手を見つけるためでも、ただ楽しみたいだけでも、あらゆる方向性の出会いを歓迎し、祝福します。",
      "Bumbleが大切にしていること",
      "Bumbleでは、女性が自分で行動を起こすか、マッチング相手が返答できるOpening Moveを設定することを選択します。時代遅れの力関係を変え、始まりから対等であることを奨励します。",
      "Bumbleの仕組み",
      "会社概要",
      "今すぐダウンロード",
      "アプリがおすすめ。",
      "オンラインデートを、​そう、​現実的に、​楽しく​する​ため​の​機能",
      "2024年2月12日",
      "人間関係で感情的な親密さを高める方法",
      "2024年1月25日",
      "出会い系アプリを使ったことがない場合のBumbleの使い方",
      "2024年2月5日",
      "Deception Detector™",
      "記録するべき​出会いに​関する​すべての記事",
      "<b>For You</b>を閲覧すると、あなたの好みや過去のマッチング相手に基づいてキューレーションしたプロフィールが表示されます。",
      "あなた​の​ため​に​キューレーション",
      "<b>スヌーズモード</b>で出会いを一時休止できます。再び出会う準備ができた際にはすぐに再開できます。",
      "休憩をしよう",
      "<b>ビデオ通話</b>や<b>音声チャット</b>を使って、家にいながら現実に会いましょう。",
      "声に出してみよう",
      "もっと見る",
      "<b>Opening Moves</b>を選択して、マッチング相手からチャットを開始してもらうようにしましょう。そうすれば、あなたが開始する必要はありません。",
      "やる事を減らして、もっとデートをしよう",
      "<b>トラベルモード</b>で、免税でスイートなお相手をみつけ、世界のあらゆる場所でマッチングしましょう。",
      "一緒に旅をしよう",
      "バイブスに響くプロフィールをみつけたら、<b>SuperSwipe</b>でその思いを伝えましょう。",
      "お相手の注意を引こう",
      "あなたのプロフィールが際立つように、お相手に<b>Compliments</b>を贈ってみましょう。",
      "お相手を喜ばせよう",
      "<b>プライベートモード</b>で上司、親戚、元恋人からプロフィールを隠せます。",
      "プロフィールの非表示設定",
      "思い通りに​出会いを​コントロール",
      "数カ月後、彼女はBumbleで花嫁介添人の一人にも出会いました。",
      "ハンナはBumbleで今の夫と出会いました。",
      "ユーリアンがカレーヌの旅行写真に惹かれて、二人はBumbleで知り合い、小さな家を一緒に建てました。",
      "カレーヌとユーリアン",
      "ブライアンとケイシは共に海外在住中に、Bumbleで知り合いました。",
      "ブライアンとケイシ",
      "誰か​の​役​に​立てた​の​なら、​あなた​に​も​うまく​いく​かも​しれ​ない",
      "次の行動を起こそう",
      "新着情報を見る",
      "もっとデートを楽しめて、かつストレスが軽減できる素晴らしい方法を考案しました。",
      "バー​を​本来​ある​べき​位置​に​戻し​ましょう",
      "Bumbleについて",
      "Bumbleは、女性の体験を第一に考え、より良いデート体験を提供する唯一のアプリです。なぜなら、女性にとって良いことは、誰にとっても良いことだからです。",
      "Make the first move™",
      "実際にデートしたいと思うお相手をみつけて、自分から行動を起こしましょう。本物のつながりを築き、お互いの好きなことで盛り上がり、そして最も重要なのは楽しむことです。",
      "Bumble​ Date​で​出会い​を探そう",
      "仕事に有益な友達をみつけましょう。他のビジネスプロフェッショナルとつながり、次の仕事やメンター、新しいキャリアと出会いましょう。",
      "Bumble​ Bizz​で​キャリア​アップ​を​目指​そう",
      "似ている人や自分とはまったく異なる人と出会いましょう。新しい友達をつくり、一緒にできる新しいことを探すのはどうでしょう？",
      "Bumble​ For​ Friends​で​新しい​友達​を​みつけ​よう",
      "デート​の​ため​だけ​では​あり​ません",
      "Bumbleは、女性が自分から行動を起こして出会うためのマッチングアプリです。デートの仕方、有意義な関係の作り方、ネットワークの作り方を変えたアプリです。今すぐダウンロードしよう。",
      "{{social_network}}のBumble",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Premium+",
      "申し訳ありません、お探しのページが見つかりません。",
      "お問い合わせ",
      "ログイン",
      "問題が発生しました",
      "(opens in new window)",
      "よくある質問",
      "Cookie Policy",
      "Premium",
      "Bumble ー The Blog",
      "採用情報",
      "チャット",
      "都市",
      "クッキーの管理",
      "著作権 © 2006 – 現在。Bumble（不許複製・禁無断転載）",
      "デートの信頼度レポート",
      "AppleおよびAppleのロゴはApple Inc.の商標です。{{__EOL__}}Google PlayはGoogle LLCの商標です。",
      "ダウンロード",
      "Facebook",
      "フォローはこちらから",
      "ヘルプセンター",
      "Instagram",
      "言語",
      "LinkedIn",
      "BumbleはBumble Incの一部です。",
      "プライバシーポリシー",
      "Safety Centre",
      "サイトマップ",
      "現代奴隷法",
      "利用規約",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "Compliments": {
            "plural": [
                  "Compliment"
            ],
            "singular": [
                  "Compliment"
            ]
      },
      "day": {
            "plural": [
                  "日"
            ],
            "singular": [
                  "日"
            ]
      },
      "hour": {
            "plural": [
                  "時間"
            ],
            "singular": [
                  "時間"
            ]
      },
      "minute": {
            "plural": [
                  "分"
            ],
            "singular": [
                  "分"
            ]
      },
      "month": {
            "plural": [
                  "ヶ月"
            ],
            "singular": [
                  "ヶ月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "superswipe": {
            "plural": [
                  "SuperSwipe"
            ],
            "singular": [
                  "SuperSwipe"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
