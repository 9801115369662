import Localization from 'lib/localization';

export default {
    get lxOpeningMovesHeroTitle() {
        return Localization.get('bumblesite@pages.features.opening-moves.hero.title');
    },
    get lxOpeningMovesHeroSubtitle() {
        return Localization.get('bumblesite@pages.features.opening-moves.hero.subtitle');
    },
    get lxOpeningMovesOneBlockTitle() {
        return Localization.get('bumblesite@pages.features.opening-moves.one.block.title');
    },
    get lxOpeningMovesOneBlockBodyText() {
        return Localization.get('bumblesite@pages.features.opening-moves.one.block.body.text');
    },
    get lxOpeningMovesOneBlockCta() {
        return Localization.get('bumblesite@pages.features.download.bumble.cta');
    },
    get lxOpeningMovesTwoBlockTitle() {
        return Localization.get('bumblesite@pages.features.opening-moves.two.block.title');
    },
    get lxOpeningMovesTwoBlockBodyText() {
        return Localization.get('bumblesite@pages.features.opening-moves.two.block.body.text');
    },
    get lxOpeningMovesTwoBlockCta() {
        return Localization.get('bumblesite@pages.features.opening-moves.two.block.cta');
    },
    get lxOpeningMovesThreeBlockTitle() {
        return Localization.get('bumblesite@pages.features.opening-moves.three.block.title');
    },
    get lxOpeningMovesThreeBlockPointOneText() {
        return Localization.get(
            'bumblesite@pages.features.opening-moves.three.block.point.one.text',
        );
    },
    get lxOpeningMovesThreeBlockPointTwoText() {
        return Localization.get(
            'bumblesite@pages.features.opening-moves.three.block.point.two.text',
        );
    },
    get lxOpeningMovesThreeBlockPointThreeText() {
        return Localization.get(
            'bumblesite@pages.features.opening-moves.three.block.point.three.text',
        );
    },
    get lxFeaturesFaqTitle() {
        return Localization.get('bumblesite@pages.features.faq.title');
    },
    get lxOpeningMovesFaqQuestionOneTitle() {
        return Localization.get('bumblesite@pages.features.opening-moves.faq.question.one.title');
    },
    get lxOpeningMovesFaqQuestionOneAnswer() {
        return Localization.get('bumblesite@pages.features.opening-moves.faq.question.one.answer');
    },
    get lxOpeningMovesFaqQuestionTwoTitle() {
        return Localization.get('bumblesite@pages.features.opening-moves.faq.question.two.title');
    },
    get lxOpeningMovesFaqQuestionTwoAnswer() {
        return Localization.get('bumblesite@pages.features.opening-moves.faq.question.two.answer');
    },
    get lxOpeningMovesFaqQuestionThreeTitle() {
        return Localization.get('bumblesite@pages.features.opening-moves.faq.question.three.title');
    },
    get lxOpeningMovesFaqQuestionThreeAnswer() {
        return Localization.get(
            'bumblesite@pages.features.opening-moves.faq.question.three.answer',
        );
    },
    get lxOpeningMovesCtaTitle() {
        return Localization.get('bumblesite@pages.features.opening-moves.cta.title');
    },
    get lxFeaturesTrademarkText() {
        return Localization.get('bumblesite@pages.features.trademark.text');
    },
};
